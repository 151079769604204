import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TechnologyCollection } from '@sympheny/database/model';
import {
  ConversionTechnology,
  ConversionTechnologyDatabaseSchemaV1,
  ConversionTechnologyDtoV1,
} from '@sympheny/project/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';

@Injectable()
export class UserConversionTechnologyCollection extends TechnologyCollection<ConversionTechnology> {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(
      http,
      environmentService,
      {
        db: 'user',
        technology: 'conversion-tech',
        categoryMapper: 'technologyCategories',
        technologyMapper: 'databaseConversionTechnologies',
        guid: 'conversionTechGuid',
        responseSchema: ConversionTechnologyDatabaseSchemaV1,
        requestSchema: ConversionTechnologyDtoV1,
      },
      'V1',
    );
  }
}
