import { InjectionToken } from '@angular/core';

export const DB_SELECT_TOKEN_DB = new InjectionToken(
  'sympheny/DB/SELECT/database'
);
export const DB_SELECT_TOKEN_EWZ = new InjectionToken('sympheny/DB/SELECT/ewz');
export const DB_SELECT_TOKEN_USER = new InjectionToken(
  'sympheny/DB/SELECT/user'
);
export const DB_SELECT_TOKEN_ORG = new InjectionToken(
  'sympheny/DB/SELECT/database-org'
);
