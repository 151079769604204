import { Injectable } from '@angular/core';
import { LoadDataService } from '@sympheny/utils/data-access';
import { map, Observable, of, ReplaySubject } from 'rxjs';

import { DatabaseDetailsService } from './database-details.service';

@Injectable()
export abstract class DatabaseDetailsNoCategoriesService<
    TECH,
    ID extends keyof TECH,
  >
  extends DatabaseDetailsService<TECH>
  implements LoadDataService
{
  protected abstract readonly idKey: ID;

  public load(): void {
    this.reload();
  }
  public override readonly noCategories = true;

  public data$ = new ReplaySubject<TECH[]>(1);
  public categories$ = this.data$.asObservable() as any;

  public override getTechnologyCategoryDetails(categories: string[]) {
    return this.data$;
  }

  public override deleteCategory(guid: string) {
    return this.delete(guid);
  }

  public find(guid: string): Observable<TECH | null> {
    if (!guid) {
      return of(null);
    }

    return this.data$.pipe(
      map(
        (items) =>
          (items && items.find((item) => item[this.idKey] === guid)) ?? null,
      ),
    );
  }
  public abstract fetchData(): Promise<TECH[]>;

  public override getDetails(
    categories: string,
    guid: string,
    exchangeRate: number,
  ): Observable<TECH> {
    return this.find(guid);
  }

  public override reload(): void {
    this.fetchData().then((d) => {
      this.data$.next(d);
    });
  }

  public override deleteType(category: string, type: string): Promise<string> {
    throw new Error('Method not implemented.');
  }
}
