import z from 'zod';

export const ResultNetDesignSchema = z.object({
  name: z.string(),
  installStage: z.string(),
  phaseoutStage: z.string(),
  link: z.string(),
  capacity: z.number(),
  capacityBinary: z.number(),
  investment: z.number(),
  replacement: z.number(),
  salvageRevenue: z.number(),
  co2: z.number(),
  point: z.number().nullish(),
});

export type ResultNetDesign = z.infer<typeof ResultNetDesignSchema>;
