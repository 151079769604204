import { NgIf, AsyncPipe } from '@angular/common';
import { Component, Inject, InjectionToken, Optional } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ScenarioState,
  ScenarioStore,
} from '@sympheny/project/scenario/data-access';
import { SelectComponent } from '@sympheny/ui/form';
import { map } from 'rxjs';

export const SELECT_VALUE_FROM_STORE_TOKEN = new InjectionToken(
  'sympheny/TABLE/select-value-from-store',
);

export type SelectValueFromStoreData<T, K extends keyof ScenarioState> = {
  field: keyof T;
  storeField: K;
  guid: string;
  displayField: string;
};

@Component({
  selector: 'sympheny-select-value-from-store',
  // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
  template: ` {{ stages$ | async }} `,
  standalone: true,
  imports: [NgIf, SelectComponent, ReactiveFormsModule, AsyncPipe],
})
export class SelectValueFromStoreComponent<T> {
  private values: string[];
  public readonly stages$ = this.scenarioStore
    .selectValue(this.data.data.storeField)
    .pipe(
      map((storeValues) => {
        const values =
          this.values?.map((s) => {
            const value = storeValues.find((v) => v[this.data.data.guid] === s);

            return value?.[this.data.data.displayField] ?? null;
          }) ?? [];

        return values.filter((s) => !!s).join(',');
      }),
    );

  constructor(
    private readonly scenarioStore: ScenarioStore,
    @Optional()
    @Inject(SELECT_VALUE_FROM_STORE_TOKEN)
    private readonly data: {
      data: SelectValueFromStoreData<T, any>;
      element: T;
    },
  ) {
    const values = data.element[data.data.field] as any;

    if (Array.isArray(values)) {
      this.values = values;
    } else {
      this.values = [values];
    }
  }
}
