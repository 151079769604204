import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TechnologyCollection } from '@sympheny/database/model';
import { NetworkTechnology } from '@sympheny/project/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';

@Injectable()
export class DatabaseNetworkTechnologyCollection extends TechnologyCollection<NetworkTechnology> {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(
      http,
      environmentService,
      {
        db: 'database',
        technology: 'network-tech',
        categoryMapper: 'technologyCategories',
        technologyMapper: 'databaseNetworkTechnologies',
        guid: 'databaseTechGuid',
      },
      'V1',
    );
  }
}
