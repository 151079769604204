import { SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const BuildingAgeOrStandardResponseDtoSchema = z.object({
  buildingAgeOrStandard: z.string().nullish(),
  dbOrganization: z.string().nullish(),
  displayValue: z.string().nullish(),
  energyDemandMetadataGuid: z.string().nullish(),
  name: z.string().nullish(),
  specificEnergyDemandValueKWhM2: SymphenyNumber().nullish(),
});
export type BuildingAgeOrStandardResponseDto = z.infer<
  typeof BuildingAgeOrStandardResponseDtoSchema
>;

export const EnergyDemandDatabaseSchema = z.object({
  averageProfileValue: SymphenyNumber().nullish(),
  buildingAgeOrStandardResponseDtos: z
    .array(BuildingAgeOrStandardResponseDtoSchema)
    .nullish(),
  buildingType: z.string().nullish(),
  fullLoadHours: SymphenyNumber().nullish(),
  maximumProfileValue: SymphenyNumber().nullish(),
  minimumProfileValue: SymphenyNumber().nullish(),
  totalProfileValue: SymphenyNumber().nullish(),
});

export type EnergyDemandDatabase = z.infer<typeof EnergyDemandDatabaseSchema>;

export const EnergyDemandAgeDatabaseSchema = z.object({
  averageProfileValue: SymphenyNumber().nullish(),
  buildingAgeOrStandard: z.string().nullish(),
  buildingType: z.string().nullish(),
  dbOrganisation: z.string().nullish(),
  energyDemandMetadataGuid: z.string().nullish(),
  energyDemandMetadataName: z.string().nullish(),
  energyDemandType: z.string().nullish(),
  fullLoadHours: SymphenyNumber().nullish(),
  maximumProfileValue: SymphenyNumber().nullish(),
  minimumProfileValue: SymphenyNumber().nullish(),
  notes: z.string().nullish(),
  source: z.string().nullish(),
  specificEnergyDemandValueKWhM2: SymphenyNumber().nullish(),
  totalProfileValue: SymphenyNumber().nullish(),
});

export type EnergyDemandAgeDatabase = z.infer<
  typeof EnergyDemandAgeDatabaseSchema
>;
