import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ScenarioStore } from '@sympheny/project/scenario/data-access';
import { DialogFormComponent } from '@sympheny/ui/dialog';
import { FormLogService } from '@sympheny/ui/form-log';
import { combineLatest, merge, of, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'sympheny-scenario-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss'],
  providers: [FormLogService],
  standalone: true,
  imports: [DialogFormComponent],
})
export class FormDialogComponent implements OnChanges, OnDestroy {
  @Input() public form!: FormGroup;
  @Input() public contentLabel!: string;
  @Input() public dialogTitle: string;
  @Input() public tooltip?: string;
  @Input() public editMode!: boolean;
  @Input() public loading = false;
  @Input() public submitLabel?: string;
  @Input() public cancelLabel?: string;
  @Input() public hideTitle = false;
  @Input() public hideActions = false;
  @Output() public readonly submitForm = new EventEmitter<void>();

  public readonly su = this;
  public disabled$ = this.scenarioState?.isLocked$ ?? of(false);
  public readonly$ = this.scenarioState?.readonly$ ?? of(false);
  public canEdit$ = this.scenarioState?.canEdit$ ?? of(true);

  private readonly destroy$ = new Subject<void>();
  constructor(
    private readonly formLogService: FormLogService,
    @Optional() private readonly scenarioState: ScenarioStore,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['form']) {
      this.formLogService.registerForm(this.form);
      this.destroy$.next();
      merge(this.disableEffect$()).pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  public onSubmitForm() {
    if (this.form.invalid) {
      return;
    }

    this.submitForm.emit();
  }

  private disableEffect$() {
    return combineLatest([this.readonly$, this.disabled$]).pipe(
      tap(([readonly, disabled]) => this.toggleForm(readonly, disabled)),
    );
  }

  private toggleForm(readonly: boolean, disabled: boolean) {
    if (disabled || readonly) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
