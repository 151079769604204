import { SymphenyNumber, SymphenyUtcDate } from '@sympheny/utils/data-access';
import { z } from 'zod';

import { PlanLimitationSchema } from './plan-limitation.model';

export const AccountSchema = z.object({
  planLimitationId: z.string().nullish(),
  email: z.string().nullish(),
  organizationId: z.string().nullish(),
  planExpiry: SymphenyUtcDate().nullish(),
  numberOfExecutionsLeft: SymphenyNumber().nullish(),
  executionTimeWeekLeft: SymphenyNumber().nullish(),
  mfa: z.boolean().nullish(),
  deactivated: z.boolean().nullish(),
  superuser: z.boolean().nullish(),
  admin: z.boolean().nullish(),
  showMaintenanceInfo: z.boolean().nullish(),
  showGtc: z.boolean().nullish(),
  showUserGuide: z.boolean().nullish(),
  accountGuid: z.string().nullish(),
  created: SymphenyUtcDate().nullish(),
  updated: SymphenyUtcDate().nullish(),
});

export const UserPreferencesSchema = z.object({
  exchangeCurrencyDefault: z.string().nullish(),
  unitSystemDefault: z.string().nullish(),
  languageDefault: z.string().nullish(),
  interestRateDefault: SymphenyNumber().nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  created: z.string().nullish(),
  updated: z.string(),
});

export const OrganizationSchema = z.object({
  name: z.string().nullish(),
  gisOn: z.boolean().nullish(),
  sepOn: z.boolean().nullish(),
  sepBuildingsLimit: SymphenyNumber().nullish(),
  executionSlots: SymphenyNumber().nullish(),
  id: z.string().nullish(),
  created: SymphenyUtcDate().nullish(),
  updated: SymphenyUtcDate().nullish(),
  sepBuildingsConsumed: SymphenyNumber().nullish(),
});

export const ProfileSchema = z.object({
  account: AccountSchema,
  preferences: UserPreferencesSchema,
  planLimitation: PlanLimitationSchema,
  organization: OrganizationSchema,
  profilePicture: z.string().nullish(),
  organizationPicture: z.string().nullish(),
});

export type Organization = z.infer<typeof OrganizationSchema>;
export type Account = z.infer<typeof AccountSchema>;
export type UserPreferences = z.infer<typeof UserPreferencesSchema>;

export type Profile = z.infer<typeof ProfileSchema>;
