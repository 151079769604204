<form
  [formGroup]="editLayersForm"
  (ngSubmit)="onSave()"
  class="flex flex-col gap-4"
>
  <div class="flex items-center">
    <sympheny-select
      class="flex-1 w-64"
      formControlName="selectedLayerId"
      label="map.layer.select"
      [options]="editableLayers"
      labelKey="title"
      valueKey="layerId"
      (selectionChange)="setSelectedLayer($event?.layerId)"
      [translateOptions]="false"
    />
    <sympheny-more-button
      *ngIf="canEdit"
      [disabled]="disabled"
      [configuration]="moreConfiguration"
    />
  </div>
  <sympheny-input
    formControlName="name"
    label="map.layer.name"
    [required]="true"
  />

  <sympheny-color-picker
    formControlName="color"
    (ngModelChange)="changeColor()"
  >
    {{ 'map.layer.color' | transloco }}
  </sympheny-color-picker>
  <div class="flex justify-end pt-2 border-t" *ngIf="canEdit">
    <sympheny-button
      buttonType="submit"
      [disabled]="!selectedLayer || disabled"
    >
      {{ 'Save' | transloco }}
    </sympheny-button>
  </div>
</form>
