<div class="flex justify-between items-baseline gap-4">
  <div>
    <ng-content></ng-content>
  </div>
  <div class="flex gap-4 items-center">
    <button
      *ngIf="!hideCancel"
      data-cy="dialog-actions--cancel"
      mat-raised-button
      [mat-dialog-close]="false"
    >
      {{ cancelLabel | transloco }}
    </button>

    <button
      *ngIf="!hideSubmit"
      mat-raised-button
      data-cy="dialog-actions--submit"
      type="submit"
      [color]="submitColor"
      [disabled]="disabled"
      (click)="submitAction.emit()"
    >
      {{ submitLabel | transloco }}
    </button>
    <ng-content select="[dialog-actions-additional-buttons]"></ng-content>
  </div>
</div>
