<header class="z-[999]">
  <mat-toolbar
    role="heading"
    color="primary"
    class="sympheny--header flex gap-4"
  >
    <a class="title" routerLink="/projects">
      <h1 data-cy="sympheny-header">
        <!-- <img class="logo-element" width="250" src="/assets/sympheny-new-logo.webp" alt="Sympheny Logo" /> -->
        <picture>
          <source
            srcset="/assets/sympheny-new-logo.webp"
            width="250"
            type="image/webp"
          />
          <source
            srcset="/assets/sympheny-new-logo.png"
            width="250"
            type="image/png"
          />
          <img
            class="logo-element"
            width="250"
            src="/assets/sympheny-new-logo.webp"
            alt="Sympheny"
          />
        </picture>
      </h1>
    </a>

    <div class="flex-fill-space">
      <sympheny-breadcrumb *ngIf="!mobileQuery.matches"></sympheny-breadcrumb>
    </div>
    <button
      mat-button
      [matMenuTriggerFor]="apiDocsMenu"
      *ngIf="authenticated$ | async"
    >
      {{ 'API Docs' | transloco }}
    </button>
    <mat-menu #apiDocsMenu>
      <button
        *ngFor="let api of apiLinks"
        mat-menu-item
        [routerLink]="api.routerLink"
        [queryParams]="api.queryParams"
      >
        {{ api.label }}
      </button>
    </mat-menu>
    <a
      mat-button
      *ngIf="authenticated$ | async"
      target="_blank"
      href="https://support.app.sympheny.com/user-guide/latest/"
      >{{ 'Support' | transloco }}</a
    >
    <sympheny-language-switcher
      [languages]="languages"
      [currentLanguage]="currentLanguage$ | async"
      (changeLanguage)="changeLanguage($event)"
    ></sympheny-language-switcher>

    <button mat-button *ngIf="authenticated$ | async" (click)="logout()">
      {{ 'Log Out' | transloco }}
    </button>

    <img
      class="w-10 h-10 rounded-full"
      *ngIf="profileImage$ | async as profileImage; else avaterLetters"
      [src]="profileImage"
      alt="Avatar"
    />

    <ng-template #avaterLetters>
      <div
        class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
      >
        <span class="font-medium text-gray-300">
          {{ firstLetter$ | async }}
        </span>
      </div></ng-template
    >
  </mat-toolbar>
</header>
