import { computePaths } from './path/compute';
import { resolveNodeCollisions } from './resolve-collision';
import { Graph, GraphData, Node, NodeImpl } from '../../model';
import { centerFromNodes, centerFromSourceTarget } from '../../utils/node';

const isIntersecting = (n1: Node, n2: Node) => {
  // Ignore aggregated nodes as they are invisible
  if (n1.aggregate || n2.aggregate) return false;

  if (n1._id === n2._id) return false;

  if (!n1.virtual && !n2.virtual && n1.row !== n2.row) return false;

  const noIntersect =
    n2.x0 > n1.x1 || n2.x1 < n1.x0 || n2.y0 > n1.y1 || n2.y1 < n1.y0;

  return !noIntersect;
};

const detectCollisions = ({
  graph: data,
  settings,
}: Readonly<Graph<any, any>>) => {
  const moveNodeUp = (node: Node, collisions: Node[]) => {
    // const firstCollision = collisions.sort(sortNodeBy('y0'))

    const row = node.row;
    let nextY = node.y0 ?? 0;

    if (node.aggregate) {
      const { sourceNodes } = data.getSourceLinksWithNodes(node);
      const { targetNodes } = data.getTargetLinksWithNodes(node);
      const n = sourceNodes.length > 1 ? sourceNodes : targetNodes;
      nextY = centerFromNodes(n).center;
    } else if (!node.partOfCycle) {
      const { sourceNodes } = data.getSourceLinksWithNodes(node);
      const { targetNodes } = data.getTargetLinksWithNodes(node);
      nextY = centerFromSourceTarget(sourceNodes, targetNodes).center;
    } else if (node.circularLinkType === 'bottom') {
      nextY = nextY + row + settings.minNodePadding.y;
    } else if (node.circularLinkType === 'top') {
      nextY = nextY - row - settings.minNodePadding.y;
    }
    if (nextY < 0) nextY = 0;
    if (nextY > data.extend.y1) nextY = data.extend.y1 - node.row;

    node.setCenterNodeY(nextY);

    collisions.forEach((c) => {
      if (isIntersecting(node, c)) {
        node.setNodeY(c.y1 + 10);
      }
    });
  };
  const collisionNodes = [];

  const nodes = data.getNodes();

  nodes.forEach((node) => {
    const collisions =
      !node.virtual && nodes.filter((n) => isIntersecting(node, n));
    if (collisions?.length) {
      collisionNodes.push({ node, collisions });
    }
  });

  collisionNodes.forEach((node) => {
    moveNodeUp(node.node, node.collisions);
  });

  return collisionNodes.length > 0;
};

export const createVirtualLinks = (data: GraphData) => {
  const columnMap = {};
  data.forEachColumn((nodes, columnIndex) => {
    columnMap[columnIndex] = nodes[0].x0;
  });

  data.forEachLink((link) => {
    const { orthogonalPathData: d } = link;
    if (!d) return;
    let x0 = Math.min(d.source.x, d.target.x) + 10;
    let width = Math.max(d.source.x, d.target.x) - x0 - 10;
    if (width < 0) {
      x0 -= 5;
      width = 10;
    }
    const x1 = x0 + width;

    const y0 = d.source.y - 2;
    const y1 = d.source.y + 2;

    const { source } = data.getNodeLinks(link);
    const virtualNode = new NodeImpl({
      x0,
      x1,
      y0,
      y1,
      name: `virtual_${source.column}_${link._id}`,
      color: 'rgba(0,255,0,0.5)',
      column: source.column,
      virtual: true,
      originalLink: link,
    } as unknown as Node);

    data.addNode(virtualNode.name, virtualNode);
    return;
  });
};

export const EhubResolveLinkCollison = (graph: Readonly<Graph<any, any>>) => {
  const { graph: data } = graph;

  const { iterations } = graph.settings;
  // For each of the nodes check if there is a link that goes throug it. If so move it

  // Compute path data of current gaph
  computePaths(graph);

  let hasCollisions = true;

  for (let n = iterations; n > 0; --n) {
    computePaths(graph);
    // Create virtual nodes on the position of the path data
    createVirtualLinks(data);

    // Detect collisions
    hasCollisions = detectCollisions(graph);
    data.removeVirtualNodesFromIndex();

    // Only continue if there was a movement in the collisions
    if (hasCollisions) {
      // n = 0;
      resolveNodeCollisions(data, 5);
    } else {
      n = 0;
    }
  }

  if (hasCollisions) {
    console.log('there are some collisions after', iterations);
  }
};
