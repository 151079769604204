// eslint-disable-next-line @nx/enforce-module-boundaries
import { createProviders } from '@sympheny/database/select';
import { createLoadProviders } from '@sympheny/utils/data-access';

import { DatabaseStorageTechnologyCollectionService } from './collections/database-storage-technology-collection.service';
import { EwzStorageTechnologyCollectionService } from './collections/ewz-storage-technology-collection.service';
import { OrgDatabaseStorageTechnologyCollectionService } from './collections/org-database-storage-technology-collection.service';
import { UserStorageTechnologyCollectionService } from './collections/user-storage-technology-collection.service';
import { DatabaseStorageTechnologyCollectionServiceV2 } from './collections-v2/database-storage-technology-collection.service';
import { EwzStorageTechnologyCollectionServiceV2 } from './collections-v2/ewz-storage-technology-collection.service';
import { OrgDatabaseStorageTechnologyCollectionServiceV2 } from './collections-v2/org-database-storage-technology-collection.service';
import { UserStorageTechnologyCollectionServiceV2 } from './collections-v2/user-storage-technology-collection.service';

export * from './collections/database-storage-technology-collection.service';
export * from './collections/ewz-storage-technology-collection.service';
export * from './collections/org-database-storage-technology-collection.service';
export * from './collections/user-storage-technology-collection.service';

export * from './collections-v2/database-storage-technology-collection.service';
export * from './collections-v2/ewz-storage-technology-collection.service';
export * from './collections-v2/org-database-storage-technology-collection.service';
export * from './collections-v2/user-storage-technology-collection.service';

export const createStorageProvidersV2 = () =>
  createProviders({
    dbDetailsService: DatabaseStorageTechnologyCollectionServiceV2,
    ewzDetailsService: EwzStorageTechnologyCollectionServiceV2,
    userDetailsService: UserStorageTechnologyCollectionServiceV2,
    orgDetailsService: OrgDatabaseStorageTechnologyCollectionServiceV2,
  });

export const createStorageProviders = () =>
  createProviders({
    dbDetailsService: DatabaseStorageTechnologyCollectionService,
    ewzDetailsService: EwzStorageTechnologyCollectionService,
    userDetailsService: UserStorageTechnologyCollectionService,
    orgDetailsService: OrgDatabaseStorageTechnologyCollectionService,
  });

export const ProvideStorageTechnology = () => {
  return createLoadProviders([
    OrgDatabaseStorageTechnologyCollectionService,
    DatabaseStorageTechnologyCollectionService,
    EwzStorageTechnologyCollectionService,
    UserStorageTechnologyCollectionService,

    OrgDatabaseStorageTechnologyCollectionServiceV2,
    DatabaseStorageTechnologyCollectionServiceV2,
    EwzStorageTechnologyCollectionServiceV2,
    UserStorageTechnologyCollectionServiceV2,
  ]);
};
