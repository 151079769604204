import { Injectable } from '@angular/core';

import { AbstractEnergyDemandDatabaseCollection } from './abstract-energy-demand-database.collection';

@Injectable()
export class EnergyDemandDatabaseCollection extends AbstractEnergyDemandDatabaseCollection {
  constructor() {
    super(false, { db: 'database', guid: 'energyDemandGuid' });
  }
}
