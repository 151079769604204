export interface BreadcrumbModel {
  key: string;
  label: string;
  translateLabel?: boolean;
  subLabel?: string;
  translateSubLabel?: boolean;
  route?: string;
}

export interface BreadcrumbDataModel {
  skip?: boolean;
  label: string;
  subLabel?: string;
  translateLabel?: boolean;
  translateSubLabel?: boolean;
  route?: any[];
  routeBackPath?: number;
}
