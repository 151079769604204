import { Injectable } from '@angular/core';

import { AbstractTechnologyPackagesCollection } from './abstract-technology-packages.collection';
import { UserConversionTechnologyCollectionV2 } from '../../../conversion-technology';
import { UserStorageTechnologyCollectionServiceV2 } from '../../../storage-technology';

const url = 'user-technology-packages';
const saveUrl = `user-technology-packages/saved-profiles`;

@Injectable()
export class UserTechnologyPackageCollectionServiceV2 extends AbstractTechnologyPackagesCollection {
  constructor(
    conversionService: UserConversionTechnologyCollectionV2,
    storageService: UserStorageTechnologyCollectionServiceV2,
  ) {
    super('user', url, saveUrl, conversionService, storageService);
  }
}
