<div [formGroup]="form" class="grid grid-cols-3 gap-2">
  <sympheny-select-list
    label="DATABASE.database"
    formControlName="database"
    [options]="databases$ | async"
    (ngModelChange)="changeDB()"
  >
    <p info>{{ 'DATABASE.select.db.info' | transloco }}</p>
  </sympheny-select-list>

  <sympheny-select-list
    [label]="categoryHeaderLabel"
    formControlName="category"
    [labelKey]="categoryLabel"
    [valueKey]="categoryValue"
    [options]="categories$ | async"
    [sortByValue]="true"
    (ngModelChange)="changeCategory()"
  >
    <p info>{{ categoryInfoLabel | transloco }}</p>
  </sympheny-select-list>

  <sympheny-select-list
    [label]="technologyHeaderLabel"
    formControlName="technology"
    [multi]="multi"
    [labelKey]="technologyLabel"
    [valueKey]="technologyValue"
    [options]="technologies$ | async"
    [sortByValue]="true"
    (ngModelChange)="changeTechnology()"
  >
    <p info>{{ technologyInfoLabel | transloco }}</p>
  </sympheny-select-list>
</div>
