import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { Hub, HubSchema } from '@sympheny/project/data-access';
import {
  LayerType,
  ScenarioStore,
} from '@sympheny/project/scenario/data-access';
import { ScenarioMapStore } from '@sympheny/project/scenario/map-gis';
import {
  ColumnConfig,
  CrudListComponent,
  CrudTableAction,
  CrudTableComponent,
} from '@sympheny/project/scenario/ui-kit';
import { TableDefinedAction } from '@sympheny/ui/table';
import { Observable } from 'rxjs';

import { HubListFields, HubFieldConfig } from './const/hub.field-config';
import { HubsService } from './hubs.service';

@Component({
  selector: 'sympheny-hubs',
  templateUrl: './hubs.component.html',
  styleUrls: ['./hubs.component.scss'],
  standalone: true,
  imports: [
    CrudListComponent,
    AsyncPipe,
    TranslocoPipe,
    CrudTableComponent,
    NgIf,
  ],
})
export class HubsComponent implements OnInit {
  // Stepper info
  public label = 'Hubs';
  public errorMessage = 'You must finish specifying this step.';
  public hubNextError: string = null; // TODO [Meda]: Remove hardcoded bool

  @Input() public scenarioId: string;
  public readonly definedActions: TableDefinedAction<Hub> = {
    label: 'Hub',
    edit: (hub: Hub) => this.onEditHub(hub),
    delete: (hub: Hub) => this.deleteHub(hub),
  };

  public readonly actions: CrudTableAction[] = [
    {
      label: 'general.addNew',
      dataCy: 'create.hubs',
      action: () => this.onHubAdd(),
    },
  ];

  public columnConfig: ColumnConfig<Hub> = {
    summaryFields: HubListFields,
    fieldConfig: HubFieldConfig,
    schema: HubSchema,
  };

  // Hubs
  public hubs$: Observable<Hub[]> = this.scenarioStore.selectValue('hubs');

  private hubs: Hub[] = [];
  public hubEditingIndex: number;
  public pricingPlan: string;

  constructor(
    private readonly scenarioMapStore: ScenarioMapStore,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly scenarioStore: ScenarioStore,
    private readonly hubsService: HubsService,
  ) {}

  public ngOnInit(): void {
    // Get hubs and set unique validator for name
    this.hubs$.subscribe((res) => {
      this.hubs = res;
    });
  }

  public deleteHub(hub: Hub) {
    const onSuccess = () => {
      this.scenarioMapStore.delete(
        LayerType.hubs,
        this.scenarioId,
        hub.hubGuid,
      );
    };

    this.scenarioStore.delete('hubs', hub.hubGuid, { onSuccess });
  }

  public onHubAdd() {
    this.hubsService.add(this.viewContainerRef);
  }

  public onEditHub(hub: Hub) {
    this.hubsService.edit(hub.hubGuid, this.viewContainerRef);
  }

  public validateHubsNext() {
    if (this.hubs?.length === 0) {
      this.hubNextError = 'You must specify at least one hub.';
    } else {
      this.hubNextError = null;
    }
  }
}
