import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  DatabaseDetailsService,
  TechnologyCollection,
} from '@sympheny/database/model';
import { ConversionTechnology } from '@sympheny/project/data-access';
import {
  DB_TYPES,
  LoadDataService,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Observable, firstValueFrom, ReplaySubject, map, tap, of } from 'rxjs';

import {
  TechnologyPackagesDatabase,
  TechnologyPackagesDatabaseSchema,
  TechnologyPackagesDetailDatabaseSchema,
} from './technology-package.model';

type Technology = {
  guid: string;
  type: 'conversion' | 'storage';
  name: string;
};

@Injectable()
export abstract class AbstractTechnologyPackagesCollection
  implements DatabaseDetailsService<any>, LoadDataService
{
  protected readonly idKey = 'guid';

  public readonly categories$ = new ReplaySubject<any>(1);

  private readonly environmentService = inject(EnvironmentService);
  private readonly base = this.environmentService.getValue('base');
  private readonly http = inject(HttpClient);
  private readonly items$ = new ReplaySubject<Technology[]>(1);

  constructor(
    protected readonly type: DB_TYPES,
    protected readonly url: string,
    protected readonly saveUrl: string,
    protected readonly conversionService: TechnologyCollection<ConversionTechnology>,
    protected readonly storageService: TechnologyCollection<ConversionTechnology>,
  ) {}

  public getDetails(
    categories: string,
    guid: string,
    exchangeRate: number,
  ): Observable<any> {
    return this.items$.pipe(map((items) => items.find((i) => i.guid === guid)));
  }

  public getTechnologyDetails(technology: Technology, exchangeRate: number) {
    if (technology.type === 'conversion')
      return this.conversionService.getDetails(
        '',
        technology.guid,
        exchangeRate,
      );
    if (technology.type === 'storage')
      return this.storageService.getDetails('', technology.guid, exchangeRate);

    return of(null);
  }

  public load(): void {
    firstValueFrom(this.fetchApi()).then((categories) =>
      this.categories$.next(categories),
    );
  }

  public reload(): void {
    this.load();
  }

  public async create(data: Partial<TechnologyPackagesDatabase>) {
    const createCall = this.http.post(
      `${this.base}${this.saveUrl}/${data.guid}`,
      {},
    );
    await firstValueFrom(createCall);
    this.reload();
  }
  protected fetchApi(): Observable<TechnologyPackagesDatabase[]> {
    return this.http
      .get<
        ResponseModel<{
          technologyPackages: TechnologyPackagesDatabase[];
        }>
      >(`${this.base}${this.url}`, {
        params: { fromOrg: this.type === 'database-org' },
      })
      .pipe(mapDataZ(TechnologyPackagesDatabaseSchema, 'technologyPackages'));
  }

  public getTechnologyCategoryDetails(category: string[]) {
    return this.http
      .get<
        ResponseModel<{ types: TechnologyPackagesDatabase[] }>
      >(`${this.base}v2/${this.url}/${category[0]}`, { params: { fromOrg: this.type === 'database-org' } })
      .pipe(
        mapDataZ(TechnologyPackagesDetailDatabaseSchema),
        map((data) =>
          // TODO update when database is updated
          [
            data.conversionTechnologies?.map((d) => ({
              // guid: `${PREFIX.CONVERSION}-${d.guid}`,
              guid: d.guid,
              name: d.name,
              type: 'conversion',
            })),
            data.storageTechnologies?.map((d) => ({
              // guid: `${PREFIX.storage}-${d.guid}`,
              guid: d.guid,
              name: d.name,
              type: 'storage',
            })),
          ]
            .flat()
            .filter((v) => !!v),
        ),
        tap((data) => this.items$.next(data)),
      );
  }

  public update(
    gui: string,
    data: Partial<TechnologyPackagesDatabase>,
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public delete(guid: string): Promise<string> {
    return firstValueFrom(
      this.http
        .delete(`${this.base}technology-packages-no-scenario/${guid}`)
        .pipe(map(() => guid)),
    );
  }

  public async deleteCategory(category: string) {
    await this.delete(category);

    return category;
  }
  public deleteType(category: string, type: string): Promise<string> {
    throw new Error('Method not implemented.');
  }
}
