/** Inspired on https://observablehq.com/@tomshanley/sankey-circular-deconstructed */

import { ElementRef } from '@angular/core';
import * as d3 from 'd3';

import { drawInfo } from './info';
import { drawArrow } from '../draw/arrow';
import { Setup, GraphSetup } from '../draw/graph-setup';
import { DrawLinkProps, drawLinks, updateLink } from '../draw/link';
import { drawNode } from '../draw/node';
import { TextProps, createTextElement, updateText } from '../draw/text-element';
import { SankeyData, Link, Node } from '../model';

export const drawEhub = <
  DATA extends SankeyData,
  NODE_TYPE extends Node,
  LINK_TYPE extends Link,
>(
  data: DATA,
  setup: Setup<NODE_TYPE, LINK_TYPE>,

  tooltip: ElementRef,
) => {
  const graphSetup = new GraphSetup<any, any>(setup, tooltip);
  graphSetup.generateEhubData(data);

  const { padding } = graphSetup.settings;
  const topPadding = 40;

  let scale = 1;

  const graphDimensions = graphSetup.getGraphDimensions();

  if (scale > 1) {
    // only downscale te graph
    scale = 1;
  }
  const svg = d3
    .create('svg')
    .attr(
      'viewBox',
      `0 0 ${graphDimensions.width + padding} ${graphDimensions.height + topPadding}`,
    );

  const infoG = svg.append('g').attr('transform', `translate(${padding},${0})`);

  //calculate new horizontal padding
  drawInfo(
    graphSetup,
    infoG,
    graphDimensions.width,
    graphDimensions.height + topPadding,
  );
  const g = svg
    .append('g')
    .attr('transform', `translate(${padding},${topPadding})`);

  const textProps: TextProps<Node> = {
    // width: nodeWidth - 10,
    anchor: 'right',
    x: (d) => d.x0 + 5,
    y: (d) => d.y0 + 5,
    wordBreak: () => true,
  };
  const { nodeText, nodeColor } = graphSetup;
  const linkProps: DrawLinkProps = { linkOpacity: 0.9 };

  const { node } = drawNode(graphSetup, g, svg, (n: Readonly<Node>) =>
    updateDrawing(n),
  );

  const { text } = createTextElement(
    node as any,
    nodeText,
    nodeColor,
    textProps,
  );
  const { linkG, links, linkData, linkPaths } = drawLinks(
    graphSetup,
    g,
    svg,
    linkProps,
  );
  let { arrows } = drawArrow(graphSetup, links, linkG);

  const updateDrawing = (n?: Readonly<Node>) => {
    if (n) graphSetup.dragEhub(n);

    updateLink(graphSetup, linkData, linkPaths);
    updateText(text, nodeText, textProps);

    arrows.remove();
    arrows = drawArrow(graphSetup, links, linkG).arrows;
  };
  return svg.node();
};
