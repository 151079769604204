import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SymphenyErrorHandler } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';

import { CrudService } from './crud.service';
import { TechnologyPackagesServiceV1 } from './technology-package.service.v1';
import { TechnologyPackagesServiceV2 } from './technology-package.service.v2';
import { ProjectVersion, TechnologyPackages } from '../model';

export enum TechnologyPackageFrom {
  database,
  custom,
}

@Injectable()
export class TechnologyPackagesService extends CrudService<TechnologyPackages> {
  public readonly guidKey = 'guid';
  public readonly key = 'technologyPackages';

  constructor(
    http: HttpClient,
    environmentService: EnvironmentService,
    errorHandler: SymphenyErrorHandler,
    private readonly technologyPackageServiceV1: TechnologyPackagesServiceV1,
    private readonly technologyPackageServiceV2: TechnologyPackagesServiceV2,
  ) {
    super(http, environmentService, errorHandler);
  }

  private getService(projectVersion: ProjectVersion) {
    if (projectVersion === 'V1') return this.technologyPackageServiceV1;
    return this.technologyPackageServiceV2;
  }

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    return this.getService(projectVersion).list(projectVersion, scenarioGuid);
  }

  /**
   * Create a TechnologyPackages
   */
  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<TechnologyPackages>,
    extra: TechnologyPackageFrom,
  ) {
    return this.getService(projectVersion).create(
      projectVersion,
      scenarioGuid,
      data,
      extra,
    );
  }

  public override get(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    id: string,
    extra?: any,
  ): Promise<TechnologyPackages> {
    return this.getService(projectVersion).get(
      projectVersion,
      scenarioGuid,
      id,
      extra,
    );
  }

  /**
   * Update TechnologyPackages name
   */

  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: Partial<TechnologyPackages>,
  ) {
    return this.getService(projectVersion).update(
      projectVersion,
      scenarioGuid,
      guid,
      data,
    );
  }

  /**
   * Delete TechnologyPackages
   */

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    extra?: any,
  ) {
    return this.getService(projectVersion).delete(
      projectVersion,
      scenarioGuid,
      guid,
      extra,
    );
  }
}
