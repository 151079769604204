<div
  *transloco="let t"
  class="collapse collapse-arrow join-item border-base-300 border shadow rounded-none"
>
  <input type="radio" [name]="uuid" checked="checked" />
  <div class="collapse-title text-xl font-medium bg-gray-100">
    <ng-content select="[summary-title]"></ng-content>

    <ng-container *ngIf="summaryTitle">
      {{ t(summaryTitle, summaryTitleArgs) }}
    </ng-container>
  </div>
  <div class="collapse-content">
    <div class="pb-2">
      <h2 class="underline text-lg pb-2" *ngIf="summarySubject">
        {{ summarySubject }}
      </h2>
      <ng-content select="[summary-content]"></ng-content>
      <ng-content></ng-content>
    </div>
    <hr />
    <div class="py-2">
      <p class="underline font-bold">{{ t('DATABASE.notes') }}:</p>
      <p>
        {{ notes ?? 'N/A' }}
      </p>
      <ng-content select="[summary-notes]"></ng-content>
    </div>
    <div>
      <p *ngIf="source !== false" class="mt-2">
        <span class="underline font-bold">{{ t('DATABASE.source') }}:</span>
        <a *ngIf="source !== null" target="_blank" [href]="sourceLink">
          {{ source }}
        </a>
      </p>
      <p *ngIf="lastUpdated !== false" class="mt-2">
        <span class="underline font-bold">
          {{ t('DATABASE.last_updated') }}:</span
        >
        {{ lastUpdated ? (lastUpdated | date: 'yyyy-MM-dd hh:mm:ss') : 'N/A' }}
      </p>
    </div>
  </div>
</div>
