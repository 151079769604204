import { SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const PlanLimitationSchema = z.object({
  maxEnergyCarriersPerHub: SymphenyNumber().nullish(),
  maxEnergyDemands: SymphenyNumber().nullish(),
  maxHubs: SymphenyNumber().nullish(),
  maxStages: SymphenyNumber().nullish(),
  maxSolarResources: SymphenyNumber().nullish(),
  maxOtherResources: SymphenyNumber().nullish(),
  maxImports: SymphenyNumber().nullish(),
  maxExports: SymphenyNumber().nullish(),
  maxConversionTechs: SymphenyNumber().nullish(),
  maxConversionModes: SymphenyNumber().nullish(),
  maxStorageTechs: SymphenyNumber().nullish(),
  maxNetworkTechs: SymphenyNumber().nullish(),
  maxNetworkLinks: SymphenyNumber().nullish(),
  intraHubNetwork: z.boolean().nullish(),
  scenarioVariants: z.boolean().nullish(),
  maxScenarioVariants: SymphenyNumber().nullish(),
  advancedCostComponents: z.boolean().nullish(),
  maxExecutions: SymphenyNumber().nullish(),
  maxSimultaneousExecutions: SymphenyNumber().nullish(),
  maxExecutionTime: SymphenyNumber().nullish(),
  maxExecutionTimeWeek: SymphenyNumber().nullish(),
  maxExecutionHistory: SymphenyNumber().nullish(),
  maxParetoPoints: SymphenyNumber().nullish(),
  maxProjects: SymphenyNumber().nullish(),
  maxAnalyses: SymphenyNumber().nullish(),
  maxScenarios: SymphenyNumber().nullish(),
  shareProject: z.boolean().nullish(),
  organizationDb: z.boolean().nullish(),
  name: z.string().nullish(),
  id: z.string().nullish(),
  created: z.string().nullish(),
  updated: z.string().nullish(),
  sagemakerOn: z.boolean().nullish(),
  unitCommitment: z.boolean().nullish(),
});

export type PlanLimitation = z.infer<typeof PlanLimitationSchema>;
