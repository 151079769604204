import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UploadFile } from '@sympheny/ui/form/model';
import { ProgressService } from '@sympheny/ui/progress';
import { SnackbarService } from '@sympheny/ui/snackbar';
import { UserState } from '@sympheny/user/data-access';
import { plainToClass } from 'class-transformer';
import {
  BehaviorSubject,
  EMPTY,
  firstValueFrom,
  Subject,
  switchMap,
  takeWhile,
  tap,
  timer,
  Observable,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  withLatestFrom,
} from 'rxjs/operators';

import { ProjectEvents } from './project-events.service';
import { Project, ProjectDetails, SecondaryOwner } from '../model';
import { ProjectsService } from '../service';
import { ProjectDto } from '../service/project.dto';

@Injectable()
export class ProjectsStore {
  private readonly destroyLoad$ = new Subject<void>();
  private readonly _projects$ = new BehaviorSubject<Project[]>([]);
  public readonly projects$ = this._projects$.asObservable();
  private readonly _loading$ = new BehaviorSubject(true);
  public readonly loading$ = this._loading$.pipe(distinctUntilChanged());

  constructor(
    private readonly projectService: ProjectsService,
    private readonly projectActions: ProjectEvents,
    private readonly router: Router,
    private readonly snackbarService: SnackbarService,
    private readonly userState: UserState,
    private readonly progressService: ProgressService,
  ) {}

  public clear() {
    this.destroyLoad$.next();
    this._projects$.next([]);
  }

  public load() {
    this.loadUntilAllProcessed();
  }

  private loadUntilAllProcessed() {
    this.destroyLoad$.next();
    timer(0, 3000)
      .pipe(
        switchMap(() => this.loadProjects()),
        tap((projects) => this._projects$.next(projects)),
        tap(() => this._loading$.next(false)),
        takeWhile((projects) => projects.some((p) => p.processing)),
        catchError(() => EMPTY),
      )
      .subscribe();
  }

  private loadProjects(): Observable<Project[]> {
    return this.projectService.all().pipe(
      withLatestFrom(this.userState.waitForEmail()),
      map(([projects, email]) =>
        projects.map((project) => project.addRights(email)),
      ),
    );
  }

  public create(project: ProjectDto): Promise<Project> {
    return firstValueFrom(this.projectService.create(project)).then(
      (createdProject) => this.createDone(createdProject),
    );
  }

  public makeFavorite(project: Project, favorite: boolean) {
    const dto =
      ProjectDto.fromExistingProject(project).updateFavorite(favorite);
    if (project.ownedByCurrentUser) {
      this.update(project.projectGuid, dto);
    } else if (project.secondaryOwner) {
      this.updateSecondaryOwners(project, dto.secondaryOwners);
    }
  }

  public async migrate(
    projectGuid: string,
    copyBeforeMigration: boolean,
  ): Promise<void> {
    let migrateProjectGuid = projectGuid;
    if (copyBeforeMigration) {
      const copiedProject = await this.projectService.copy(projectGuid);

      migrateProjectGuid = copiedProject.projectGuid;
    }

    return this.projectService.migrate(migrateProjectGuid).then((res) => {
      this.load();
    });
  }

  public update(projectGuid: string, project: ProjectDto): Promise<Project> {
    return firstValueFrom(
      this.projectService.update(projectGuid, project),
    ).then((createdProject) => this.updateDone(createdProject));
  }

  public async copy(projectGuid: string, projectName: string) {
    const promise = this.progressService.handleProgress(
      { message: 'project.copy', translateParams: { projectName } },
      this.projectService.copy(projectGuid).then((project) => {
        this.createDone(project);
      }),
    );

    this.router.navigate(['projects']);
    this.load();
    return promise;
  }

  public async delete(projectGuid: string, projectName: string) {
    this.snackbarService.success(
      `Deleting project ${projectName} is now in progress. Completion time may vary depending on the size of the project. Please be patient.`,
    );

    this.router.navigate(['projects']);
    this.deleteDone(projectGuid);

    return this.projectService
      .deleteProject(projectGuid)
      .then(() => {
        this.deleteDone(projectGuid);
      })
      .catch((error) => {
        this.reload();

        console.error(error);

        throw new Error(error);
      });
  }

  public async reinitialiseProject(project: Project) {
    await this.projectService.deleteProject(project.projectGuid);
    this.deleteDone(project.projectGuid);
    return this.projectService.reinitialise(project).then(() => this.reload());
  }

  public async setDefault(project: Project) {
    const request = project.originalDefaultProjectGuid
      ? this.projectService.removeDefaultProject(project)
      : this.projectService.addDefaultProject(project);

    return firstValueFrom(request).then(() => {
      this.snackbarService.success(
        `${project.projectName} ${
          !project.originalDefaultProjectGuid
            ? 'added as default project'
            : 'removed as default project'
        }`,
      );
      this.reload();
    });
  }

  public unlock(project: Project) {
    if (!project.lock) {
      return;
    }
    this.projectService.unlock(project.projectGuid);

    setTimeout(() => this.reload(), 500);
  }

  public async updateSecondaryOwners(
    project: Project,
    secondaryOwners: SecondaryOwner[],
  ) {
    await this.projectService.updateSecondaryOwners(
      project.projectGuid,
      secondaryOwners,
    );

    this.updateDone({ ...project, secondaryOwners } as ProjectDetails);

    return;
  }

  public get projects() {
    return this._projects$.value;
  }

  private createDone(project: Project) {
    const projects = [...this.projects, project];
    this._projects$.next(projects);

    return project;
  }

  private updateDone(_project: Project) {
    const project = plainToClass(Project, _project);
    project.addRights(this.userState.getEmail());

    const projects = this.projects.map((p) =>
      project.projectGuid === p.projectGuid ? project : p,
    );
    this._projects$.next(projects);

    // For the active project store.
    this.projectActions.updateProject(project);

    return project;
  }

  private deleteDone(projectGuid: string) {
    const projects = this.projects.filter((p) => p.projectGuid !== projectGuid);

    this._projects$.next(projects);
  }

  private reload() {
    this.loadUntilAllProcessed();
  }

  public uploadImage(projectGuid: string, upload: UploadFile) {
    return firstValueFrom(this.projectService.upload(projectGuid, upload)).then(
      (project) => this.updateDone(project),
    );
  }
}
