<mat-form-field
  [class.w-full]="config?.fullWidth ?? true"
  [attr.data-cy]="dataCy || label"
  [attr.data-testid]="dataCy || label"
>
  <mat-label *ngIf="label">{{ label | transloco: labelParams }}</mat-label>
  <mat-select
    *ngIf="multiple"
    [placeholder]="placeholder ?? label | transloco: labelParams"
    [formControl]="formControl"
    multiple
    [errorStateMatcher]="matcher"
    (selectionChange)="onSelectionChange($event)"
    (openedChange)="onOpenChange($event)"
  >
    <div
      class="ml-1 w-full hover:bg-gray-100 py-1"
      *ngIf="!disableSelectAll && (!maxValues || maxValues > 1)"
    >
      <mat-checkbox
        [(ngModel)]="allSelected"
        [ngModelOptions]="{ standalone: true }"
        (change)="toggleAllSelection()"
        color="primary"
      >
        {{ 'general.selectAll' | transloco }}
      </mat-checkbox>
    </div>

    <mat-option
      *ngFor="let option of options; trackBy: trackByValue(); let index = index"
      [value]="getValue(option)"
      [disabled]="optionDisabled(option)"
      [class.single-select]="maxValues === 1"
      [attr.data-cy]="(dataCy || label) + '__option_' + index"
      [attr.data-testid]="(dataCy || label) + '__option_' + index"
    >
      <span
        [innerHTML]="
          translateOptions
            ? (getLabel(option) | transloco: labelParams)
            : getLabel(option)
        "
      ></span>
    </mat-option>
  </mat-select>
  <mat-select
    *ngIf="!multiple"
    [placeholder]="placeholder ?? label | transloco: labelParams"
    [formControl]="formControl"
    [errorStateMatcher]="matcher"
    (selectionChange)="onSelectionChange($event)"
    (openedChange)="onOpenChange($event)"
  >
    <mat-option
      *ngFor="let option of options; let index = index; trackBy: trackByValue()"
      [disabled]="optionDisabled(option)"
      [value]="getValue(option)"
      [attr.data-cy]="(dataCy || label) + '__option_' + index"
      [attr.data-testid]="(dataCy || label) + '__option_' + index"
    >
      <span
        [innerHTML]="
          translateOptions
            ? (getLabel(option) | transloco: labelParams)
            : getLabel(option)
        "
      ></span>
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="requiredHint">
    * {{ requiredMessage | transloco: labelParams }}
  </mat-hint>
  <mat-error *ngIf="invalid">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</mat-form-field>
<sympheny-info-button
  *ngIf="hint"
  [tooltip]="hint"
  [link]="hintLink"
></sympheny-info-button>
