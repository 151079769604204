import { Injectable } from '@angular/core';
import {
  findByGuidZ,
  findLatestDataZ,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, map } from 'rxjs';

import { CrudService } from './crud.service';
import {
  IntraHubNetworkLink,
  IntraHubNetworkLinkDtoSchema,
  IntraHubNetworkLinkListSchemaV1,
  IntraHubNetworkLinkListSchemaV2,
  IntraHubNetworkLinkSchemaV1,
  IntraHubNetworkLinkSchemaV2,
  ProjectVersion,
} from '../model';

@Injectable()
export class IntraHubNetworkLinksService extends CrudService<IntraHubNetworkLink> {
  public readonly guidKey = 'intraHubNetworkLinkGuid';
  public readonly key = 'intraHubNetworkLink';

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    const schema =
      projectVersion === 'V2'
        ? IntraHubNetworkLinkListSchemaV2
        : IntraHubNetworkLinkListSchemaV1;
    return this.http
      .get<
        ResponseModel<{ intraHubNetworkLinks: IntraHubNetworkLink[] }>
      >(`${this.base}scenarios/${scenarioGuid}/intra-hub-network-links`)
      .pipe(mapDataZ(schema, 'intraHubNetworkLinks'));
  }

  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<IntraHubNetworkLink>,
  ) {
    const schema =
      projectVersion === 'V2'
        ? IntraHubNetworkLinkSchemaV2
        : IntraHubNetworkLinkSchemaV1;
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<{ intraHubNetworkLinks: IntraHubNetworkLink[] }>
        >(`${this.base}scenarios/${scenarioGuid}/intra-hub-network-links`, mapDataRequest(IntraHubNetworkLinkDtoSchema, data))
        .pipe(findLatestDataZ(schema, 'created', 'intraHubNetworkLinks')),
    );
  }

  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    intraHubNetworkLinkGuid: string,
    data: Partial<IntraHubNetworkLink>,
  ) {
    const schema =
      projectVersion === 'V2'
        ? IntraHubNetworkLinkSchemaV2
        : IntraHubNetworkLinkSchemaV1;
    return firstValueFrom(
      this.http
        .put<
          ResponseModel<{ intraHubNetworkLinks: IntraHubNetworkLink[] }>
        >(`${this.base}scenarios/intra-hub-network-links/${intraHubNetworkLinkGuid}`, mapDataRequest(IntraHubNetworkLinkDtoSchema, data))
        .pipe(
          findByGuidZ(
            schema,
            this.guidKey,
            intraHubNetworkLinkGuid,
            'intraHubNetworkLinks',
          ),
        ),
    );
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    intraHubNetworkLinkGuid: string,
  ) {
    return firstValueFrom(
      this.http
        .delete(
          `${this.base}scenarios/intra-hub-network-links/${intraHubNetworkLinkGuid}`,
        )
        .pipe(map(() => intraHubNetworkLinkGuid)),
    );
  }
}
