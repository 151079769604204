import { Inject, Injectable, OnDestroy, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  addCollectionItem,
  makeState,
  removeCollectionItem,
  updateCollectionItem,
} from '@fp-tools/angular-state';
import {
  ConversionTechnologyService,
  CrudService,
  ProjectState,
  ProjectVersion,
  Scenario,
  ScenarioService,
} from '@sympheny/project/data-access';
import { SnackbarService } from '@sympheny/ui/snackbar';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PlanService } from '@sympheny/user/plan';
import { isNotNullOrUndefined } from '@sympheny/utils/rxjs';
import {
  combineLatest,
  firstValueFrom,
  merge,
  Observable,
  Subject,
  switchMap,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import {
  DataConfig,
  DataKey,
  getDataFor,
  PlanLimitMap,
  ScenarioState,
  ScenarioStateData,
  ScenarioStateExtraData,
} from './scenario.store.types';
import { OtherParameterCollection, OtherParameters } from '../other-parameters';

const initialState: ScenarioState = {
  onSiteSolarResources: [],
  energyDemands: [],
  hubs: [],
  scenarioId: null,
  selectedHubGuid: null,
  reloadHubDiagram: new Date(),
  status: false,
  importsExports: [],
  conversionTechnologies: [],
  technologyPackages: [],
  seasonalOperations: [],
  storageTechnologies: [],
  intraHubNetworkLink: [],
  networkLinks: [],
  networkTechnologies: [],
  energyCarriers: [],
  stages: [],
  profiles: [],
  scenarioVariants: [],
  details: null,
};

type ScenarioStateFieldsTranslations = Partial<
  Record<keyof ScenarioState, string>
>;

const initialLoadState: Record<
  keyof ScenarioState | 'otherParameters',
  boolean
> = {
  ...getDataFor.reduce(
    (state, key) => ({ ...state, [key]: true }),
    {} as Record<keyof ScenarioState, boolean>,
  ),
  otherParameters: true,
};

@Injectable()
export class ScenarioStore implements OnDestroy {
  public technologyTranslationsMapping: ScenarioStateFieldsTranslations = {
    stages: 'stage',
    scenarioVariants: 'SCENARIO_VARIANTS',
    energyCarriers: 'ENERGY_CARRIER',
  };

  public readonly otherParams$ = this.otherParameterCollection.data$;
  private readonly state = makeState({ ...initialState });
  private readonly loading = makeState({ ...initialLoadState });
  private scenarioId: string;
  private readonly services = new Map<keyof ScenarioState, CrudService<any>>();
  private readonly guidKeys = new Map<keyof ScenarioState, string>();
  private readonly destroy$ = new Subject<void>();
  public readonly scenario$: Observable<Scenario> =
    this.state.select('details');
  public readonly name$: Observable<string> = this.scenario$.pipe(
    map((scenario) => scenario?.scenarioName),
  );

  constructor(
    private readonly projectState: ProjectState,
    private readonly scenarioService: ScenarioService,
    private readonly snackbarService: SnackbarService,
    private readonly otherParameterCollection: OtherParameterCollection,
    private readonly conversionTechnologyService: ConversionTechnologyService,
    private readonly planService: PlanService,
    @Inject(CrudService) services: CrudService<any>[] | CrudService<any>,
  ) {
    if (!Array.isArray(services)) {
      this.services.set(services.key as keyof ScenarioState, services);
      this.guidKeys.set(
        services.key as keyof ScenarioState,
        services.guidKey as string,
      );
      return;
    }

    services.forEach((service) => {
      this.services.set(service.key as keyof ScenarioState, service);
      this.guidKeys.set(
        service.key as keyof ScenarioState,
        service.guidKey as string,
      );
    });

    const scenarioId = this.state
      .select('scenarioId')
      .pipe(isNotNullOrUndefined());
    const projectVersion$ = this.projectState.version$.pipe(
      isNotNullOrUndefined(),
    );

    combineLatest(scenarioId, projectVersion$)
      .pipe(
        tap(([id, projectVersion]) =>
          this.getDataForScenario(id, projectVersion),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public get scenarioVariantGuid() {
    const details = this.getValue('details');
    return details?.variant ? this.scenarioId : null;
  }

  public readonly isVariant$ = this.scenario$.pipe(
    map((s) => s?.variant),
    distinctUntilChanged(),
  );
  public readonly masterScenarioGuid$ = this.scenario$.pipe(
    map((s) => s?.masterScenarioGuid),
    distinctUntilChanged(),
  );
  public readonly isNoVariant$ = this.isVariant$.pipe(
    map((isVariant) => !isVariant),
    distinctUntilChanged(),
  );
  public readonly isLocked$ = this.projectState.isLocked$;
  public readonly lockDetails$ = this.projectState.lockDetails$;
  public readonly canEdit$ = combineLatest([
    this.projectState.canEdit$,
    this.isNoVariant$,
  ]).pipe(map((readonlys) => readonlys.every((readonly) => readonly)));

  public readonly readonly$ = combineLatest([
    this.projectState.isReadonly$,
    this.isVariant$,
  ]).pipe(map((readonlys) => readonlys.some((readonly) => readonly)));

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public get exchangeCurrency() {
    return this.otherParameterCollection.exchangeCurrency;
  }
  public get projectVersion() {
    return this.projectState.version;
  }

  public get exchangeCurrency$() {
    return this.otherParameterCollection.select('exchangeCurrency');
  }
  public get exchangeRate() {
    return this.otherParameterCollection.exchangeRate;
  }

  public setScenarioGuid(scenarioId: string) {
    this.scenarioId = scenarioId;
    this.state.reset();
    this.loading.reset();
    this.state.set('scenarioId', scenarioId);
  }

  public selectValue<K extends keyof ScenarioState>(
    key: K,
  ): Observable<ScenarioState[K]> {
    return this.state.select(key);
  }

  public selectValue_s<K extends keyof ScenarioState>(
    key: K,
  ): Signal<ScenarioState[K]> {
    return toSignal(this.state.select(key));
  }

  public isLoading<K extends keyof ScenarioState>(
    key: K | 'otherParameters',
  ): Observable<boolean> {
    return this.loading.select(key) as unknown as Observable<boolean>;
  }

  public isPlanLimitReached(key: DataKey): boolean {
    const maxKey = PlanLimitMap[key];
    if (!maxKey) return false;

    return this.planService.isPlanLimitReached(maxKey, this.getValue(key));
  }

  public getValue<K extends keyof ScenarioState>(key: K): ScenarioState[K] {
    return this.state.get(key);
  }

  public createMultiple<
    KEY extends DataKey,
    EXTRA_DATA extends (typeof ScenarioStateExtraData)[KEY],
  >(
    key: KEY,
    data: Partial<(typeof ScenarioStateData)[KEY]>[],
    config?: DataConfig<EXTRA_DATA>,
  ) {
    this.loading.set(key, false);
    this.getServiceFor(key)
      .createMultiple(
        this.projectState.version,
        this.scenarioId,
        data,
        config?.extra,
      )
      .then((newData) => {
        this.state.set(key, [...this.state.get(key), ...newData]);
        this.showSuccessMessage(key, 'MESSAGES.success.multiple.added');

        if (config?.onSuccess) {
          config.onSuccess(newData);
        }
        if (config?.reloadHubDiagram) {
          this.reloadHubDiagram();
        }
      })
      .catch((error) => {
        if (config?.onError) {
          config.onError(error);
        }
      })
      .finally(() => {
        this.loading.set(key, false);
      });
  }

  public create<
    KEY extends DataKey,
    EXTRA_DATA extends (typeof ScenarioStateExtraData)[KEY],
  >(
    key: KEY,
    data: Partial<(typeof ScenarioStateData)[KEY]>,
    config?: DataConfig<EXTRA_DATA>,
  ) {
    this.loading.set(key, true);

    return this.getServiceFor(key)
      .create(this.projectState.version, this.scenarioId, data, config?.extra)
      .then((newData) => {
        this.createData(key, newData);
        if (config?.onSuccess) {
          config.onSuccess(newData);
        }
        if (config?.reloadHubDiagram) {
          this.reloadHubDiagram();
        }
        this.showSuccessMessage(key, 'MESSAGES.success.added');
      })
      .catch((error) => {
        if (config?.onError) {
          config.onError(error);
        }
      })
      .finally(() => {
        this.loading.set(key, false);
      });
  }

  public updateGeneralParameters(data: OtherParameters) {
    this.loading.set('otherParameters', true);
    this.otherParameterCollection.save(data).finally(() => {
      this.loading.set('otherParameters', false);
    });
  }

  public update<
    KEY extends DataKey,
    EXTRA_DATA extends (typeof ScenarioStateExtraData)[KEY],
  >(
    key: KEY,
    guid: string,
    data: Partial<(typeof ScenarioStateData)[KEY]>,
    config?: DataConfig<EXTRA_DATA>,
  ) {
    this.loading.set(key, true);

    this.getServiceFor(key)
      .update(
        this.projectState.version,
        this.scenarioId,
        guid,
        data,
        config?.extra,
      )
      .then((newData) => {
        this.updateData(key, newData);
        if (config?.onSuccess) {
          config.onSuccess(newData);
        }
        if (config?.reloadHubDiagram) {
          this.reloadHubDiagram();
        }
        this.showSuccessMessage(key, 'MESSAGES.success.edited');

        if (key in ['conversionTechnologies', 'storageTechnologies']) {
          this.reload('technologyPackages');
        }
      })
      .catch((error) => {
        if (config?.onError) {
          config.onError(error);
        }
      })
      .finally(() => {
        this.loading.set(key, false);
      });
  }

  public delete<
    KEY extends DataKey,
    EXTRA_DATA extends (typeof ScenarioStateExtraData)[KEY],
  >(key: KEY, guid: string, config?: DataConfig<EXTRA_DATA>) {
    this.loading.set(key, true);
    return this.getServiceFor(key)
      .delete(this.projectState.version, this.scenarioId, guid, config?.extra)
      .then(() => {
        this.deleteData(key, guid);
        if (config?.onSuccess) {
          config.onSuccess(guid);
        }
        if (config?.reloadHubDiagram) {
          this.reloadHubDiagram();
        }
        this.showSuccessMessage(key, 'MESSAGES.success.deleted');
        return;
      })
      .catch((error) => {
        if (config?.onError) {
          config.onError(error);
        }
        throw new Error(error);
      })
      .finally(() => {
        this.loading.set(key, false);
      });
  }

  private showSuccessMessage(key: keyof ScenarioState, message: string) {
    const value = this.technologyTranslationsMapping[key];
    if (!value) return;
    this.snackbarService.success(`${value}.${message}`, {
      translateParams: { value },
    });
  }

  public reload(key: DataKey) {
    if (!this.projectState.version) {
      return;
    }
    this.listData(key, this.scenarioId, this.projectState.version);
  }

  public selectHubGuid(guid: string) {
    this.state.set('selectedHubGuid', guid);
  }

  public reloadHubDiagram() {
    this.state.set('reloadHubDiagram', new Date());
  }

  public finish() {
    return this.scenarioService
      .finishScenario(this.scenarioId, this.projectState.version)
      .then(() => this.projectState.reload());
  }

  private async loadScenario(scenarioId: string): Promise<Scenario> {
    const scenario = await this.scenarioService.get(
      scenarioId,
      this.projectState.version,
    );

    this.state.set('details', scenario);

    return scenario;
  }
  public async refreshStatus(): Promise<boolean> {
    const scenarioStatus = await (this.projectState.version === 'V1'
      ? this.statusV1()
      : this.statusV2());
    this.state.set('status', scenarioStatus);

    return scenarioStatus;
  }

  private async statusV1(): Promise<boolean> {
    return (await firstValueFrom(
      this.scenarioService.getScenarioStatus(this.scenarioId),
    ).catch((error) => false)) as boolean;
  }

  private async statusV2(): Promise<boolean> {
    const scenario = await this.scenarioService.get(
      this.scenarioId,
      this.projectState.version,
    );

    return scenario.preparingExecutionV2;
  }

  private refreshStatusTimer() {
    timer(0, 10000)
      .pipe(
        switchMap(() => this.refreshStatus()),
        takeWhile((status) => status === true),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private getServiceFor(key: DataKey) {
    const service = this.services.get(key);

    if (!service) {
      throw new Error(`No service for ${key}`);
    }

    return service;
  }

  private async getDataForScenario(
    scenarioId: string,
    projectVersion: ProjectVersion,
  ) {
    this.refreshStatusTimer();
    this.otherParameterCollection.setScenario(scenarioId, projectVersion);

    this.loading.set('otherParameters', false);
    await this.loadScenario(scenarioId);
    return merge(
      getDataFor.map((key: DataKey) =>
        this.listData(key, scenarioId, projectVersion),
      ),
      this.getSeasonalOperations(),
    );
  }

  private getSeasonalOperations() {
    return firstValueFrom(
      this.conversionTechnologyService.getSeasonalOperations(),
    ).then((data) => {
      this.state.set('seasonalOperations', data);
    });
  }

  private listData(
    key: DataKey,
    scenarioId: string,
    projectVersion: ProjectVersion,
  ) {
    if (
      key === 'scenarioVariants' &&
      !this.planService.getPlanLimit('scenarioVariants')
    ) {
      // TODO  return null;
    }
    const masterScenarioGuid = this.getValue('details')?.masterScenarioGuid;
    this.loading.set(key, true);
    return firstValueFrom(
      this.getServiceFor(key).list(
        projectVersion,
        scenarioId,
        masterScenarioGuid,
      ),
    )
      .then((data: any[]) => {
        this.state.set(key, data);
      })
      .finally(() => {
        this.loading.set(key, false);
      });
  }

  private deleteData(key: keyof ScenarioState, guid: string) {
    this.state.set(
      key,
      removeCollectionItem<any, any>(
        this.getGuidKey(key) as any,
        this.state.get(key) as any,
        guid,
      ),
    );
  }

  private createData(key: keyof ScenarioState, newData: any) {
    this.state.set(key, addCollectionItem(this.state.get(key) as any, newData));
  }

  private updateData(key: keyof ScenarioState, data: any) {
    this.state.set(
      key,
      updateCollectionItem(
        this.getGuidKey(key),
        this.state.get(key) as any,
        data,
      ),
    );
  }

  private getGuidKey(key: keyof ScenarioState): any {
    return this.guidKeys.get(key) ?? 'guid';
  }
}
