<mat-spinner *ngIf="loading" mode="indeterminate" class="spinner"></mat-spinner>

<table
  *ngIf="dataSource && columns"
  mat-table
  matSort
  [dataSource]="dataSource"
  (matSortChange)="sortData($event)"
>
  <ng-container
    *ngFor="let column of columns"
    [matColumnDef]="getHeaderName(column)"
  >
    <th
      mat-header-cell
      [mat-sort-header]
      [disabled]="column.sort?.disabled"
      *matHeaderCellDef
      class="text-xs text-gray-500"
    >
      <span
        [innerHTML]="column.label || column.labelFn() | transloco: labelParams"
      ></span>
      <span *ngIf="column.suffix" class="ml-1">
        ({{ column.suffix | transloco: labelParams }})
      </span>
      <sympheny-info-button
        *ngIf="column.tooltip"
        [tooltip]="column.tooltip"
      ></sympheny-info-button>
    </th>
    <td mat-cell *matCellDef="let element; let rowIndex = index">
      <span [ngClass]="column.cssClassFn ? column.cssClassFn(element) : ''">
        <sympheny-table-cell
          [element]="element"
          [column]="column"
          [rowIndex]="rowIndex"
        ></sympheny-table-cell
      ></span>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="actions text-gray-500 text-xs">
      Actions
    </th>

    <td mat-cell *matCellDef="let element; let rowIndex = index">
      <div class="flex">
        <ng-container *ngFor="let action of actions">
          <sympheny-table-action
            [action]="action"
            [element]="element"
            [index]="rowIndex"
          ></sympheny-table-action>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
</table>

<mat-paginator
  *ngIf="showPaginator"
  [pageSizeOptions]="[5, 10, 25, 100]"
  appeareance="fill"
></mat-paginator>
