import { z } from 'zod';

import { EnergyDemandV1, EnergyDemandV1Dto } from './energy-demand-v1.model';
import {
  EnergyDemandV2,
  EnergyDemandV2DtoWithProfile,
} from './energy-demand-v2.model';

export const EnergyDemandTypeSchema = z.object({
  energyDemandType: z.string().nullish(),
  energyDemandTypeDisplayValue: z.string().nullish(),
});

export const BuildingTypeSchema = z.object({
  buildingType: z.string().nullish(),
  buildingTypeDisplayValue: z.string().nullish(),
});

export type EnergyDemandType = z.infer<typeof EnergyDemandTypeSchema>;
export type BuildingType = z.infer<typeof BuildingTypeSchema>;

export type EnergyDemand = EnergyDemandV1 & EnergyDemandV2;
export type EnergyDemandDto = EnergyDemandV1 & EnergyDemandV2DtoWithProfile;
export type EnergyDemandDetail = EnergyDemandV1Dto & EnergyDemandV2;
