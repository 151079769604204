<div class="flex flex-row gap-1 items-center" *transloco="let t">
  {{ t(format.label) }}:
  <sympheny-format-value [format]="format" [element]="element" />
  <small
    *ngIf="format.suffix"
    class="text-gray-500 text-xs block"
    [innerHTML]="t(format.suffix, { currency })"
  >
  </small>
</div>
