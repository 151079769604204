import { AsyncPipe } from '@angular/common';
import { Component, Optional } from '@angular/core';
import {
  IconButtonListComponent,
  IconButtonListConfiguration,
} from '@sympheny/ui/button';
import { map, Observable } from 'rxjs';

import { FormLogService, FormLogStatus } from '../form-log.service';

@Component({
  selector: 'sympheny-form-log',
  templateUrl: './form-log.component.html',
  styleUrls: ['./form-log.component.scss'],
  standalone: true,
  imports: [AsyncPipe, IconButtonListComponent],
})
export class FormLogComponent {
  public readonly formLogButtons$: Observable<IconButtonListConfiguration> =
    this.formLogService.status$.pipe(
      map((status) => this.createFormLogButtons(status)),
    );

  constructor(@Optional() private readonly formLogService: FormLogService) {}

  private createFormLogButtons(
    status: FormLogStatus,
  ): IconButtonListConfiguration {
    return {
      buttons: [
        {
          tooltip: 'undo',
          action: () => this.formLogService.undo(),
          icon: 'undo',
          disabled: !status.undo,
        },
        {
          tooltip: 'redo',
          action: () => this.formLogService.redo(),
          icon: 'redo',
          disabled: !status.redo,
        },
      ],
    };
  }
}
