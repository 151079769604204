import { Injectable, Injector } from '@angular/core';

import { LoadDataService, LoadParameters } from './load-data.service';
export type DB_TYPES =
  | 'database'
  | 'user'
  | 'database-org'
  | 'customer'
  | 'ewz';

@Injectable({ providedIn: 'root' })
export class ReloadState {
  constructor(private readonly injector: Injector) {}
  private params: LoadParameters = {
    ewz: false,
    organization: null,
    organizationId: null,
  };

  public reload(params: LoadParameters) {
    const loadServices = this.injector.get(LoadDataService) as any;
    this.params = params;
    if (Array.isArray(loadServices)) {
      loadServices.forEach((service) => this.load(service));
    } else this.load(loadServices);
  }

  private load(loadService: LoadDataService) {
    if (!loadService.load) {
      console.warn('No load found for', loadService.constructor.name);
      return;
    }
    loadService.load();
  }

  public shouldLoad(db: DB_TYPES | 'ewz') {
    return db !== 'ewz' || this.params.ewz;
  }

  public getOrganization() {
    return this.params.organization;
  }
}
