<div
  class="px-4"
  [class.w-full]="config?.fullWidth ?? true"
  [attr.data-cy]="dataCy || label"
  [attr.data-testid]="dataCy || label"
>
  <mat-label *ngIf="label">{{ label | transloco }}</mat-label>
  <input
    type="range"
    [max]="max"
    [formControl]="formControl"
    [min]="min"
    class="range range-xs range-primary h-3"
    style="--range-shdw: 170 30% 80%"
  />
  <div class="w-full flex justify-between text-xs px-2">
    <span *ngFor="let step of steps">{{ step.label }}</span>
  </div>

  <sympheny-input-suffix
    matSuffix
    [suffix]="suffix"
    [suffixLabel]="suffixLabel"
    [suffixParams]="labelParams"
  >
    <ng-content select="[input-suffix]"></ng-content>
  </sympheny-input-suffix>
  <mat-hint *ngIf="requiredHint">
    * {{ 'FORM.requiredHint' | transloco }}
  </mat-hint>
  <mat-error *ngIf="matcher.isErrorState">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</div>

<sympheny-info-button *ngIf="hint" [tooltip]="hint" [link]="hintLink">
</sympheny-info-button>
