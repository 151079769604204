import { ElementRef } from '@angular/core';

export const showTooltip = (
  text: string,
  color: string,
  tooltip: ElementRef,
  graphWidth: number,
  { layerX, layerY }: any,
) => {
  tooltip.nativeElement.innerHTML = text;
  tooltip.nativeElement.style['border-color'] = color;
  tooltip.nativeElement.style.opacity = 1;
};
