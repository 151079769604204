// eslint-disable-next-line @nx/enforce-module-boundaries
import { BLANK, FormatValueConfig } from '@sympheny/format-value';

import { JobStatusInvalid, ScenarioJobEnriched } from './scenario-job.model';
type KEY = keyof ScenarioJobEnriched;
export const JobFieldConfig: Record<
  KEY,
  FormatValueConfig<ScenarioJobEnriched>
> = {
  accountGuid: undefined,
  batchId: undefined,
  status: undefined,
  id: undefined,
  clientType: undefined,
  name: {
    label: 'ANALYSIS.execution.name',
    key: 'name',
    defaultValue: 'N/A',
  },
  scenarioName: {
    label: 'project.scenario',
    key: 'scenarioName',
    defaultValue: 'N/A',
  },
  executionTime: {
    label: 'ANALYSIS.execution.execution.time',
    key: 'executionTime',
    type: 'template',
    sort: {
      mapData: (d: ScenarioJobEnriched) => {
        if (!d.terminated || !d.started) return -1;
        return d.terminated.getTime() - d.started.getTime();
      },
    },
  },
  objective1: {
    label: 'ANALYSIS.Objective1',
    translatePrefix: 'ANALYSIS.execution.objective.',
    key: 'objective1',
  },
  objective2: {
    label: 'ANALYSIS.Objective2',
    translatePrefix: 'ANALYSIS.execution.objective.',
    key: 'objective2',
  },
  scenarioGuid: {
    label: 'ANALYSIS.scenario',
    key: 'scenarioGuid',
  },
  temporalResolution: {
    label: 'ANALYSIS.execution.temporalResolution.label',
    translatePrefix: 'ANALYSIS.execution.temporalResolution.',
    key: 'temporalResolution',
  },
  points: {
    label: 'ANALYSIS.pareto.points',
    key: 'points',
  },
  timeLimit: {
    label: 'ANALYSIS.execution.timeLimit.label',
    key: 'timeLimit',
    suffix: 'SUFFIX.minutes',
  },
  mipGap: {
    label: 'ANALYSIS.execution.mipGap',
    hint: 'ANALYSIS.tooltip.mipgap',
    key: 'mipGap',
    suffix: 'SUFFIX.%',
  },
  statusMsg: {
    label: 'ANALYSIS.status.message',
    key: 'statusMsg',
    defaultValue: 'N/A',
    cssClassFn: (element: ScenarioJobEnriched) =>
      element.status === 'DONE'
        ? 'text-success'
        : JobStatusInvalid.includes(element.status)
          ? 'text-error'
          : '',
  },
  started: {
    label: 'ANALYSIS.execution.started',
    key: 'started',
  },
  terminated: {
    label: 'ANALYSIS.execution.terminated',
    key: 'terminated',
  },
  pointsCompleted: {
    label: 'ANALYSIS.pareto.points.completed',
    key: 'pointsCompleted',
    type: 'template',
  },
  created: {
    label: 'ANALYSIS.execution.created',
    key: 'created',
    sort: {
      fieldType: 'date',
    },
  },
  inputFile: {
    label: 'ANALYSIS.input.file',
    key: 'inputFile',
    type: 'action',
    readOnly: (element: ScenarioJobEnriched) => element.status === 'VALIDATING',
    action: {
      tooltip: 'download',
      icon: 'download',
    },
  } as any,
  outputFile: {
    label: 'ANALYSIS.output.file',
    key: 'outputFile',
    type: 'action',
    readOnly: (element: ScenarioJobEnriched) => element.status !== 'DONE',
    action: {
      tooltip: 'download',
      icon: 'download',
    },
  } as any,
  infeasibilityInfo: {
    label: 'ANALYSIS.execution.infeasibility',
    key: 'infeasibilityInfo',
  },
};
export const JobFieldListConfig: Record<
  KEY,
  FormatValueConfig<ScenarioJobEnriched>
> = {
  ...JobFieldConfig,
  created: { ...JobFieldConfig['created'], type: 'template' },
};

type SummaryKeys = KEY | BLANK;

export const summaryFields: SummaryKeys[] = [
  'name',
  'scenarioName',
  'objective1',
  'objective2',
  'pointsCompleted',
  'mipGap',
  'timeLimit',
  'temporalResolution',
  'created',
  'started',
  'terminated',
  'executionTime',
  'statusMsg',
  'inputFile',
  'outputFile',
];

export const listFields: KEY[] = [
  'name',
  'scenarioName',
  'statusMsg',
  'created',
  'executionTime',
  'inputFile',
  'outputFile',
];
