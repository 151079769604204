import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

export interface Progress {
  type: string;
  id: string;
  done: boolean;
  error: boolean;
  name: string;
  noKeepLocalStorage?: boolean;
}

const job_key = 'sympheny_progress';

@Injectable({ providedIn: 'root' })
export class ProgressState {
  private readonly progressMap = new Map<string, Progress>();
  private readonly progressIds$ = new BehaviorSubject<string[]>([]);

  public readonly progress$ = this.progressIds$.pipe(
    map((ids) => ids.map((id) => this.progressMap.get(id))),
  );

  public registerProgress(progress: Progress) {
    this.progressMap.set(progress.id, progress);

    this.updateProgress(progress.id);
  }

  public setDone(id: string) {
    const progress = this.progressMap.get(id);

    if (!progress) {
      return;
    }

    progress.done = true;

    this.updateProgress();
  }

  public setError(id: string) {
    const progress = this.progressMap.get(id);

    if (!progress) {
      return;
    }

    progress.error = true;

    this.updateProgress();
  }

  public removeProgress(progressId: string) {
    this.progressIds$.next(
      this.progressIds$.value.filter((id) => id !== progressId),
    );
  }

  public getForType(type: string) {
    return Array.from(this.progressMap.values()).filter(
      (job) => job.type === type,
    );
  }

  public removeAllProgress() {
    this.progressIds$.next([]);
    this.progressMap.clear();
    this.saveJobsToLocalStorage();
  }

  private updateProgress(progressId?: string) {
    const nextProgress = this.progressIds$.value;

    if (progressId) {
      this.progressIds$.next([progressId, ...nextProgress]);
    } else {
      this.progressIds$.next(nextProgress);
    }

    this.saveJobsToLocalStorage();
  }

  private saveJobsToLocalStorage() {
    const jobsInProgress = Array.from(this.progressMap.values()).filter(
      (j: Progress | null) => j && !j.done && !j.error && !j.noKeepLocalStorage,
    );

    window.localStorage.setItem(job_key, JSON.stringify(jobsInProgress));
  }

  public checkForJobsInLocalstorage() {
    const jobsInStorage = window.localStorage.getItem(job_key);

    const jobs = jobsInStorage ? JSON.parse(jobsInStorage) : [];

    jobs.map((job) => {
      this.registerProgress(job);
    });
  }
}
