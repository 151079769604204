import { Inject, Injectable, Optional } from '@angular/core';
import { makeState } from '@fp-tools/angular-state';
import {
  DatabaseDetailsService,
  DatabaseService,
  DB_TYPES,
} from '@sympheny/database/model';
import { ScenarioStore } from '@sympheny/project/scenario/data-access';
import { isArray } from 'lodash-es';
import {
  catchError,
  combineLatest,
  map,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';

import { SelectDbInitalValue } from '../select-item/model';
import {
  DB_SELECT_TOKEN_DB,
  DB_SELECT_TOKEN_EWZ,
  DB_SELECT_TOKEN_ORG,
  DB_SELECT_TOKEN_USER,
} from '../tokens';

interface DataSelectState {
  database: DB_TYPES | null;
  category: string | null;
  type: string | null;
  items: string[] | null;
  reload: number;

  // TODO check if we need these
  showEwz: boolean;
  showSymphenyDb: boolean;
  showOrganization: boolean;
  showUser: boolean;
}

const initialState = (
  state: Partial<DataSelectState> = {},
): DataSelectState => ({
  database: null,
  showSymphenyDb: true,
  showOrganization: true,
  showEwz: false,
  showUser: true,
  category: null,
  type: null,
  reload: null,
  items: null,
  ...state,
});

@Injectable()
export class DatabaseSelectSignalService<TECH> {
  protected readonly state = makeState(initialState());

  constructor(
    @Optional() protected readonly scenarioStore: ScenarioStore,
    protected readonly databaseService: DatabaseService,
    @Optional()
    @Inject(DB_SELECT_TOKEN_DB)
    protected readonly dbDetailsService: DatabaseDetailsService<TECH>,
    @Optional()
    @Inject(DB_SELECT_TOKEN_EWZ)
    protected readonly ewzDetailsService: DatabaseDetailsService<TECH>,
    @Optional()
    @Inject(DB_SELECT_TOKEN_USER)
    protected readonly userDetailsService: DatabaseDetailsService<TECH>,
    @Optional()
    @Inject(DB_SELECT_TOKEN_ORG)
    protected readonly orgDetailsService: DatabaseDetailsService<TECH>,
  ) {
    this.reloadData();
  }

  public async delete(guid: string) {
    const deleted = await this.getDataSource()?.delete(guid);

    this.reloadData();
    return deleted;
  }

  public async deleteCategory(category: string) {
    await this.getDataSource()?.deleteCategory(category);
    if (this.state.value?.category === category) {
      this.setCategory(null);
    }

    this.reloadData();

    return category;
  }

  public async deleteType(type: string) {
    await this.getDataSource()?.deleteType(this.state.get('category'), type);
    if (this.state.value?.type === type) {
      this.setType(null);
    }

    this.reloadData();

    return type;
  }

  public reloadData() {
    this.userDetailsService?.reload();
    this.orgDetailsService?.reload();
    this.dbDetailsService?.reload();
    this.userDetailsService?.reload();
    this.orgDetailsService?.reload();
    this.state.set('reload', Date.now());
  }

  // #region selection
  public setDatabase(database: any) {
    this.state.set('database', database);
  }

  public setType(type: any) {
    this.state.set((state) => ({ ...state, type }));
  }

  public setItems(items: any) {
    this.state.set((state) => ({ ...state, items }));
  }

  public setCategory(category: string) {
    this.state.set((state) => ({ ...state, category }));
  }

  public noCategories() {
    const datasource = this.getDataSource();

    if (!datasource) return false;

    return datasource.noCategories;
  }

  public initalValue({ database, category, items, type }: SelectDbInitalValue) {
    const itemSelected = items ? (isArray(items) ? items : [items]) : null;
    this.state.set((state) => ({
      ...state,
      database,
      type: type ?? null,
      category: category ?? null,
      items: itemSelected as any,
    }));
  }

  // #endregion

  public getCategories = this.listenToChanges('database', 'reload').pipe(
    switchMap(() => this.getCategorieApis()),
    catchError((error) => {
      console.error(error);
      return of(null);
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public hasTypes() {
    return this.listenToChanges('database').pipe(
      switchMap(() => this.hasTypes()),
      catchError((error) => {
        console.error(error);
        return of(null);
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private getCategorieApis() {
    const datasource = this.getDataSource();

    if (!datasource) return of(null);
    if (datasource.noCategories) return of(null);

    return datasource.categories$.pipe(
      map(
        (values) =>
          values?.map((v) =>
            typeof v === 'string' ? { label: v, value: v } : v,
          ),
        shareReplay({ refCount: true, bufferSize: 1 }),
      ),
    );
  }

  public getTypes = this.listenToChanges('category', 'database', 'reload').pipe(
    switchMap(() => this.getTypesApi()),
    catchError((error) => {
      console.error(error);
      return of(null);
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  private getTypesApi() {
    const datasource = this.getDataSource();

    if (!datasource) return of(null);

    if (!datasource.hasTypes) {
      return of(null);
    }
    const category = this.state.get('category');
    if (!category) return of(null);

    return datasource.getTechnologyCategoryDetails([category]);
  }

  public getItems = this.listenToChanges(
    'category',
    'database',
    'type',
    'reload',
  ).pipe(
    switchMap(() => this.getItemsRequest()),
    catchError((error) => {
      console.error(error);
      return of(null);
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as any;

  private getItemsRequest() {
    const datasource = this.getDataSource();

    if (!datasource) return of(null);

    if (datasource.noCategories) {
      return datasource.getTechnologyCategoryDetails([]);
    }

    const category = this.state.get('category');
    const type = this.state.get('type');

    if (!category) return of(null);

    if (datasource.hasTypes) {
      if (!type) return of(null);

      return datasource.getCategoryTypeDetails(category, type);
    }
    return datasource.getTechnologyCategoryDetails([category]);
  }

  public selectedItems() {
    return this.listenToChanges('reload', 'items').pipe(
      switchMap(() => this.getSelectedItemsRequest()),
    ) as any;
  }

  private getSelectedItemsRequest() {
    const datasource = this.getDataSource();
    if (!datasource) return of(null);

    const category = this.state.get('category');
    const items = this.state.get('items');

    if (!category || !items) return of(null);

    return combineLatest(
      items.map((item) =>
        datasource
          .getDetails(category, item, this.scenarioStore?.exchangeRate)
          .pipe(
            map((value) => ({ selectedValue: item, ...value })),
            catchError((errror) => {
              console.error(errror);
              return of(null);
            }),
          ),
      ),
    );
  }

  private listenToChanges(...keys: Array<keyof DataSelectState>) {
    return combineLatest(keys.map((key) => this.state.select(key))) as any;
  }

  public getDataSource() {
    const db = this.state.value?.database;
    switch (db) {
      case 'database':
        return this.dbDetailsService;
      case 'ewz':
        return this.ewzDetailsService;
      case 'user':
        return this.userDetailsService;
      case 'database-org':
        return this.orgDetailsService;
    }
    return null;
  }

  // #region DB restrictions
  public setEwz(ewz: boolean) {
    this.state.set('showEwz', ewz);
    if (ewz) {
      this.ewzDetailsService?.reload();
    }
  }

  public showUser(ewz: boolean) {
    this.state.set('showUser', ewz);
  }

  public showSymphenyDb(showSymphenyDb: boolean) {
    this.state.set('showSymphenyDb', showSymphenyDb);
  }

  public showOrganization(showOrganization: boolean) {
    this.state.set('showOrganization', showOrganization);
  }

  public readonly databases$ = this.listenToChanges(
    'showEwz',
    'showUser',
    'showSymphenyDb',
    'showOrganization',
  ).pipe(
    switchMap(([showEwz, showUser, showSymphenyDb, showOrganization]) =>
      this.databaseService.getDatabases(
        showEwz,
        showUser,
        showOrganization,
        showSymphenyDb,
      ),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
    catchError((error) => {
      console.error(error);
      return of([]);
    }),
  );

  // #endregion
}
