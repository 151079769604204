<ng-container [formGroup]="form">
  <ng-container *ngFor="let option of options">
    <sympheny-checkbox
      *ngIf="isEnabled(option)"
      (changeChecked)="onSaveToDbChange(option, $event)"
      [formControlName]="
        this.superuser || option === 'user' ? option : option + '_suggest'
      "
    >
      {{
        'DATABASE.save.' +
          option +
          (superuser || option === 'user' ? '' : '.suggest') | transloco
      }}
    </sympheny-checkbox>
  </ng-container>
</ng-container>
