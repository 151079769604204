import { SortDirection, sortByFn } from './sort-by-fn';

function byAttribute<T>(
  values: T[],
  attribute: string,
  childAttribute: string | undefined,
  direction: SortDirection,
) {
  if (!values?.length) {
    return values;
  }
  const dir = direction === 'asc' ? 1 : -1;
  const compareFn = (a: any, b: any) => {
    const aValue = childAttribute ? a[attribute][childAttribute] : a[attribute];
    const bValue = childAttribute ? b[attribute][childAttribute] : b[attribute];
    return (aValue < bValue ? -1 : 1) * dir;
  };

  return values.slice().sort(compareFn);
}

export const sortDataFn = <T>(
  values: T[],
  attribute: string,
  childAttribute: string | undefined,
  direction: SortDirection = 'asc',
) => {
  const mapDate = (t: T) => {
    const value = t[attribute];
    if (childAttribute) return value[childAttribute];

    return value;
  };

  return sortByFn(values, mapDate, direction);
};
/**
 * @deprecated use sortDataFn
 * @param values
 * @returns
 */
export function sortData<T>(values: T[]) {
  return {
    byAttribute: (
      attribute: string,
      childAttribute: string | undefined,
      direction: SortDirection,
    ) => byAttribute(values, attribute as any, childAttribute, direction),
  };
}
