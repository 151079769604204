import { NgIf, AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { ButtonComponent } from '@sympheny/ui/button';
import { DialogService } from '@sympheny/ui/dialog';
import { UserState } from '@sympheny/user/data-access';
import { distinctUntilChanged, map } from 'rxjs';

import { DatabaseSelectService } from '../service/database-select.service';

@Component({
  selector: 'sympheny-select-buttons',
  templateUrl: './select-buttons.component.html',
  styleUrls: ['./select-buttons.component.scss'],
  standalone: true,
  imports: [NgIf, ButtonComponent, AsyncPipe, TranslocoPipe],
})
export class SelectButtonsComponent {
  @Input() public selectedGuid: string;
  public readonly superuser = this.userState.isSu();

  @Output() public readonly edit = new EventEmitter<void>();
  @Output() public readonly delete = new EventEmitter<void>();

  constructor(
    private readonly userState: UserState,
    private readonly databaseSelectService: DatabaseSelectService<unknown>,
    private readonly dialogService: DialogService,
  ) {}
  public readonly isGlobalDb$ = this.databaseSelectService.database$.pipe(
    map((db) => db === 'database'),
    distinctUntilChanged(),
  );
  public readonly isNotGlobalDb$ = this.isGlobalDb$.pipe(map((db) => !db));

  public readonly isOrganisation$ = this.databaseSelectService.database$.pipe(
    map((db) => db === 'database-org'),
    distinctUntilChanged(),
  );
  public readonly isEwz$ = this.databaseSelectService.database$.pipe(
    map((db) => db === 'ewz'),
    distinctUntilChanged(),
  );
  public readonly isUser$ = this.databaseSelectService.database$.pipe(
    map((db) => db === 'user'),
    distinctUntilChanged(),
  );

  public onDelete() {
    if (!this.selectedGuid) return;

    // TODO confirmation?
    const confirmed = () =>
      this.databaseSelectService
        .delete(this.selectedGuid)
        .then((res) => this.delete.emit());

    this.dialogService.openConfirmationDialog(
      {
        title: 'DELETE.dialog.title',
        question: `Deleting is a permanent operation and can’t be undone.
    Are you sure to delete`,
      },
      confirmed,
    );
  }

  public onEdit() {
    if (!this.selectedGuid) return;

    this.edit.emit();
  }

  public get invalid() {
    return !this.selectedGuid;
  }
}
