import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { SnackbarService } from '@sympheny/ui/snackbar';
import { v4 as uuidv4 } from 'uuid';

import { ProgressComponent } from './progress/progress.component';
import { Progress, ProgressState } from './progress.state';

type ProgressMessage = {
  translateParams?: Record<string, any>;
  /**
   * Should be a prefix from a object message in en.json
   * format
   * {
   *    "error": "error",
   *    "success": "success",
   *    "name": "name",
   *    "started": "started",
   * }
   *
   *
   */
  message: string;
};
@Injectable({ providedIn: 'root' })
export class ProgressService {
  private overlayRef!: OverlayRef;

  constructor(
    private readonly overlay: Overlay,
    private readonly progressState: ProgressState,
    private readonly snackbarService: SnackbarService,
    private readonly translocoService: TranslocoService,
  ) {
    this.progressState.checkForJobsInLocalstorage();
  }

  public registerProgress(progress: Progress) {
    this.progressState.registerProgress(progress);
  }

  public setDone(id: string) {
    this.progressState.setDone(id);
  }
  public setError(id: string) {
    this.progressState.setError(id);
  }

  public handleProgress(message: ProgressMessage, promise: Promise<unknown>) {
    const id = `handle_progress_${uuidv4()}`;

    const snackbarOptions = {
      translateParams: message.translateParams,
    };

    this.snackbarService.success(`${message.message}.started`, snackbarOptions);
    this.registerProgress({
      done: false,
      error: false,
      type: 'handle-progress',
      id,
      name: this.translocoService.translate(
        `${message.message}.name`,
        message.translateParams,
      ),
      noKeepLocalStorage: true,
    });
    return promise
      .then(() => {
        this.snackbarService.success('project.copy.success', snackbarOptions);
        this.setDone(id);
      })
      .catch((error) => {
        this.setError(id);
        console.error(error);
        this.snackbarService.error('project.copy.error', snackbarOptions);

        throw new Error(error);
      });
  }

  public removeProgress(progressId: string) {
    this.progressState.removeProgress(progressId);
  }

  public getForType(type: string) {
    return this.progressState.getForType(type);
  }

  public enableProgress() {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    const componentPortal = new ComponentPortal(ProgressComponent);
    this.overlayRef.addPanelClass('example-overlay');
    this.overlayRef.attach(componentPortal);
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .bottom('0')
      .right('0');
    return new OverlayConfig({
      positionStrategy: positionStrategy,
    });
  }
}
