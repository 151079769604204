import { Injectable } from '@angular/core';
import {
  findByGuidZ,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, map, mapTo } from 'rxjs';

import { CrudService } from './crud.service';
import {
  ProjectVersion,
  TechnologyPackages,
  TechnologyPackagesDtoV1,
  TechnologyPackagesList,
  TechnologyPackagesSchemaV1,
} from '../model';

export enum TechnologyPackageFrom {
  database,
  custom,
}

@Injectable()
export class TechnologyPackagesServiceV1 extends CrudService<TechnologyPackages> {
  public readonly guidKey = 'guid';
  public readonly key = 'technologyPackages';

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    return this.http
      .get<
        ResponseModel<{ technologyPackages: TechnologyPackages[] }>
      >(`${this.base}scenarios/${scenarioGuid}/technology-packages`)
      .pipe(mapDataZ(TechnologyPackagesList, 'technologyPackages'));
  }

  /**
   * Create a TechnologyPackages
   */
  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<TechnologyPackages>,
    extra: TechnologyPackageFrom,
  ) {
    const url =
      extra === TechnologyPackageFrom.custom
        ? `technology-packages`
        : 'database-technology-packages';

    const mapper = TechnologyPackagesDtoV1;
    const responseMapper = TechnologyPackagesSchemaV1;
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<{ technologyPackages: TechnologyPackages[] }>
        >(`${this.base}scenarios/${scenarioGuid}/${url}`, mapDataRequest(mapper, data))
        .pipe(map((d) => d.data.technologyPackages[0])),
    );
  }

  /**
   * Update TechnologyPackages name
   */

  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: Partial<TechnologyPackages>,
  ) {
    const mapper = TechnologyPackagesDtoV1;
    const responseMapper = TechnologyPackagesSchemaV1;
    return firstValueFrom(
      this.http
        .put<
          ResponseModel<{ technologyPackages: TechnologyPackages[] }>
        >(`${this.base}scenarios/${scenarioGuid}/technology-packages/${guid}`, mapDataRequest(mapper, data))
        .pipe(
          findByGuidZ(responseMapper, this.guidKey, guid, 'technologyPackages'),
        ),
    );
  }

  /**
   * Delete TechnologyPackages
   */

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
  ) {
    return firstValueFrom(
      this.http
        .delete(
          `${this.base}scenarios/${scenarioGuid}/technology-packages/${guid}`,
        )
        .pipe(mapTo(guid)),
    );
  }
}
