import z from 'zod';

export const ResultImportSchema = z.object({
  name: z.string(),
  stage: z.string(),
  hub: z.string(),
  capacity: z.number(),
  capacityBinary: z.number(),
  monthlyCapacity: z.array(z.number()),
  importEnergy: z.array(z.number()),
  capacityCost: z.number(),
  energyCost: z.number(),
  co2: z.number(),
  om: z.number(),
  energyCarrier: z.string(),
  monthlyEnergy: z.array(z.number()),
  point: z.number().nullish(),
});

export type ResultImport = z.infer<typeof ResultImportSchema>;
