import { LoadDataService } from '@sympheny/utils/data-access';

import {
  SolarEnergyOrgDatabaseProfileCollection,
  SolarEnergyDatabaseProfileCollection,
  SolarEnergySavedProfileCollection,
} from './solar-resources';

export const provideDatabaseDataAccessOnSiteResources = () => {
  return [
    SolarEnergyDatabaseProfileCollection,
    SolarEnergySavedProfileCollection,
    SolarEnergyOrgDatabaseProfileCollection,
    {
      multi: true,
      provide: LoadDataService,
      useExisting: SolarEnergyDatabaseProfileCollection,
    },
    {
      multi: true,
      provide: LoadDataService,
      useExisting: SolarEnergySavedProfileCollection,
    },
    {
      multi: true,
      provide: LoadDataService,
      useExisting: SolarEnergyOrgDatabaseProfileCollection,
    },
  ];
};
