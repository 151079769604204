import { ComponentType } from '@angular/cdk/portal';
import { Injectable, ViewContainerRef } from '@angular/core';
import { DIALOG_WIDTH, DialogService } from '@sympheny/ui/dialog';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DatabaseDialogService<TECH> {
  constructor(private dialogService: DialogService) {}

  /**
   * Open dialog/popup
   */
  public open<C, DATA, RETURN_TYPE>(
    dialogComponent: ComponentType<C>,
    data: DATA,
    viewContainerRef?: ViewContainerRef,
    width?: string,
  ): Promise<RETURN_TYPE> {
    const dialogRef = this.dialogService.openDialog(
      dialogComponent,
      data,
      width ?? DIALOG_WIDTH.xlarge,
      viewContainerRef,
    );

    return firstValueFrom(dialogRef.afterClosed());
  }
}
