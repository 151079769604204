import { LoadDataService } from '@sympheny/utils/data-access';

import {
  EnergyDemandSavedUserProfileCollection,
  EnergyDemandDatabaseCollection,
  OrgEnergyDemandDatabaseCollection,
} from './collection';

export const provideDatabaseDataAccessEnergyDemands = () => [
  EnergyDemandSavedUserProfileCollection,
  EnergyDemandDatabaseCollection,
  OrgEnergyDemandDatabaseCollection,
  {
    multi: true,
    provide: LoadDataService,
    useExisting: EnergyDemandSavedUserProfileCollection,
  },
  {
    multi: true,
    provide: LoadDataService,
    useExisting: EnergyDemandDatabaseCollection,
  },
  {
    multi: true,
    provide: LoadDataService,
    useExisting: OrgEnergyDemandDatabaseCollection,
  },
];
