import { ProvideConversionTechnology } from './conversion-technology';
import { ProvideNetworkTechnology } from './network-technology';
import { ProvideStorageTechnology } from './storage-technology';
import { ProvideTechnologyPackage } from './technology-package';
// import { EwzTechnologyPackageCollectionService } from './technology-package/collections/ewz-technology-package-collection.service';

export const provideProjectSupplyTechnologyDataAccess = () => {
  return [
    ProvideConversionTechnology(),
    ProvideNetworkTechnology(),
    ProvideStorageTechnology(),

    ProvideTechnologyPackage(),
  ];
};
