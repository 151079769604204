import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { CheckboxComponent } from '@sympheny/ui/form';
import { UserState, isEwzOrganization } from '@sympheny/user/data-access';

export type SaveTechControl = FormGroup<{
  ewz: FormControl<boolean>;
  ewz_suggest: FormControl<boolean>;
  db: FormControl<boolean>;
  db_suggest: FormControl<boolean>;
  user: FormControl<boolean>;
}>;

@Component({
  selector: 'sympheny-save-to-db-checkbox',
  templateUrl: './save-to-db-checkbox.component.html',
  styleUrls: ['./save-to-db-checkbox.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgFor, NgIf, CheckboxComponent, TranslocoPipe],
})
export class SaveToDbCheckboxComponent implements OnInit {
  public readonly options = ['ewz', 'user', 'db'];
  public form: FormGroup;
  public readonly superuser = this.userState.isSu();

  constructor(
    public readonly userState: UserState,
    private readonly controlContainer: ControlContainer,
  ) {}
  private _ewz = false;

  @Input()
  public set ewz(ewz: boolean) {
    this._ewz = ewz;
  }
  private _db = false;

  @Input()
  public set db(db: boolean) {
    this._db = db;
  }

  @Input()
  public user: boolean;

  @Output() public readonly saveToDbChange = new EventEmitter<{
    option: string;
    checked: boolean;
  }>();

  public ngOnInit() {
    this.form = this.controlContainer.control as FormGroup;

    this.options.forEach((option) => {
      this.form.addControl(option, new FormControl(false));
      this.form.addControl(`${option}_suggest`, new FormControl(false));
    });
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  public get db() {
    return this.userState.planLimitation.organizationDb && this._db;
  }
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  public get ewz() {
    return isEwzOrganization(this.userState.organisationName) && this._ewz;
  }

  public isEnabled(isEnabled: string) {
    return this[isEnabled];
  }

  public onSaveToDbChange(option: string, checked: boolean) {
    this.saveToDbChange.emit({ option, checked });
  }
}
