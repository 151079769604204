import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapDataRequest, mapResponse } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, map } from 'rxjs';

import {
  Dataset,
  DatasetAddressSchema,
  DatasetSchema,
  DatasetSummary,
  DatasetSummarySchema,
} from './dataset.model';

@Injectable()
export class DatasetService {
  constructor(
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService,
  ) {}

  protected readonly gis =
    this.environmentService.getValue('apiServices') + 'gis/';

  public list() {
    return firstValueFrom(
      this.http
        .get<DatasetSummary[]>(`${this.gis}datasets`)
        .pipe(mapResponse(DatasetSummarySchema)),
    );
  }

  public create(dataset: Partial<Dataset>) {
    return firstValueFrom(
      this.http
        .post<Dataset>(
          `${this.gis}datasets`,
          mapDataRequest(DatasetAddressSchema, dataset),
        )
        .pipe(mapResponse(DatasetSchema)),
    );
  }

  public delete(dataset_id: string) {
    return firstValueFrom(
      this.http
        .delete<Dataset>(`${this.gis}datasets/${dataset_id}`)
        .pipe(map(() => dataset_id)),
    );
  }

  public loadDataForScenario(dataSetId: string, scenarioId: string) {
    return firstValueFrom(
      this.http.get<Dataset>(`${this.gis}datasets/${dataSetId}`, {
        params: { scenarioId },
      }),
    );
  }
}
