import { NgIf, AsyncPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  computed,
  signal,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { EnergyCarrier } from '@sympheny/project/data-access';
import { ScenarioStore } from '@sympheny/project/scenario/data-access';
import { SelectComponent } from '@sympheny/ui/form';

@Component({
  selector: 'sympheny-energy-carrier-select',
  templateUrl: './energy-carrier-select.component.html',
  styleUrls: ['./energy-carrier-select.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SelectComponent,
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class EnergyCarrierSelectComponent
  implements ControlValueAccessor, OnInit
{
  @Input() public disableSelectAll = false;
  @Input() public label?: string;
  @Input() public placeholder?: string;
  @Input() public dataCy?: string;
  @Input() public required = false;
  @Input() public hint: string;
  @Input() public filterPredicate?: (EnergyCarrier: EnergyCarrier) => boolean;
  @Input() public multiple = false;
  @Input() public maxValues = undefined;
  @Input() public requiredMessage = 'FORM.requiredHint';
  @Input() public disabledIds?: string[];
  @Output() public readonly selectionChange = new EventEmitter<any | null>();

  public formControl = null;
  public changeFn: (option: string) => void;
  public touchFn: () => void;

  public errorMessages = {
    required: 'Energy Carrier is required',
  };
  private readonly excludedGuids_ = signal([]);

  private readonly ecs = this.scenarioStore.selectValue_s('energyCarriers');

  public readonly energyCarriers = computed(() => {
    let energyCarriers = this.ecs();

    if (!energyCarriers?.length) return [];
    if (this.filterPredicate) {
      energyCarriers = energyCarriers.filter((e) => this.filterPredicate(e));
    }

    const excluded = this.excludedGuids_();

    if (excluded && excluded.length) {
      energyCarriers = energyCarriers.filter(
        (e) => excluded.indexOf(e.energyCarrierGuid) < 0,
      );
    }

    return energyCarriers;
  });

  constructor(
    private readonly ngControl: NgControl,
    private readonly scenarioStore: ScenarioStore,
  ) {
    this.ngControl.valueAccessor = this;
  }

  @Input() public set excludedGuids(guids: string[]) {
    this.excludedGuids_.set(guids);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public writeValue(obj: any): void {}

  public registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  public ngOnInit(): void {
    this.formControl = this.ngControl.control as FormControl;
  }
}
