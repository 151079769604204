import { parseISO } from 'date-fns';

import { SortDirection, sortByFn } from './sort-by-fn';

export const sortByDateFn = <T>(
  values: T[],
  attribute: keyof T,
  direction: SortDirection,
) => {
  const mapDate = (t: T) => {
    const value = t[attribute];
    if (!value) return null;
    if (value instanceof Date) return value.getTime();
    return parseISO(value as string).getTime();
  };

  return sortByFn(values, mapDate, direction);
};
