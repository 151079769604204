/* eslint-disable unused-imports/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ResponseModel,
  SymphenyErrorHandler,
  mapData,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Observable, firstValueFrom } from 'rxjs';

import { ProjectVersion } from '../model';

@Injectable()
export abstract class CrudService<T, DETAIL = T, DTO = Partial<DETAIL>> {
  public abstract readonly guidKey: keyof T;
  public abstract readonly key: string;

  constructor(
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService,
    protected readonly errorHandler: SymphenyErrorHandler,
  ) {
    this.base = this.environmentService.getValue('base');
  }

  protected readonly base: string;

  public list(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    scenarioMasterGuid?: string,
  ): Observable<T[]> {
    throw new Error('implement get api');
  }

  public create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: DTO,
    extra?: any,
  ): Promise<T> {
    throw new Error('implement create api');
  }

  public createMultiple(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: DTO[],
    extra?: any,
  ): Promise<T[]> {
    throw new Error('implement create api');
  }

  public update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    id: string,
    data: DTO,
    extra?: any,
  ): Promise<T> {
    throw new Error('implement update api');
  }

  public get(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    id: string,
    extra?: any,
  ): Promise<DETAIL> {
    throw new Error('implement get api');
  }

  public delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    id: string,
    data?: any,
  ): Promise<string> {
    throw new Error('implement delete api');
  }

  protected async uploadProfileAndUpdateId(
    scenarioGuid: string,
    updateWithProfile: DTO,
    fileNameFieldName: string,
    profileIdFieldName: string,
  ) {
    const file = updateWithProfile[fileNameFieldName];

    if (!file) return;
    const url = `scenarios/${scenarioGuid}/profiles`;

    const data = {
      name: new Date().getTime() + (file as any).fileName,
      values: file,
    };
    const profile = await firstValueFrom(
      this.http
        .post<ResponseModel<any>>(`${this.base}${url}`, data)
        .pipe(mapData()),
    );
    (updateWithProfile[profileIdFieldName] as any) = profile.id as any;
    updateWithProfile[fileNameFieldName] = null;

    return;
  }
}
