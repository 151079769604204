import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadFile } from '@sympheny/ui/form/model';
import { mapResponse } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Observable } from 'rxjs';

import {
  Profile,
  ProfileSchema,
  UserPreferences,
  UserPreferencesSchema,
} from '../model/account.model';

@Injectable()
export class ProfileService {
  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService,
  ) {}

  private readonly profileApi = `${this.environmentService.backOfficeApi}users/profile`;
  /**
   * Get profile info
   */
  public profile(): Observable<Profile> {
    return this.http
      .get<Profile>(`${this.profileApi}`)
      .pipe(mapResponse(ProfileSchema));
  }

  /**
   * Save profile data
   *
   * @param profile
   */
  public savePreferences(
    preferences: UserPreferences,
  ): Observable<UserPreferences> {
    return this.http
      .put<UserPreferences>(`${this.profileApi}/preferences`, preferences)
      .pipe(mapResponse(UserPreferencesSchema));
  }

  /**
   * Save profile image
   *
   * @param imageData
   */
  public uploadProfilePicture(imageData: UploadFile): Observable<string> {
    return this.http
      .put<string>(
        `${this.profileApi}/profile-picture`,
        this.createMultiPartFormdata(imageData),
      )
      .pipe(mapResponse(ProfileSchema));
  }

  /**
   * Save organisation image
   *
   * @param imageData
   */
  public uploadOrganisationPicture(imageData: UploadFile): Observable<string> {
    return this.http
      .put<string>(
        `${this.profileApi}/organization-picture`,
        this.createMultiPartFormdata(imageData),
      )
      .pipe(mapResponse(ProfileSchema));
  }
  private createMultiPartFormdata(imageData: UploadFile) {
    const formData = new FormData();
    formData.append('file', imageData.file);

    return formData;
  }
}
