import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

import { EnergyCarrierSchema } from './energy-carrier.model';
import { HubSchema } from './hub.model';

const IntraHubAdvancedCostComponent = z.object({
  category: z.string().nullish(),
  categoryId: z.string().nullish(),
  complexityFactor: SymphenyNumber().nullish(),
  dataPoints: SymphenyNumber().nullish(),
  guid: z.string().nullish(),
  interestRate: SymphenyNumber().nullish(),
  length: SymphenyNumber().nullish(),
  lifetime: SymphenyNumber().nullish(),
  name: z.string().nullish(),
  numberOfPumps: SymphenyNumber().nullish(),
  value: SymphenyNumber().nullish(),
});

const IntraHubNetworkLinkSchema = z.object({
  advancedCostComponents: z.array(IntraHubAdvancedCostComponent).nullish(),
  created: SymphenyDate().nullish(),
  fixedEmbodiedCo2: SymphenyNumber().nullish(),
  hubs: z.array(HubSchema).nullish(),
  inputEnergyCarrier: EnergyCarrierSchema,
  intraHubNetworkLinkGuid: z.string().nullish(),
  name: z.string().nullish(),
  networkLoss: SymphenyNumber().nullish(),
  outputEnergyCarrier: EnergyCarrierSchema.nullish(),
  stages: z.array(z.string()),
  updated: SymphenyDate().nullish(),
});

export const IntraHubNetworkLinkDtoSchema = z.object({
  advancedCostComponents: z.array(
    z.object({
      category: z.string().nullish(),
      complexityFactor: SymphenyNumber().nullish(),
      dataPoints: SymphenyNumber().nullish(),
      interestRate: SymphenyNumber().nullish(),
      length: SymphenyNumber().nullish(),
      lifetime: SymphenyNumber().nullish(),
      name: z.string().nullish(),
      numberOfPumps: SymphenyNumber().nullish(),
      value: SymphenyNumber().nullish(),
    }),
  ),
  fixedEmbodiedCo2: SymphenyNumber().nullish(),
  hubGuids: z.array(z.string()),
  inputEnergyCarrierGuid: z.string().nullish(),
  name: z.string().nullish(),
  networkLoss: SymphenyNumber().nullish(),
  outputEnergyCarrierGuid: z.string().nullish(),
  stages: z.array(z.string()).nullish(),
});

export const IntraHubNetworkLinkListSchema = z.object({
  advancedCostComponents: z.array(IntraHubAdvancedCostComponent).nullish(),
  created: SymphenyDate().nullish(),
  fixedEmbodiedCo2: SymphenyNumber().nullish(),
  hubs: z.array(HubSchema).nullish(),
  inputEnergyCarrier: EnergyCarrierSchema.nullish(),
  intraHubNetworkLinkGuid: z.string().nullish(),
  name: z.string().nullish(),
  networkLoss: SymphenyNumber().nullish(),
  outputEnergyCarrier: EnergyCarrierSchema.nullish(),
  stages: z.array(z.string()).nullish(),
  updated: SymphenyDate().nullish(),
});

// #region v1
export const IntraHubNetworkLinkSchemaV1 = IntraHubNetworkLinkSchema.omit({
  stages: true,
});
export type IntraHubNetworkLinkV1 = z.infer<typeof IntraHubNetworkLinkSchemaV1>;
export const IntraHubNetworkLinkListSchemaV1 =
  IntraHubNetworkLinkListSchema.omit({
    stages: true,
  });
export type IntraHubNetworkLinkListV1 = z.infer<
  typeof IntraHubNetworkLinkListSchemaV1
>;

// #endregion

// #region v2
export const IntraHubNetworkLinkListSchemaV2 =
  IntraHubNetworkLinkListSchema.omit({});
export const IntraHubNetworkLinkSchemaV2 = IntraHubNetworkLinkSchema.omit({});
export type IntraHubNetworkLinkV2 = z.infer<typeof IntraHubNetworkLinkSchema>;
export type IntraHubNetworkLinkListV2 = z.infer<
  typeof IntraHubNetworkLinkListSchemaV2
>;
// #endregion

export type IntraHubNetworkLink = IntraHubNetworkLinkV1 & IntraHubNetworkLinkV2;
export type IntraHubNetworkLinkList = IntraHubNetworkLinkListV1 &
  IntraHubNetworkLinkListV2;
