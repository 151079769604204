import { Inject, Injectable, Optional } from '@angular/core';
import { makeState } from '@fp-tools/angular-state';
import {
  DatabaseDetailsService,
  DatabaseService,
  DB_TYPES,
} from '@sympheny/database/model';
import { ProjectVersion } from '@sympheny/project/data-access';
import { ScenarioStore } from '@sympheny/project/scenario/data-access';
import {
  catchError,
  combineLatest,
  map,
  merge,
  of,
  shareReplay,
  Subject,
  switchMap,
} from 'rxjs';

import { SelectDbInitalValue } from '../select-item/model';
import {
  DB_SELECT_TOKEN_DB,
  DB_SELECT_TOKEN_EWZ,
  DB_SELECT_TOKEN_ORG,
  DB_SELECT_TOKEN_USER,
} from '../tokens';

interface DataSelectState<TECH> {
  database: DB_TYPES | null;
  showEwz: boolean;
  showSymphenyDb: boolean;
  showOrganization: boolean;
  showUser: boolean;
  category: string[] | null;
  type: string[] | null;
  technology: string[] | null;
  projectVersion: ProjectVersion;
}

const initialState = <TECH>(
  state: Partial<DataSelectState<TECH>> = {},
): DataSelectState<TECH> => ({
  database: null,
  showSymphenyDb: true,
  showOrganization: true,
  showEwz: false,
  showUser: true,
  category: null,
  type: null,
  technology: null,
  projectVersion: 'V1',
  ...state,
});

@Injectable()
export class DatabaseSelectService<TECH> {
  private readonly reloadData$ = new Subject<void>();
  private readonly state = makeState(initialState<TECH>());
  public readonly database$ = this.state.select('database');
  public readonly category$ = this.state.select('category');
  public readonly databases$ = combineLatest(
    this.state.select('showEwz'),
    this.state.select('showUser'),
    this.state.select('showSymphenyDb'),
    this.state.select('showOrganization'),
  ).pipe(
    switchMap(([showEwz, showUser, showSymphenyDb, showOrganization]) =>
      this.databaseService.getDatabases(
        showEwz,
        showUser,
        showOrganization,
        showSymphenyDb,
      ),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
    catchError((error) => {
      console.error(error);
      return of([]);
    }),
  );

  public readonly categories$ = this.state.select('database').pipe(
    switchMap(() => this.getDataSource()?.categories$ ?? of(null)),
    map((values) =>
      values?.map((v) => (typeof v === 'string' ? { label: v, value: v } : v)),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
    catchError((error) => {
      console.error(error);
      return of(null);
    }),
  );

  public readonly technologies$ = this.reload('category').pipe(
    switchMap(
      (category) =>
        (category &&
          this.getDataSource()?.getTechnologyCategoryDetails(category)) ??
        of(null),
    ),

    shareReplay({ refCount: true, bufferSize: 1 }),
    catchError((error) => {
      console.error(error);
      return of(null);
    }),
  );

  public readonly selectedValue$ = combineLatest(
    this.state.select('technology'),
    this.state.select('category'),
  ).pipe(
    switchMap(([technologies, categories]) =>
      technologies && technologies.length && categories && categories.length
        ? combineLatest(
            technologies?.map((technology) => {
              return this.getDataSource()?.getDetails(
                categories[0],
                technology,
                this.scenarioStore?.exchangeRate,
              );
            }),
          )
        : of(null),
    ),
    map((techs) => techs?.flat()),
    shareReplay({ refCount: true, bufferSize: 1 }),
    catchError((error) => {
      console.error(error);
      return of(null);
    }),
  );

  constructor(
    @Optional() private readonly scenarioStore: ScenarioStore,
    private readonly databaseService: DatabaseService,
    @Optional()
    @Inject(DB_SELECT_TOKEN_DB)
    private readonly dbDetailsService: DatabaseDetailsService<TECH>,
    @Optional()
    @Inject(DB_SELECT_TOKEN_EWZ)
    private readonly ewzDetailsService: DatabaseDetailsService<TECH>,
    @Optional()
    @Inject(DB_SELECT_TOKEN_USER)
    private readonly userDetailsService: DatabaseDetailsService<TECH>,
    @Optional()
    @Inject(DB_SELECT_TOKEN_ORG)
    private readonly orgDetailsService: DatabaseDetailsService<TECH>,
  ) {
    this.userDetailsService?.reload();
    this.orgDetailsService?.reload();
    this.dbDetailsService?.reload();
    this.userDetailsService?.reload();
    this.orgDetailsService?.reload();
  }

  public setEwz(ewz: boolean) {
    this.state.set('showEwz', ewz);
    if (ewz) {
      this.ewzDetailsService?.reload();
    }
  }

  public showUser(ewz: boolean) {
    this.state.set('showUser', ewz);
  }
  public projectVersion(projectVersion: ProjectVersion) {
    this.state.set('projectVersion', projectVersion);
  }

  public showSymphenyDb(showSymphenyDb: boolean) {
    this.state.set('showSymphenyDb', showSymphenyDb);
  }

  public showOrganization(showOrganization: boolean) {
    this.state.set('showOrganization', showOrganization);
  }

  public setDatabase(database: DB_TYPES | 'ewz') {
    this.state.set((state) => ({
      ...state,
      database,
      category: null,
      technology: null,
    }));
  }

  public setCategory(category: string) {
    this.state.set((state) => ({
      ...state,
      // TODO multi
      category: category && [category],
      technology: null,
    }));
  }

  public setTechnology(technology: string[]) {
    this.state.set((state) => ({
      ...state,
      technology,
    }));
  }

  public setType(type: string) {
    this.state.set((state) => ({
      ...state,
      type: type && [type],
    }));
  }

  public setProjectVersion(projectVersion: ProjectVersion) {
    this.state.set((state) => ({
      ...state,
      projectVersion,
    }));
  }

  public async delete(guid: string) {
    await this.getDataSource()?.delete(guid);
    this.reloadData$.next();
    this.getDataSource()?.reload();
  }

  public async deleteCategory(category: string) {
    await this.getDataSource()?.deleteCategory(category);
    this.reloadData$.next();
    this.getDataSource()?.reload();
  }
  public async update(guid: string, data: Partial<TECH>) {
    await this.getDataSource()?.update(
      // this.state.get('projectVersion'),
      guid,
      data,
    );
    this.reloadData$.next();
    this.getDataSource()?.reload();
  }

  public async create(data: Partial<TECH>) {
    await this.getDataSource()?.create(
      // this.state.get('projectVersion'),

      data,
    );
    this.reloadData$.next();
    this.getDataSource()?.reload();
  }

  public reloadData() {
    this.reloadData$.next();
    this.getDataSource()?.reload();
  }

  public initalValue({ database, category, technology }: SelectDbInitalValue) {
    this.state.set((state) => ({
      ...state,
      database,
      category: (category && [category]) ?? null,
      technology:
        (technology &&
          (Array.isArray(technology) ? technology : [technology])) ??
        null,
    }));
  }

  private reload<K extends keyof DataSelectState<TECH>>(key: K) {
    return merge(
      this.reloadData$.pipe(map(() => this.state.get(key))),
      this.state.select(key),
    );
  }

  private getDataSource() {
    const db = this.state.get('database');
    switch (db) {
      case 'database':
        return this.dbDetailsService;
      case 'ewz':
        return this.ewzDetailsService;
      case 'user':
        return this.userDetailsService;
      case 'database-org':
        return this.orgDetailsService;
    }
    return null;
  }
}
