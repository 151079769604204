// eslint-disable-next-line @nx/enforce-module-boundaries
import { createProviders } from '@sympheny/database/select';
import { createLoadProviders } from '@sympheny/utils/data-access';

import { DatabaseNetworkTechnologyCollection } from './collections/database-network-technology-collection.service';
import { EwzNetworkTechnologyCollection } from './collections/ewz-network-technology-collection.service';
import { OrgDatabaseNetworkTechnologyCollection } from './collections/org-database-network-technology-collection.service';
import { UserNetworkTechnologyCollection } from './collections/user-network-technology-collection.service';
import { DatabaseNetworkTechnologyCollectionV2 } from './collections-v2/database-network-technology-collection.service';
import { EwzNetworkTechnologyCollectionV2 } from './collections-v2/ewz-network-technology-collection.service';
import { OrgDatabaseNetworkTechnologyCollectionV2 } from './collections-v2/org-database-network-technology-collection.service';
import { UserNetworkTechnologyCollectionV2 } from './collections-v2/user-network-technology-collection.service';

export * from './collections/database-network-technology-collection.service';
export * from './collections/ewz-network-technology-collection.service';
export * from './collections/org-database-network-technology-collection.service';
export * from './collections/user-network-technology-collection.service';

export * from './collections-v2/database-network-technology-collection.service';
export * from './collections-v2/ewz-network-technology-collection.service';
export * from './collections-v2/org-database-network-technology-collection.service';
export * from './collections-v2/user-network-technology-collection.service';

export const createNetworkProvidersV2 = () =>
  createProviders({
    dbDetailsService: DatabaseNetworkTechnologyCollectionV2,
    ewzDetailsService: EwzNetworkTechnologyCollectionV2,
    userDetailsService: UserNetworkTechnologyCollectionV2,
    orgDetailsService: OrgDatabaseNetworkTechnologyCollectionV2,
  });

export const createNetworkProviders = () =>
  createProviders({
    dbDetailsService: DatabaseNetworkTechnologyCollection,
    ewzDetailsService: EwzNetworkTechnologyCollection,
    userDetailsService: UserNetworkTechnologyCollection,
    orgDetailsService: OrgDatabaseNetworkTechnologyCollection,
  });

export const ProvideNetworkTechnology = () => {
  return createLoadProviders([
    OrgDatabaseNetworkTechnologyCollection,
    DatabaseNetworkTechnologyCollection,
    EwzNetworkTechnologyCollection,
    UserNetworkTechnologyCollection,
    OrgDatabaseNetworkTechnologyCollectionV2,
    DatabaseNetworkTechnologyCollectionV2,
    EwzNetworkTechnologyCollectionV2,
    UserNetworkTechnologyCollectionV2,
  ]);
};
