import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { BannerEffects } from '../services/banner.effects';

@Component({
  selector: 'sympheny-show-gtc',
  templateUrl: './show-gtc.component.html',
  styleUrls: ['./show-gtc.component.scss'],
  standalone: true,
  imports: [MatButtonModule],
})
export class ShowGtcComponent {
  constructor(private bannerEffects: BannerEffects) {}

  public acceptGTC() {
    this.bannerEffects.dismiss('showGtc');
  }
}
