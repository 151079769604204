import { Node } from '../utils/model';

export const NODE_TYPES = {
  EC: 'Energy Carrier',
  VL: 'Virtual Load',
  LS: 'Load Shedding',
  IMP: 'Import',
  EXP: 'Export',
  ONSITE: 'On site resource',
  STG: 'Storage',
  STG_DIS: 'Storage Discharge',
  STG_CHG: 'Storage Charge',
  INTRA: 'Intra Hub',
  LINK_OUT: 'Link Out',
  LINK_IN: 'Link In',
  DEMAND: 'Energy Demand',
  MODE: 'Conversion Mode',
};
export const inputNodeTypes: NodeType[] = [
  'IMP',
  'DEMAND',
  'ONSITE',
  'LINK_IN',
] as const;
export const exportNodeTypes: NodeType[] = [
  'EXP',
  'DEMAND',
  'LINK_OUT',
] as const;

export type NodeType = keyof typeof NODE_TYPES;

export type EhubType = 'sankey' | 'ehub';

export type EhubNodeType = 'ENERGY CARRIER';
export type EhubNode = Node & {
  capacity: number;
  edgeLabel: string;
  label?: string;
  type: NodeType;
  unit: { unit: string };
  color: string;
  energyCarrier: string;
};
