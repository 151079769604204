// eslint-disable-next-line @nx/enforce-module-boundaries
import { createProviders } from '@sympheny/database/select';
import { createLoadProviders } from '@sympheny/utils/data-access';

import { DatabaseTechnologyPackageCollectionService } from './collections/database-technology-package-collection.service';
import { OrgDatabaseTechnologyPackageCollectionService } from './collections/org-database-technology-package-collection.service';
import { UserTechnologyPackageCollectionService } from './collections/user-technology-package-collection.service';
import { DatabaseTechnologyPackageCollectionServiceV2 } from './collections/v2/database-technology-package-collection.service';
import { OrgDatabaseTechnologyPackageCollectionServiceV2 } from './collections/v2/org-database-technology-package-collection.service';
import { TechnologyPackageSelectService } from './collections/v2/technology-package-select.service';
import { UserTechnologyPackageCollectionServiceV2 } from './collections/v2/user-technology-package-collection.service';

export * from './collections/database-technology-package-collection.service';
export * from './collections/org-database-technology-package-collection.service';
export * from './collections/user-technology-package-collection.service';
export * from './collections/v2/database-technology-package-collection.service';
export * from './collections/v2/org-database-technology-package-collection.service';
export * from './collections/v2/user-technology-package-collection.service';
export * from './collections/v2/technology-package-select.service';

export const createTechnologyPackageProvidersV2 = () =>
  createProviders({
    dbDetailsService: DatabaseTechnologyPackageCollectionServiceV2,
    //ewzDetailsService: EwzTechnologyPackageCollectionService,
    userDetailsService: UserTechnologyPackageCollectionServiceV2,
    orgDetailsService: OrgDatabaseTechnologyPackageCollectionServiceV2,
    databaseSelectSignalService: TechnologyPackageSelectService,
  });

export const createTechnologyPackageProviders = () =>
  createProviders({
    dbDetailsService: DatabaseTechnologyPackageCollectionService,
    //ewzDetailsService: EwzTechnologyPackageCollectionService,
    userDetailsService: UserTechnologyPackageCollectionService,
    orgDetailsService: OrgDatabaseTechnologyPackageCollectionService,
  });

export const ProvideTechnologyPackage = () => {
  return createLoadProviders([
    DatabaseTechnologyPackageCollectionService,
    UserTechnologyPackageCollectionService,
    OrgDatabaseTechnologyPackageCollectionService,
    DatabaseTechnologyPackageCollectionServiceV2,
    UserTechnologyPackageCollectionServiceV2,
    OrgDatabaseTechnologyPackageCollectionServiceV2,
  ]);
};
