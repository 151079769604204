// Iteratively assign the depth for each node.
// Nodes are assigned the maximum depth of incoming neighbors plus one;
// nodes with no incoming links are assigned depth zero, while

import { maxBy, minBy } from 'lodash-es';

import { Graph, GraphData, Node } from '../../model';
import {
  sortNodeBy,
  sortNodeByColumnRow,
  sortNodeByReverse,
} from '../../utils/node';
const maxIterations = 10;

const groupByTarget = (data: Readonly<GraphData>, nodes: Node[]) => {
  const result = {};

  nodes.forEach((node) => {
    // If the node is a node with each time one target/one link it already had an row
    if (node.row) return;

    const targetLinks = data.getSourceLinks(node);
    if (targetLinks.length === 0) {
      // use the source instead
      const sourceLinks = data.getTargetLinks(node);
      sourceLinks.forEach((l) => {
        const list = result[l.source] ?? [];
        list.push(node);
        result[l.source] = list;
      });
    } else
      targetLinks.forEach((l) => {
        const list = result[l.target] ?? [];
        list.push(node);
        result[l.target] = list;
      });
  });

  const targeNodes = Object.keys(result)
    .map((t) => data.getNode(t))
    .sort(sortNodeByColumnRow()); //.sort(sortNodeBy('index'));

  return { result, targeNodes };
};

const computeLatestNodeBasedOnSingleLinking = (data: Readonly<GraphData>) => {
  const singleNodes = [];

  data.forEachNodeAtColumn(data.maxColumns(), (nodes) =>
    nodes.forEach((node) => {
      // Check the flow until we are at the source or it has more than one source links
      let iteration = 1;
      let { sourceLinks, sourceNodes } = data.getSourceLinksWithNodes(node);
      while (
        iteration < maxIterations &&
        sourceLinks.length === 1 &&
        !sourceLinks[0].isSelfLinking
      ) {
        const result = data.getSourceLinksWithNodes(sourceNodes[0]);
        sourceLinks = result.sourceLinks;
        sourceNodes = result.sourceNodes;

        if (sourceLinks.length === 0) singleNodes.push(node);
        iteration += 1;
      }
    }),
  );

  const groupedNodes: Record<number, Node[]> = {};

  singleNodes.forEach((node) => {
    let list: Node[] = groupedNodes[node.column] ?? [];
    list.push(node);
    node.setValue('single', true);
    groupedNodes[node.column] = list;
    let { sourceLinks, sourceNodes } = data.getSourceLinksWithNodes(node);

    while (sourceLinks.length === 1) {
      const souceNode = sourceNodes[0];
      list = groupedNodes[souceNode.column] ?? [];
      list.push(souceNode);
      groupedNodes[souceNode.column] = list;

      const result = data.getSourceLinksWithNodes(sourceNodes[0]);
      sourceLinks = result.sourceLinks;
      sourceNodes = result.sourceNodes;
    }
    if (sourceNodes.length) {
      const souceNode = sourceNodes[0];
      list = groupedNodes[souceNode.column] ?? [];
      list.push(souceNode);
      groupedNodes[souceNode.column] = list;
    }
  });

  let maxIndex = data.getNodes().length;
  data.forEachColumn((nodes, columnIndex) => {
    const singleNodesForColumn = groupedNodes[columnIndex] ?? [];

    let nextRow = data.maxNodesInColumns() - singleNodesForColumn.length;

    singleNodesForColumn.sort(sortNodeBy('index')).forEach((node) => {
      node.setValue('row', nextRow);
      node.setValue('index', maxIndex);
      nextRow += 1;
      maxIndex += 1;
    });
  });
};

const removeNodeRowDuplicates = (data: GraphData) => {
  data.forEachColumn((nodes) => {
    nodes.sort((n1, n2) => {
      if (!n1.hasSource || !n2.hasSource || n1.row !== n2.row)
        return n1.row < n2.row ? -1 : 1;
      const s1 = data.getSourceLinksWithNodes(n1);
      const s2 = data.getSourceLinksWithNodes(n2);

      return s1.sourceNodes[0].row < s2.sourceNodes[0].row ? -1 : 1;
    });
    nodes.sort(sortNodeBy('row'));
    let nextRow = 0;
    // const isMaxNodes = maxNodesInColumn === nodes.length;

    nodes.forEach((node, index) => {
      // Don't move the nodes up only down
      // If this column has the maximum number of nodes, always assign a new row
      if (nextRow < node.row) {
        nextRow = node.row;
      }
      node.setValue('row', nextRow);
      nextRow += 1;
    });

    const rowsOutOfScreen = data.maxNodesInColumns() - nextRow;

    if (rowsOutOfScreen < 0) {
      nextRow = data.maxNodesInColumns();
      nodes.sort(sortNodeByReverse('row')).forEach((node) => {
        node.setValue('row', node.row + rowsOutOfScreen);
      });
    }
  });
};

const computeNodeRowsBasedOnSource = (data: Readonly<GraphData>) => {
  data.forEachColumn((nodes, columnIndex) => {
    // First row is taken as source of throught
    if (columnIndex === 0) return;

    nodes.forEach((node) => {
      const links = data.getTargetLinks(node);
      const sourceNodes = links
        .map((l) => data.getNode(l.source))
        .sort(sortNodeByColumnRow());

      const sourceNode = sourceNodes[0];

      // Take the first row of the first source node
      if (node.sameSourceTarget) {
        node.setValue('row', sourceNode?.row - 1);
      } else node.setValue('row', sourceNode?.row ?? 0);
    });
  }, 'row');
};

const computeFirstColumnNodeRows = (data: GraphData, nodes: Node[]) => {
  const { result, targeNodes } = groupByTarget(data, nodes);

  let nextRow = 0;
  targeNodes.forEach((r) => {
    const tNodes = result[r._id] ?? [];
    tNodes.forEach((n) => {
      n.row = nextRow;
      nextRow += 1;
    });
  });
};

const detectCrossingLinks = (data: GraphData) => {
  const crossLinkNodes = [];
  const maxRows = data.maxNodesInColumns();
  // TODO improve the crosslinks detection, now it sometimes won't fail
  data.forEachColumn((nodes) => {
    let prevRow = 0;
    let prevNode = null;
    nodes.forEach((node) => {
      if (node.partOfCycle) return;

      const { targetNodes } = data.getTargetLinksWithNodes(node);
      const nextRow = maxBy(targetNodes, (n) => n.row)?.row;

      if (nextRow < prevRow) {
        // move the source nodes with one position

        crossLinkNodes.push({ moveUp: node, moveDown: prevNode });
      }
      prevRow = nextRow;
      prevNode = node;
    });
  });
  let minNodeRow = 0;

  data.forEachNodeAtColumn(0, (nodes) => {
    minNodeRow = minBy(nodes, (n) => n.row)?.row ?? 0;
  });

  crossLinkNodes.forEach((crossLink) => {
    const moveUpNodes = findFirstColumnNodes(data, crossLink.moveUp);

    // Find the nodes to move up, ensure that there are no duplicates with the moveup nodes
    const moveDownNodes = findFirstColumnNodes(data, crossLink.moveDown).filter(
      (n) => !moveUpNodes.some((m) => m._id === n._id),
    );
    const totalMoveNodes = moveUpNodes.length + moveDownNodes.length;

    let newRow = minBy(moveDownNodes, (n) => n.row)?.row ?? 0;

    if (newRow < minNodeRow) {
      newRow = 0;
    } else if (newRow + totalMoveNodes > maxRows) {
      newRow = maxRows - totalMoveNodes;
    }
    moveUpNodes.forEach((n) => {
      n.setValue('row', newRow);

      newRow += 1;
    });
    moveDownNodes.forEach((n) => {
      n.setValue('row', newRow);

      newRow += 1;
    });

    if (newRow > maxRows) {
      console.warn('move up!!', newRow, maxRows);
    }
  });

  return crossLinkNodes.length > 0;
};

const findFirstColumnNodes = (data: GraphData, node: Node) => {
  const sourceNodes: Node[] = [];
  let nodes = [node];

  while (nodes.length) {
    const searchNextNodes = nodes
      .map((n) => {
        const sources = data
          .getSourceLinksWithNodes(n)
          .sourceNodes.filter((s) => !s.sameSourceTarget && !s.partOfCycle);

        if (sources.length) return sources;

        if (n.column === 0)
          // This is a source node so add it oto the array
          sourceNodes.push(n);

        return null;
      })
      .filter((n) => n !== null);

    nodes = searchNextNodes.flat();
  }

  return sourceNodes;
};

export const detectSelfLinkingNodeFirstRow = (data: GraphData) => {
  let moveEverythingOneDown = false;
  data.forEachColumn((nodes) => {
    nodes.forEach((node) => {
      if (!node.sameSourceTarget) return;

      const links = data.getSourceLinks(node);
      const source = data.getNode(links[0].target);
      const bottomPosition = source.row + 1;
      const topPosition = source.row - 1;

      const hasNodeOnTop = nodes.some((n) => n.row === topPosition);
      const hasNodeOnBottom = nodes.some((n) => n.row === bottomPosition);

      if (hasNodeOnTop) node.setValue('row', bottomPosition);
      else if (hasNodeOnBottom) node.setValue('row', topPosition);
      else if (source.row > 0 && node.circularLinkType === 'bottom')
        node.setValue('row', bottomPosition);
      else node.setValue('row', topPosition);

      moveEverythingOneDown = moveEverythingOneDown || node.row < 0;
    });
  }, 'row');

  if (moveEverythingOneDown) {
    moveOneDown(data);
  }
};

const moveOneDown = (data: GraphData) => {
  let max = 0;
  data.forEachNode((node) => {
    node.setValue('row', node.row + 1);
    if (max < node.row) max = node.row;
  });

  data.computeColumns();
};

const getPureSourceNodes = (data: GraphData, node: Node) => {
  const nodes = data.getSourceLinksWithNodes(node);
  return nodes.sourceNodes.filter((n) => !n.sameSourceTarget);
};

const getPureTargetNodes = (data: GraphData, node: Node) => {
  const nodes = data.getTargetLinksWithNodes(node);
  return nodes.targetNodes.filter((n) => !n.sameSourceTarget);
};

const reverseAlignOnOneNode = (data: GraphData) => {
  data.forEachColumnReverse((nodes) => {
    nodes.forEach((node) => {
      // Ignore non source nodes and self linking nodes
      if (!node.hasSource || node.sameSourceTarget) return;
      const sourceNodes = getPureSourceNodes(data, node);

      // If it has more than one source node, just ignore it
      if (sourceNodes.length !== 1) return;
      const source = sourceNodes[0];

      if (node.row === source.row) return;

      // Check how many target the node has
      const targetNodes = getPureTargetNodes(data, source);

      if (source.aggregate) {
        const first = minBy(targetNodes, (n) => n.row);
        source.setValue('row', first.row);
      }
      // Only align if the source has one target
      else {
        if (targetNodes.length !== 1) return;
        source.setValue('row', node.row);
      }
    });
  });
};

const alignOneNode = (data: GraphData) => {
  data.forEachColumn((nodes) => {
    nodes.forEach((node) => {
      // Ignore non source nodes and self linking nodes
      if (!node.hasTarget || node.sameSourceTarget) return;
      if (node.aggregate) return;
      const targetNodes = getPureTargetNodes(data, node);

      // If it has more than one source node, just ignore it
      if (targetNodes.length !== 1) return;

      const target = targetNodes[0];
      if (node.row === target.row) return;

      // Check how many target the node has
      const sourceNodes = getPureSourceNodes(data, target);

      // Only align if the source has one target
      if (sourceNodes.length !== 1) return;

      target.setValue('row', node.row);
    });
  });
};

const alignBetweenTargetAndSource = (data: GraphData) => {
  data.forEachColumn((nodes) => {
    nodes.forEach((node) => {
      // Ignore non source nodes and self linking nodes
      if (!node.hasTarget || node.sameSourceTarget) return;

      const targetNodes = getPureTargetNodes(data, node);
      const sourceNodes = getPureSourceNodes(data, node);

      if (targetNodes.length === 1 && sourceNodes.length === 1) {
        return;
      }

      if (targetNodes.length <= 1 || sourceNodes.length <= 1) return;

      const targetRows = targetNodes
        .map((n) => {
          if (!n.aggregate) return n.row;
          const sources = getPureTargetNodes(data, n);
          return sources.map((s) => s.row);
        })
        .flat();
      const sourceRows = sourceNodes
        .map((n) => {
          if (!n.aggregate) return n.row;
          const sources = getPureSourceNodes(data, n);
          return sources.map((s) => s.row);
        })
        .flat();
      const allRows = [targetRows, sourceRows].flat();

      const minRow = Math.min(...allRows);
      const maxRow = Math.max(...allRows);
      const middle = Math.floor(minRow + (maxRow - minRow) / 2);

      if (node.row === middle) return;

      node.setValue('row', middle);
    });
  });
};

const computeNodeRowsBasedOnFirstColumn = (data: GraphData) => {
  // Every node has now a row assigned. The row will be shifted depending on the source row
  computeNodeRowsBasedOnSource(data);
  // There are still possible duplicates in the row, remove them
  removeNodeRowDuplicates(data);
  reverseAlignOnOneNode(data);

  // There are still possible duplicates in the row, remove them
  removeNodeRowDuplicates(data);
  // alignOneNode(data);

  // alignBetweenTargetAndSource(data);
  // There are still possible duplicates in the row, remove them
  removeNodeRowDuplicates(data);
};

/**
 * This step is important to define the order of the nodes, it will limit overlap
 * @param graph
 */
export const EhubComputeNodeRows = (graph: Readonly<Graph<any, any>>) => {
  const { graph: data } = graph;
  const iterations = graph.settings.iterations;
  // group nodes that only have on link/source for each of the bottom of the graph
  computeLatestNodeBasedOnSingleLinking(data);

  // compute the first column
  data.forEachNodeAtColumn(0, (nodes) =>
    computeFirstColumnNodeRows(data, nodes),
  );

  // compute the following nodes based on the source node
  let hasCrossingLinks = true;
  // Limit the number of iterations to not end up in a deathlock,
  // there is always a posibility that we have crossing links
  for (let n = iterations; n > 0; --n) {
    // Compute the other initial row
    // computeNodeRows(data);
    computeNodeRowsBasedOnFirstColumn(data);

    hasCrossingLinks = detectCrossingLinks(data);
  }

  // If there is a circular linking node on the first row the move everything one down expect the row
  // detectSelfLinkingNodeFirstRow(data);
  // There are still possible duplicates in the row, remove them
  // removeNodeRowDuplicates(data);
  // If there is a circular linking node on the first row the move everything one down expect the row
  detectSelfLinkingNodeFirstRow(data);

  if (hasCrossingLinks) {
    console.log('there are some crossing links after', iterations);
  }
};
