import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  GuardsCheckEnd,
  Router,
} from '@angular/router';

import { BreadcrumbDataModel, BreadcrumbModel } from './breadcrumb.model';
import { BreadcrumbState } from '../breadcrumb.state';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public readonly labels = this.breadcrumbState.labels;

  constructor(
    private readonly router: Router,
    private readonly breadcrumbState: BreadcrumbState,
  ) {
    this.router.events.subscribe((event) => this.checkForBreadcrumbs(event));
  }

  public set(key: string, value: string) {
    this.breadcrumbState.set(key, value);
  }

  private checkForBreadcrumbs(event: any) {
    if (!(event instanceof GuardsCheckEnd)) {
      return;
    }
    const route = (event as GuardsCheckEnd).state.root;
    const breadcrumbs = this.extractBreadCrumb(route, [], '');
    this.breadcrumbState.setBreadcrumbs(breadcrumbs);
  }

  private extractBreadCrumb(
    route: ActivatedRouteSnapshot,
    previousBreadcrumb: BreadcrumbModel[],
    url: string,
  ): BreadcrumbModel[] {
    const path = route.url.map((r) => r.path).join('/');
    const nextUrl = path ? `${url}/${path}` : url;
    const breadcrumb = route.data['breadcrumb'] as BreadcrumbDataModel;
    const breadcrumbs = route.data['breadcrumbs'] as BreadcrumbDataModel[];
    const nextBreadCrumbs = [...previousBreadcrumb];

    if (breadcrumb && !breadcrumb.skip) {
      nextBreadCrumbs.push(
        this.createBreadcrumb(breadcrumb as BreadcrumbDataModel, nextUrl),
      );
    }
    if (breadcrumbs) {
      breadcrumbs?.forEach?.((b) => {
        if (!b.skip) nextBreadCrumbs.push(this.createBreadcrumb(b, nextUrl));
      });
    }

    if (route.firstChild) {
      return this.extractBreadCrumb(route.firstChild, nextBreadCrumbs, nextUrl);
    }
    return nextBreadCrumbs;
  }

  private createBreadcrumb(
    breadcrumb: BreadcrumbDataModel | string,
    route: string,
  ): BreadcrumbModel {
    const path = route.split('/');
    let label = '';
    let subLabel = '';
    let translateLabel = true;
    let translateSubLabel = true;

    if (typeof breadcrumb === 'string') {
      label = breadcrumb;
    } else {
      label = breadcrumb.label;
      subLabel = breadcrumb.subLabel;
      translateLabel = breadcrumb.translateLabel;
      translateSubLabel = breadcrumb.translateSubLabel;
      if (breadcrumb.routeBackPath) {
        path.splice(-breadcrumb.routeBackPath, breadcrumb.routeBackPath);
      }

      if (breadcrumb.route) {
        path.push(...breadcrumb.route);
      }
    }

    return {
      label,
      key: label,
      subLabel,
      route: path.join('/'),
      translateLabel,
      translateSubLabel,
    };
  }
}
