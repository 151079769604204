import { Inject, Injectable, effect } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

import { BreadcrumbModel } from './breadcrumb/breadcrumb.model';
import { BreadcrumbState } from './breadcrumb.state';

@Injectable()
export class BreadcrumbTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private readonly breadcrumbState: BreadcrumbState,
    private readonly translocoService: TranslocoService,
    @Inject('BREADCRUMB_TITLE_STRATEGY_PREFIX') private readonly prefix: string,
  ) {
    super();

    effect(() => {
      this.updateTitleFromLabels(this.breadcrumbState.labels());
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public override updateTitle(routerState: RouterStateSnapshot) {}

  private updateTitleFromLabels(labels: BreadcrumbModel[]) {
    const translatedLabels = labels.map((label: any) => {
      return label.translateLabel
        ? this.translocoService.translate(label.label.label ?? label.label)
        : label.label.label ?? label.label;
    });

    const title = translatedLabels.join(' - ');
    this.title.setTitle(`${this.prefix} - ${title}`);
  }
}
