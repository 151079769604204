import {
  CdkPortalOutlet,
  ComponentPortal,
  PortalModule,
} from '@angular/cdk/portal';
import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ButtonIconComponent } from '@sympheny/ui/button';

import { BannerColor, BannerConfig, BannerService } from '../banner.service';

@Component({
  selector: 'sympheny-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, PortalModule, ButtonIconComponent],
})
export class BannerComponent {
  @Input()
  public show = true;
  @Input()
  public disableDismiss = false;
  @Input()
  public bannerColor: BannerColor = 'default';
  @Output()
  public readonly dismiss = new EventEmitter<void>();

  public text: string | null = null;

  @ViewChild(CdkPortalOutlet, { static: true })
  public portalOutlet!: CdkPortalOutlet;

  private uid = '';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private dismissFn: () => void = () => {};

  constructor(
    private readonly bannerService: BannerService,
    private readonly injector: Injector,
  ) {}

  @Input() public set config(config: BannerConfig<any>) {
    this.show = true;
    this.bannerColor = config.color;
    this.dismissFn = config.dismiss;
    this.uid = config.uid ?? '';

    if ('component' in config) {
      const portalInjector = Injector.create({
        providers: [],
        parent: this.injector,
      });

      this.portalOutlet.attach(
        new ComponentPortal(config.component, null, portalInjector),
      );
    }
    if ('text' in config) {
      this.text = config.text;
    }
  }

  public onDismiss() {
    this.dismiss.emit();
    this.dismissFn();

    this.bannerService.dismissBanner(this.uid);
  }
}
