import z from 'zod';

export const ResultNetOpsSchema = z.object({
  name: z.string(),
  stage: z.string(),
  link: z.string(),
  capacity: z.number(),
  capacityBinary: z.number(),
  linkDirectionBinary: z.number(),
  energyFlow: z.array(z.number()),
  om: z.number(),
  energyCarrier: z.string(),
  point: z.number().nullish(),
});

export type ResultNetOps = z.infer<typeof ResultNetOpsSchema>;
