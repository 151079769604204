<div class="maintenance-text text-left pl-2 text-md">
  <h2 class="text-center text-lg underline">
    <strong>Exciting News! Sympheny Version 3 as default!</strong>
  </h2>
  <p>
    Discover the exciting new features and enhancements in our latest release.
    Please visit our
    <a
      class="maintenance-link"
      target="_blank"
      href="https://support.app.sympheny.com/whats-new/latest/2024-march"
      >'What's New'</a
    >
    page to explore all the updates and see how they can enhance your
    experience.
  </p>
  <p>
    Explore our new optimization engine in V3. For more details, read our
    <a
      class="maintenance-link"
      target="_blank"
      href="https://support.app.sympheny.com/tutorials/latest/how-to-migrate-a-v2-project-to-v3"
      >migration guide</a
    >.
  </p>
  <p>
    Check out our new User Guide for v3
    <a
      class="maintenance-link"
      target="_blank"
      href="https://support.app.sympheny.com/user-guide/latest/v3"
    >
      here!</a
    >
  </p>
</div>
