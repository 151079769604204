import { z } from 'zod';

export const Technology = z.object({
  guid: z.string(),
  name: z.string(),
});

export const TechnologyPackagesDatabaseSchema = z.object({
  guid: z.string().nullish(),
  name: z.string().nullish(),
});

export const TechnologyPackagesDetailDatabaseSchema = z.object({
  guid: z.string().nullish(),
  name: z.string().nullish(),
  conversionTechnologies: z.array(Technology),
  storageTechnologies: z.array(Technology),
});

export type TechnologyPackagesDatabase = z.infer<
  typeof TechnologyPackagesDatabaseSchema
>;

export type TechnologyPackagesDetailDatabase = z.infer<
  typeof TechnologyPackagesDetailDatabaseSchema
>;
