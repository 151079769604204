<div [formGroup]="form" class="grid grid-cols-3 gap-2">
  <sympheny-select-list
    label="DATABASE.database"
    formControlName="database"
    [options]="databases()"
    (ngModelChange)="changeDB($event)"
  >
    <p info>{{ 'DATABASE.select.db.info' | transloco }}</p>
  </sympheny-select-list>

  <sympheny-select-list
    [label]="categoryHeaderLabel"
    formControlName="category"
    [labelKey]="getLabel('category')"
    [valueKey]="getKey('category')"
    [options]="categories()"
    [sortByValue]="true"
    (ngModelChange)="changeCategory($event)"
  >
    <p info>{{ categoryInfoLabel | transloco }}</p>
  </sympheny-select-list>
  <sympheny-select-list
    *ngIf="showType"
    [label]="typeHeaderLabel"
    formControlName="type"
    [labelKey]="getLabel('type')"
    [valueKey]="getKey('type')"
    [options]="types()"
    [sortByValue]="true"
    (ngModelChange)="changeType($event)"
  >
    <p info>{{ typeInfoLabel | transloco }}</p>
  </sympheny-select-list>

  <sympheny-select-list
    *ngIf="!hideTechnology"
    [label]="technologyHeaderLabel"
    formControlName="items"
    [multi]="multi"
    [labelKey]="getLabel('item')"
    [valueKey]="getKey('item')"
    [options]="items()"
    [sortByValue]="true"
    (ngModelChange)="changeItems($event)"
  >
    <p info>{{ technologyInfoLabel | transloco }}</p>
  </sympheny-select-list>
</div>
