import { ElementRef } from '@angular/core';

import {
  computeNodeLinksInitial,
  identifyCircles,
  selectCircularLinkTypes,
} from '../compute';
import { sortLinks } from '../compute/10_sort_links';
import { fillHeight } from '../compute/11_fill_chart';
import {
  computeNodeValues,
  setNodeValue,
} from '../compute/4_compute_node_values';
import { computeNodeDepths } from '../compute/5_compute_node_depths';
import { adjustSankeySize } from '../compute/6_adjust_sankey_size';
import { computeNodeBreadths } from '../compute/7_compute_node_breaths';
import { resolveCollisionsAndRelax } from '../compute/8_resolve-collision';
import { computeLinkBreadths } from '../compute/9_compute_link_breaths';
import { EhubCreateAggregateNodes } from '../compute/ehub/aggregate-node';
import { EhubComputeNodePosition } from '../compute/ehub/compute-node-positions';
import { EhubComputeGraphSize } from '../compute/ehub/compute_graph_size';
import { EhubComputeNodeColumns } from '../compute/ehub/compute_node_columns';
import { EhubComputeNodeRows } from '../compute/ehub/compute_node_rows';
import { computeOrthogonalPaths } from '../compute/ehub/path/draw';
import { EhubResolveLinkCollison } from '../compute/ehub/resolve-collision-link';
import {
  Graph,
  GraphArrow,
  DefaultGraph,
  SankeyData,
  Link,
  Node,
  GraphSettings,
  GraphData,
  GraphExtend,
} from '../model';
import {
  computeLinkPaths,
  recalculateLinkPaths,
} from '../path-data/compute_path';
import { sortSourceLinks } from '../sort/sort-links';
import { sortTargetLinks } from '../sort/sort-target-links';
import { addVirtualPathDatas } from '../virtual-nodes/add-virtual-path-data';
import { createVirtualNodes } from '../virtual-nodes/create_virtual_nodes';
import { straigtenVirtualNodes } from '../virtual-nodes/straigthen-virtual-nodes';
export type Setup<NODE_TYPE extends Node, LINK_TYPE extends Link> = Partial<
  Omit<Graph<NODE_TYPE, LINK_TYPE>, 'graph' | 'settings' | 'tooltip'>
> & { settings: Partial<GraphSettings>; extend?: GraphExtend };

export class GraphSetup<
  NODE_TYPE extends Node = Node,
  LINK_TYPE extends Link = Link,
> implements Graph<NODE_TYPE, LINK_TYPE>
{
  public graph: GraphData<NODE_TYPE, LINK_TYPE>;
  public nodeColor: (d: NODE_TYPE) => string;
  public linkColor: (
    d: LINK_TYPE,
    nodes: { target: NODE_TYPE; source: NODE_TYPE },
  ) => string;
  public nodeText: (d: NODE_TYPE) => string;
  public nodeTooltip: (d: NODE_TYPE) => string;
  public linkTooltip: (
    d: LINK_TYPE,
    source: NODE_TYPE,
    target: NODE_TYPE,
  ) => string;
  public getNodeID: (d: NODE_TYPE) => string;
  public arrow: GraphArrow | null;
  public settings: GraphSettings;
  public useVirtualRoutes: boolean;
  private extend: Readonly<GraphExtend>;
  constructor(
    setup: Setup<NODE_TYPE, LINK_TYPE> | undefined | null,
    public readonly tooltip: ElementRef,
    marginRight = 0,
  ) {
    this.nodeColor = setup?.nodeColor ?? DefaultGraph.nodeColor;
    this.linkColor = setup?.linkColor ?? DefaultGraph.linkColor;
    this.getNodeID = setup?.getNodeID ?? DefaultGraph.getNodeID;
    this.nodeText = setup?.nodeText ?? DefaultGraph.nodeText;
    this.nodeTooltip = setup?.nodeTooltip ?? DefaultGraph.nodeTooltip;
    this.linkTooltip = setup?.linkTooltip ?? DefaultGraph.linkTooltip;
    this.arrow = setup?.arrow ?? DefaultGraph.arrow;
    this.settings = { ...DefaultGraph.settings, ...(setup?.settings ?? {}) };

    this.extend = {
      x0: this.settings.padding,
      y0: this.settings.padding,
      x1: this.settings.width - this.settings.padding - marginRight,
      y1: this.settings.height - this.settings.padding,
      py: 0,
      ky: 0,
    };

    this.useVirtualRoutes =
      setup?.useVirtualRoutes ?? DefaultGraph.useVirtualRoutes;
  }

  public generateSankeyData(data: SankeyData) {
    const nodes = data.nodes.sort((n1, n2) => (n1.name < n2.name ? -1 : 1));
    this.graph = computeNodeLinksInitial(
      { links: data.links, nodes },
      this,
      this.extend,
    ) as unknown as GraphData<NODE_TYPE, LINK_TYPE>;
    identifyCircles(this);
    selectCircularLinkTypes(this);
    computeNodeValues(this.graph);
    computeNodeDepths(this);
    // optional depending on the config
    createVirtualNodes(this);
    adjustSankeySize(this);
    computeNodeBreadths(this);
    resolveCollisionsAndRelax(this);
    computeLinkBreadths(this.graph);
    straigtenVirtualNodes(this);
    sortLinks(this);
    fillHeight(this);
    addVirtualPathDatas(this);

    computeLinkPaths(this);

    return this;
  }

  public generateEhubData(data: SankeyData) {
    // console.log('---generateEhubData');
    const nodes = data.nodes.sort((n1, n2) => (n1.sortBy < n2.sortBy ? -1 : 1));
    const defaultValue = 1;
    this.graph = computeNodeLinksInitial(
      { links: data.links, nodes },
      this,
      this.extend,
    ) as unknown as GraphData<NODE_TYPE, LINK_TYPE>;

    const { nodeDimensions } = this.settings;
    this.graph.forEachNode((node) => {
      const dimensions = nodeDimensions(node);
      node.setNodeDimensions(dimensions, this.settings.minNodePadding);
    });

    identifyCircles(this);
    selectCircularLinkTypes(this);
    setNodeValue(this.graph, defaultValue);
    EhubCreateAggregateNodes(this);
    EhubComputeNodeColumns(this);

    EhubComputeNodeRows(this);
    EhubComputeGraphSize(this);

    EhubComputeNodePosition(this);

    // EhubResolveCollison(this);
    EhubResolveLinkCollison(this);

    computeOrthogonalPaths(this);

    return this;
  }

  public getGraphDimensions() {
    let maxY = 0;
    let maxX = 0;
    this.graph.forEachNode((node) => {
      const newWidth = node.x0 + node.nodeWidth;
      const newHeight = node.y0 + node.nodeHeight;
      if (maxY < newHeight) maxY = newHeight;
      if (maxX < newWidth) maxX = newWidth;
    });

    return {
      width: maxX + this.settings.padding,
      height: maxY + this.settings.padding,
    };
  }

  public dragEhub(node: Node) {
    // computeNodePath(node, this);
    computeOrthogonalPaths(this);
  }

  public dragSankey(node: Node) {
    sortSourceLinks(this);
    sortTargetLinks(this);
    recalculateLinkPaths(this);
  }
}
