import { SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';
export const DatasetFieldSchema = z.object({
  custom_name: z.string(),
  value: z.any(),
  default_name: z.string(),
});

export type DatasetField = z.infer<typeof DatasetFieldSchema>;

export const DatasetAddressSchema = z.object({
  egid: SymphenyNumber(),
  fields: z.array(DatasetFieldSchema),
});
export type DatasetAddress = z.infer<typeof DatasetAddressSchema>;

export const DatasetSchema = z.object({
  dataset_id: z.string(),
  dataset_name: z.string(),
  addresses: z.array(DatasetAddressSchema),
});
export type Dataset = z.infer<typeof DatasetSchema>;

export const DatasetSummarySchema = z.object({
  dataset_id: z.string(),
  dataset_name: z.string(),
  hub_labels: z.array(z.string()),
  number_of_addresses: SymphenyNumber(),
});
export type DatasetSummary = z.infer<typeof DatasetSummarySchema>;
