import { Injectable } from '@angular/core';
import {
  findByGuidZ,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, map, mapTo } from 'rxjs';

import { CrudService } from './crud.service';
import {
  ProjectVersion,
  TechnologyPackages,
  TechnologyPackagesDtoV2,
  TechnologyPackagesListV2,
  TechnologyPackagesSchemaV2,
} from '../model';

export enum TechnologyPackageFrom {
  database,
  custom,
}

@Injectable()
export class TechnologyPackagesServiceV2 extends CrudService<TechnologyPackages> {
  public readonly guidKey = 'guid';
  public readonly key = 'technologyPackages';

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    return this.http
      .get<
        ResponseModel<{ technologyPackages: TechnologyPackages[] }>
      >(`${this.base}v2/scenarios/${scenarioGuid}/technology-packages`)
      .pipe(mapDataZ(TechnologyPackagesListV2, 'technologyPackages'));
  }

  public override get(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
  ): Promise<TechnologyPackages> {
    return firstValueFrom(
      this.http
        .get<
          ResponseModel<TechnologyPackages>
        >(`${this.base}v2/scenarios/${scenarioGuid}/technology-packages/${guid}`)
        .pipe(mapDataZ(TechnologyPackagesSchemaV2)),
    );
  }

  /**
   * Create a TechnologyPackages
   */
  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<TechnologyPackages>,
  ) {
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<{ technologyPackages: TechnologyPackages[] }>
        >(`${this.base}v2/scenarios/${scenarioGuid}/technology-packages`, mapDataRequest(TechnologyPackagesDtoV2, data))
        .pipe(map((d) => d.data.technologyPackages[0])),
    );
  }

  /**
   * Update TechnologyPackages name
   */

  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: Partial<TechnologyPackages>,
  ) {
    return firstValueFrom(
      this.http
        .put<
          ResponseModel<{ technologyPackages: TechnologyPackages[] }>
        >(`${this.base}v2/scenarios/${scenarioGuid}/technology-packages/${guid}`, mapDataRequest(TechnologyPackagesDtoV2, data))
        .pipe(
          findByGuidZ(
            TechnologyPackagesSchemaV2,
            this.guidKey,
            guid,
            'technologyPackages',
          ),
        ),
    );
  }

  /**
   * Delete TechnologyPackages
   */

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    extra: { deleteTechs: boolean },
  ) {
    return firstValueFrom(
      this.http
        .delete(
          `${this.base}scenarios/${scenarioGuid}/technology-packages/${guid}`,
          { params: { deleteTechs: extra.deleteTechs ?? 'false' } },
        )
        .pipe(mapTo(guid)),
    );
  }
}
