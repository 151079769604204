import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Collection } from '@fp-tools/angular-state';
import { ProjectVersion } from '@sympheny/project/data-access';
import {
  LoadDataService,
  mapData,
  mapDataRequest,
  mapDataZ,
  ReloadState,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, map, Observable } from 'rxjs';
import { ZodTypeAny } from 'zod';

import { DB_TYPES, TECHNOLOGY_TYPE } from './const';
import { DatabaseDetailsService } from './database-details.service';

class CategoryCollection extends Collection<string, any> {
  protected idKey: any;

  constructor(
    private readonly http: HttpClient,
    private readonly url: string,
    private readonly categoryMapper: string,
    private readonly fromOrg: boolean,
  ) {
    super();
  }

  protected fetchApi(): Observable<string[]> {
    return this.http
      .get<ResponseModel<any>>(`${this.url}`, {
        params: { fromOrg: this.fromOrg },
      })
      .pipe(map((d) => d.data?.[this.categoryMapper]?.filter((dd) => !!dd)));
  }
}

export interface Settings {
  db: DB_TYPES | 'ewz';
  technology: TECHNOLOGY_TYPE;
  categoryMapper: string;
  technologyMapper: string;
  guid: string;
  responseSchema?: ZodTypeAny;
  requestSchema?: ZodTypeAny;
  ignoreVersionGetUrl?: boolean;
}

const TechMapping: Partial<Record<TECHNOLOGY_TYPE, string>> = {
  'conversion-tech': 'conversion-technologies',
  'storage-tech': 'storage-technologies',
  'network-tech': 'network-technologies',
  imports: 'impex',
  exports: 'impex',
};

const DbMapping: Partial<Record<DB_TYPES | 'ewz', string>> = {
  user: 'user',
  database: 'database',
  'database-org': 'database',
  ewz: 'ewz',
};

export abstract class TechnologyCollection<TECHNOLOGY>
  implements LoadDataService, DatabaseDetailsService<TECHNOLOGY>
{
  private readonly categoryCollection: CategoryCollection;

  public readonly categories$: Observable<any[]>;
  public categories;

  private readonly fromOrg: boolean;
  private readonly base: string;
  private readonly detailUrl: string;
  private readonly detailUrlNoVersion: string;
  private readonly categoryUrl: string;
  private readonly reloadState = inject(ReloadState);

  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly environmentService: EnvironmentService,
    protected readonly settings: Settings,
    protected readonly projectVersion: ProjectVersion,
  ) {
    this.fromOrg = settings.db === 'database-org';

    const prefix = projectVersion === 'V2' ? 'v2/' : '';
    this.base = `${this.environmentService.getValue('base')}${prefix}`;
    const profileTypes = `${
      TechMapping[this.settings.technology]
    }/profile-types/${DbMapping[this.settings.db]}`;

    this.detailUrlNoVersion = `${this.environmentService.getValue('base')}${profileTypes}`;
    const requestUrl = `${this.environmentService.getValue('base')}${settings.ignoreVersionGetUrl ? '' : prefix}${profileTypes}`;

    this.detailUrl = `${this.base}${profileTypes}`;

    this.categoryUrl = `${requestUrl}/technology-categories`;

    this.categoryCollection = new CategoryCollection(
      httpClient,
      this.categoryUrl,
      this.settings.categoryMapper,
      this.fromOrg,
    );
    this.categoryCollection.initialize();
    this.categories$ = this.categoryCollection.data$;

    this.categories$.subscribe((c) => (this.categories = c));
  }

  protected get tech() {
    return TechMapping[this.settings.technology];
  }

  public create(data: Partial<TECHNOLOGY>) {
    const requestSchema = this.settings.requestSchema;

    return firstValueFrom(
      this.httpClient.post<
        ResponseModel<{ conversionTechnologies: TECHNOLOGY[] }>
      >(
        this.getSaveUrl(),
        this.settings.requestSchema
          ? mapDataRequest(requestSchema, data)
          : data,
        { params: { fromOrg: this.fromOrg } },
      ),
    ).then(() => this.categoryCollection.load());
  }
  public load() {
    if (!this.reloadState.shouldLoad(this.settings.db)) return;
  }

  public reload(): void {
    if (!this.reloadState.shouldLoad(this.settings.db)) {
      return;
    }
    this.categoryCollection.load();
  }

  public getTechnologyCategoryDetails(categories: string[]): Observable<any[]> {
    return this.httpClient
      .post<
        ResponseModel<any>
      >(this.categoryUrl, { technologyCategories: categories, fromOrg: this.fromOrg }, { params: { fromOrg: this.fromOrg } })
      .pipe(mapData(this.settings.technologyMapper));
  }

  public getDetails(
    categories: string,
    guid: string,
    exchangeRate: number,
  ): Observable<any> {
    return this.httpClient
      .get<ResponseModel<any>>(`${this.detailUrl}/${guid}`, {
        params: { exchangeRate, fromOrg: this.fromOrg },
      })
      .pipe(
        this.settings.responseSchema
          ? mapDataZ(this.settings.responseSchema)
          : mapData(),
      );
  }

  private getSaveUrl() {
    const tech = TechMapping[this.settings.technology];
    const profileType =
      this.settings.db === 'database-org'
        ? this.reloadState.getOrganization()
        : DbMapping[this.settings.db];
    const base = this.base;

    return `${base}${tech}/profile-types/${profileType}`;
  }

  public update(
    // projectVersion: ProjectVersion,
    guid: string,
    data: Partial<TECHNOLOGY>,
  ) {
    const url = `${this.getSaveUrl()}/${guid}`;
    const requestSchema = this.settings.requestSchema;

    return firstValueFrom(
      this.httpClient.put<
        ResponseModel<{ conversionTechnologies: TECHNOLOGY[] }>
      >(
        `${url}`,
        requestSchema
          ? mapDataRequest(this.settings.requestSchema, data)
          : data,
        { params: { fromOrg: this.fromOrg } },
      ),
    ).then(() => this.reload());
  }

  public delete(guid: string) {
    const deleteUrl = this.detailUrlNoVersion;
    return firstValueFrom(
      this.httpClient
        .delete(`${deleteUrl}/${guid}`, {
          // params: { fromOrg: this.fromOrg },
        })
        .pipe(map(() => guid)),
    );
  }

  public async deleteCategory(category: string): Promise<string> {
    const techs = await firstValueFrom(
      this.getTechnologyCategoryDetails([category]),
    );

    await Promise.all(techs?.map((t) => this.delete(t[this.settings.guid])));

    return category;
  }

  public deleteType(category: string, type: string): Promise<string> {
    throw new Error('Method not implemented.');
  }
}
