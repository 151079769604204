<sympheny-select
  *ngIf="formControl"
  [formControl]="formControl"
  [dataCy]="dataCy ?? 'energy-carrier-select'"
  [label]="label ?? 'ENERGY_CARRIER.energyCarrier'"
  [placeholder]="placeholder ?? 'SCENARIO.energyCarrier.label'"
  labelKey="energyCarrierName"
  valueKey="energyCarrierGuid"
  [config]="{ requiredHint: required }"
  [errorMessages]="errorMessages"
  [hint]="hint"
  [options]="energyCarriers()"
  (selectionChange)="selectionChange.emit($event)"
  [multiple]="multiple"
  [requiredMessage]="requiredMessage"
  [disableSelectAll]="disableSelectAll"
  [maxValues]="maxValues"
  [disabledOptions]="disabledIds"
  [translateOptions]="false"
>
</sympheny-select>
