import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from '@angular/cdk/overlay';
import {
  CommonModule,
  Location,
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import enCH from '@angular/common/locales/en-CH';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  AuthHttpInterceptor,
  AuthModule as Auth0Module,
  authHttpInterceptorFn,
} from '@auth0/auth0-angular';
import { TranslocoModule } from '@ngneat/transloco';
import { provideDatabaseDataAccess } from '@sympheny/database/data-access/init';
import { provideDatabaseModel } from '@sympheny/database/model';
import { provideGisBackground } from '@sympheny/gis/background';
import { provideGisDataset } from '@sympheny/gis/datasets';
import { initTokens } from '@sympheny/gis/utils';
import { HeaderComponent } from '@sympheny/navigation/header';
import {
  SidenavComponent,
  provideNavigation,
} from '@sympheny/navigation/navigation';
import { provideProjectAnalysisExecution } from '@sympheny/project/analysis/execution-data-access';
import { provideProjectDataAccess } from '@sympheny/project/data-access';
import { provideProjectScenarioDataAccess } from '@sympheny/project/scenario/data-access';
import { provideScenarioHubsProviders } from '@sympheny/project/scenario/hubs';
import { BannerListComponent } from '@sympheny/ui/banner';
import { provideBreadCrumbs } from '@sympheny/ui/breadcrumb';
import { provideSnackBar } from '@sympheny/ui/snackbar';
import { UserBannerModule } from '@sympheny/user/banner';
import { provideUserState } from '@sympheny/user/data-access';
import { providePlan } from '@sympheny/user/plan';
import { provideHttpClientWithInterceptors } from '@sympheny/utils/data-access';
import { provideEnvironment } from '@sympheny/utils/environment';
import { FeatureFlagModule } from '@sympheny/utils/feature-flag';
import {
  TranslocoLazyModule,
  TranslocoRootModule,
} from '@sympheny/utils/translate';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectsService } from './project-details/projects.service';
import { environment } from '../environments/environment';

initTokens({
  mapboxToken: environment.mapboxToken,
  mapTillerToken: environment.mapTillerToken,
});

const localeId = 'en-CH';
registerLocaleData(enCH, localeId);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    TranslocoModule,
    Auth0Module.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        client: environment.auth0.clientId,
        audience: environment.auth0.audience,
        redirect_uri: `${window.location.origin}/auth-callback`,
        errorPath: '/auth-callback?error=true',
        allowedList: environment.auth0.allowedList.map((uri) => ({
          uri: `${uri}*`,
          tokenOptions: {
            authorizationParams: { audience: environment.auth0.audience },
          },
        })),
      },
      httpInterceptor: {
        allowedList: environment.auth0.allowedList.map((uri) => ({
          uri: `${uri}*`,
          tokenOptions: {
            authorizationParams: { audience: environment.auth0.audience },
          },
        })),
      },
    }),
    FeatureFlagModule.forRoot(),
    UserBannerModule.forRoot(),
    MatSelectModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    TranslocoRootModule.forRoot({
      languages: environment.supportedLanguages,
      prodMode: environment.production,
    }),
    TranslocoLazyModule.forChild(),
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.enableSw,
    }),

    HeaderComponent,
    SidenavComponent,
    BannerListComponent,
  ],
  providers: [
    AuthHttpInterceptor,
    provideDatabaseDataAccess(),
    provideHttpClientWithInterceptors(authHttpInterceptorFn),
    provideProjectScenarioDataAccess(),
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LOCALE_ID, useValue: localeId },

    // {
    // provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
    // useValue: MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    // },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },

    ProjectsService,
    provideEnvironmentNgxMask(),
    provideEnvironment({
      ...environment,
      api: environment.publicBaseApi,
      base: environment.backendApi,
    }),
    providePlan(),
    provideSnackBar(),
    provideGisDataset(),
    provideBreadCrumbs('Sympheny'),
    provideNavigation(),
    provideProjectDataAccess(),
    provideProjectAnalysisExecution(),
    provideScenarioHubsProviders(),
    provideUserState(),
    provideGisBackground(),
    provideDatabaseModel(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
