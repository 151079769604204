import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TechnologyCollection } from '@sympheny/database/model';
import {
  ConversionTechnology,
  ConversionTechnologyDatabaseSchemaV2,
  ConversionTechnologyDtoV2,
} from '@sympheny/project/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';

@Injectable()
export class EwzConversionTechnologyCollectionV2 extends TechnologyCollection<ConversionTechnology> {
  private ewz = false;
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(
      http,
      environmentService,
      {
        db: 'ewz',
        technology: 'conversion-tech',
        categoryMapper: 'technologyCategories',
        technologyMapper: 'databaseConversionTechnologies',
        guid: 'conversionTechGuid',
        responseSchema: ConversionTechnologyDatabaseSchemaV2,
        requestSchema: ConversionTechnologyDtoV2,
      },
      'V2',
    );
  }
}
