import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
import { SelectComponent } from '../select/select.component';

export interface Month {
  label: string;
  value: string;
  dateString: string;
}

export const months: Readonly<Month[]> = [
  { label: 'date.month.january', value: 'JANUARY', dateString: 'Jan' },
  { label: 'date.month.february', value: 'FEBRUARY', dateString: 'Feb' },
  { label: 'date.month.march', value: 'MARCH', dateString: 'Mar' },
  { label: 'date.month.april', value: 'APRIL', dateString: 'Apr' },
  { label: 'date.month.may', value: 'MAY', dateString: 'May' },
  { label: 'date.month.june', value: 'JUNE', dateString: 'Jun' },
  { label: 'date.month.july', value: 'JULY', dateString: 'Jul' },
  { label: 'date.month.august', value: 'AUGUST', dateString: 'Aug' },
  { label: 'date.month.september', value: 'SEPTEMBER', dateString: 'Sep' },
  { label: 'date.month.october', value: 'OCTOBER', dateString: 'Oct' },
  { label: 'date.month.november', value: 'NOVEMBER', dateString: 'Nov' },
  { label: 'date.month.december', value: 'DECEMBER', dateString: 'Dec' },
] as const;

@Component({
  selector: 'sympheny-select-month',
  templateUrl: './select-month.component.html',
  styleUrls: ['./select-month.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, SelectComponent],
})
export class SelectMonthComponent extends BaseFormComponent<
  Month,
  FormFieldConfig
> {
  @Output() public readonly selectionChange = new EventEmitter<
    Month[] | Month | null
  >();

  public readonly months = months;
  @Input() public multiple = false;
}
