/* eslint-disable @nx/enforce-module-boundaries */
import { FormatValueConfig } from '@sympheny/format-value';
import { EnergyCarrier } from '@sympheny/project/data-access';

type KEY = keyof EnergyCarrier;

export const energyCarrierFieldConfig: Record<
  KEY,
  FormatValueConfig<EnergyCarrier>
> = {
  typeKey: {
    label: 'ENERGY_CARRIER.carrierType.label',
    key: 'typeKey',
  },
  subtypeKey: {
    label: 'ENERGY_CARRIER.carrierSubtype.label',
    key: 'subType',
  },
  energyCarrierName: {
    label: 'ENERGY_CARRIER.energyCarrierName.label',
    key: 'energyCarrierName',
  },
  colorHexCode: {
    label: 'ENERGY_CARRIER.color.label',
    key: 'colorHexCode',
    type: 'color',
  },
  created: undefined,
  customInputEfficiencyActivated: undefined,
  customOutputEfficiencyActivated: undefined,
  customSeasonalityValues: undefined,
  energyCarrierGuid: undefined,
  fixedInputShare: undefined,
  outputEfficiency: undefined,
  outputEfficiencyProfileId: undefined,
  primary: undefined,
  subtypeDisplayName: undefined,
  typeDisplayName: {
    label: 'ENERGY_CARRIER.carrierType.label',
    key: 'typeDisplayName',
  },
};

export const energyCarrierSummaryFields: KEY[] = [
  'energyCarrierName',
  'typeDisplayName',
  'colorHexCode',
];
