import { provideDatabaseDataAccessEnergyDemands } from '@sympheny/database/data-access/energy-demands';
import { provideDatabaseDataAccessImpex } from '@sympheny/database/data-access/impex';
import { provideDatabaseDataAccessOnSiteResources } from '@sympheny/database/data-access/on-site-resources';
import { provideProjectSupplyTechnologyDataAccess } from '@sympheny/database/data-access/supply-technology';
import { provideProjectEnergyCarrierDataAccess } from '@sympheny/project/energy-carrier/data-access';

/**
 * Should only be imported in the application root module
 */

export const provideDatabaseDataAccess = () => {
  return [
    provideDatabaseDataAccessEnergyDemands(),
    provideProjectEnergyCarrierDataAccess(),
    provideDatabaseDataAccessOnSiteResources(),
    provideProjectSupplyTechnologyDataAccess(),
    provideDatabaseDataAccessImpex(),
  ];
};
