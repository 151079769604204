import { ObjectiveV2Enum } from '@sympheny/project/data-access';
import { getEnergyCarrierColor } from '@sympheny/project/energy-carrier/utils';
import { SymphenyNumber } from '@sympheny/utils/data-access';
import z from 'zod';
export const ResultsEnergyCarrierSchema = z.object({
  name: z.string(),
  type: z.string(),
  subtype: z.string(),
  color: z.string().nullish(),
});
export const ResultsGeneralSchema = z
  .object({
    objective1: ObjectiveV2Enum,
    objective2: ObjectiveV2Enum,
    currency: z.string(),
    points: SymphenyNumber(),
    stages: z.array(z.string()),
    hubs: z.array(z.string()),
    timeSteps: z.number(),
    months: z.number(),
    energyCarriers: z.array(ResultsEnergyCarrierSchema),
  })
  .transform((result) => {
    return {
      ...result,
      solutions: Array.from({ length: result.points }).map(
        (_, p) => p + 1,
      ) as number[],
      energyCarriers: result.energyCarriers.map((ec) => {
        return {
          ...ec,
          color: getEnergyCarrierColor({
            typeKey: ec.type,
            subtypeKey: ec.subtype,
            colorHexCode: ec.color,
          }),
        } as ResultsEnergyCarrier;
      }),
    };
  });

export type ResultsEnergyCarrier = z.infer<typeof ResultsEnergyCarrierSchema>;
export type ResultsGeneral = z.infer<typeof ResultsGeneralSchema>;
