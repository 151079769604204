import { SymphenyNumber } from '@sympheny/utils/data-access';
import z from 'zod';

export const ResultsOnSiteResourcesSchema = z.object({
  name: z.string(),
  stage: z.string(),
  hub: z.string(),
  resource: z.number(),
  energy: z.array(z.number()),
  energyCarrier: z.string(),
  monthlyEnergy: z.array(z.number()),
  point: SymphenyNumber().nullish(),
});

export type ResultsOnSiteResources = z.infer<
  typeof ResultsOnSiteResourcesSchema
>;
