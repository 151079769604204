<div [attr.data-cy]="dataCy || label" [attr.data-testid]="dataCy || label">
  <div class="flex items-center">
    <div *ngIf="displayLabel" class="mr-2 text-gray-500">
      {{ label | transloco }}
    </div>

    <button
      type="button"
      (click)="toggleValue(valueFalse)"
      data-cy="toggle-button--false"
      class="rounded-l border py-1 px-2 min-w-max"
      [class.bg-primary]="formControl.value === valueFalse"
      [class.text-white]="formControl.value === valueFalse"
      [class.text-gray-500]="formControl.value !== valueFalse"
    >
      {{ labelFalse | transloco }}
    </button>
    <button
      type="button"
      data-cy="toggle-button--true"
      (click)="toggleValue(valueTrue)"
      class="rounded-r border py-1 px-2 min-w-max"
      [class.bg-primary]="formControl.value === valueTrue"
      [class.text-white]="formControl.value === valueTrue"
      [class.text-gray-500]="formControl.value !== valueTrue"
    >
      {{ labelTrue | transloco }}
    </button>
  </div>
  <mat-error *ngIf="invalid">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</div>
