import { Injectable } from '@angular/core';
import {
  findByGuidZ,
  findLatestDataZ,
  mapDataRequest,
  mapDataZ,
  mapResponse,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, map, Observable, switchMap, throwError } from 'rxjs';

import {
  OnSiteSolarResourceV2,
  OnSiteSolarResourceV2Dto,
  OnSiteSolarResourceV2DtoSchema,
  OnSiteSolarResourceV2List,
  OnSiteSolarResourceV2ListSchema,
  OnSiteSolarResourceV2Schema,
} from './on-site-solar-resource.model.v2';
import { ProjectVersion } from '../model';
import { CrudService } from '../service';

@Injectable()
export class SolarResourcesV2Service extends CrudService<
  OnSiteSolarResourceV2List,
  OnSiteSolarResourceV2,
  OnSiteSolarResourceV2Dto
> {
  public readonly guidKey = 'solarResourceGuid';
  public readonly key = 'onSiteSolarResources';

  private getBaseUrl(scenarioGuid: string) {
    return `${this.base}v2/scenarios/${scenarioGuid}/solar-on-site-resource`;
  }
  public override list(
    _: ProjectVersion,
    scenarioGuid: string,
  ): Observable<OnSiteSolarResourceV2List[]> {
    return this.http
      .get<
        ResponseModel<OnSiteSolarResourceV2List[]>
      >(this.getBaseUrl(scenarioGuid))
      .pipe(mapDataZ(OnSiteSolarResourceV2Schema));
  }

  public override async create(
    _: ProjectVersion,
    scenarioGuid: string,
    data: OnSiteSolarResourceV2Dto,
  ): Promise<OnSiteSolarResourceV2> {
    let url = '';

    let responseMapper: any = findLatestDataZ(
      OnSiteSolarResourceV2ListSchema,
      'created',
      'solarResources',
    );

    switch (data.profile.toUpperCase()) {
      case 'SAVED':
        url = `scenarios/${scenarioGuid}/user-solar-resources`;
        break;
      case 'GENERATED':
        url = `scenarios/${scenarioGuid}/database-solar-resources`;
        break;
      case 'UPLOADED':
        await this.uploadProfileAndUpdateId(
          scenarioGuid,
          data,
          'solarResourceProfileFile',
          'profileId',
        );
        url = `v2/scenarios/${scenarioGuid}/solar-on-site-resource`;
        responseMapper = mapResponse(OnSiteSolarResourceV2DtoSchema);
        break;
      case 'UPLOADED_GIS':
        await this.uploadProfileAndUpdateId(
          scenarioGuid,
          data,
          'fileRequestDto',
          'profileId',
        );
        url = `v2/scenarios/${scenarioGuid}/solar-on-site-resource`;
        responseMapper = mapResponse(OnSiteSolarResourceV2DtoSchema);
        break;
      default:
        throwError('wrong type');
    }
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<{ solarResources: OnSiteSolarResourceV2[] }>
        >(`${this.base}${url}`, mapDataRequest(OnSiteSolarResourceV2DtoSchema, data))
        .pipe(
          switchMap(() =>
            this.http.get<
              ResponseModel<{ solarResources: OnSiteSolarResourceV2 }>
            >(this.getBaseUrl(scenarioGuid)),
          ),
          findLatestDataZ(OnSiteSolarResourceV2ListSchema, 'created'),
        ),
    );
  }

  public override async update(
    _: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: OnSiteSolarResourceV2Dto,
  ): Promise<OnSiteSolarResourceV2> {
    let url = '';
    let responseMapper: any = findByGuidZ(
      OnSiteSolarResourceV2Schema,
      this.guidKey,
      guid,
      'solarResources',
    );

    switch (data.profile.toUpperCase()) {
      case 'SAVED':
        url = `scenarios/user-solar-resources/${guid}`;
        break;
      case 'GENERATED':
        url = `scenarios/database-solar-resources/${guid}`;
        break;
      case 'UPLOADED':
        await this.uploadProfileAndUpdateId(
          scenarioGuid,
          data,
          'solarResourceProfileFile',
          'profileId',
        );

        url = `v2/scenarios/solar-on-site-resource/${guid}`;
        responseMapper = findByGuidZ(
          OnSiteSolarResourceV2Schema,
          this.guidKey,
          guid,
        );
        break;
      default:
        throwError('wrong type');
    }

    return firstValueFrom(
      this.http
        .put<
          ResponseModel<{ solarResources: OnSiteSolarResourceV2[] }>
        >(`${this.base}${url}`, mapDataRequest(OnSiteSolarResourceV2DtoSchema, data))
        .pipe(
          switchMap(() =>
            this.http.get<
              ResponseModel<{ solarResources: OnSiteSolarResourceV2 }>
            >(this.getBaseUrl(scenarioGuid)),
          ),
          findByGuidZ(OnSiteSolarResourceV2Schema, this.guidKey, guid),
        ),
    );
  }

  public override delete(
    _: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    type: string,
  ): Promise<any> {
    return firstValueFrom(
      this.http
        .delete<
          ResponseModel<OnSiteSolarResourceV2>
        >(`${this.base}scenarios/solar-on-site-resource/${guid}`)
        .pipe(map(() => guid)),
    );
  }
}
