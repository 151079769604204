import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';

import { AbstractTechnologyPackagesCollection } from './abstract-technology-packages.collection';

const url = 'database-technology-packages?fromOrg=false';
const urlCategory = `${url}/technology-categories`;

@Injectable()
export class DatabaseTechnologyPackageCollectionService extends AbstractTechnologyPackagesCollection {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService, url, urlCategory, 'database');
  }
}
