import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';
import { from, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { LocalCacheService } from './local-cache.service';
import { SymphenyErrorHandler } from '../error.handler';

export const eTagInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  if (req.url.indexOf('assets') > 0) return next(req);
  const localCache = inject(LocalCacheService);
  const errorHandler = inject(SymphenyErrorHandler);
  const environmentService = inject(EnvironmentService);

  let cache = null;

  return from(localCache.getDataFromRequest(req)).pipe(
    switchMap((c) => {
      cache = c;
      let headers = {};
      if (cache) {
        headers = {
          'If-None-Match': cache.etag,
        };
      }
      return next(req.clone({ setHeaders: headers }));
    }),
    tap((response) => {
      if (!(response instanceof HttpResponse)) return response;
      const etag = response.headers.get('Etag');
      if (!etag) return response;

      localCache.setDataFromRequest(req, response);
      return response;
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 304 && cache) {
        return of(new HttpResponse(cache.response));
      }
      if (environmentService.isSymphenyUrl(error.url)) {
        errorHandler.handleEspError(error);
      }

      return throwError(() => error);
    }),
  );
};
