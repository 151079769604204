import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { DatabaseDetailsService, DB_TYPES } from '@sympheny/database/model';
import { TechnologyPackages } from '@sympheny/project/data-access';
import {
  LoadDataService,
  mapData,
  ReloadState,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { map, Observable, tap, BehaviorSubject, firstValueFrom } from 'rxjs';

import { AbstractTechnologyPackageCategoriesCollection } from './abstract-technology-package-categories.collection';
import { AbstractTechnologyPackageCollection } from './abstract-technology-package.collection';

@Injectable()
export abstract class AbstractTechnologyPackagesCollection
  implements LoadDataService, DatabaseDetailsService<TechnologyPackages>
{
  private loadedCategoryDetails$ = new BehaviorSubject<any>([]);

  protected readonly technologyPackageCollection: AbstractTechnologyPackageCollection;
  protected readonly technologyPackageCategoriesCollection: AbstractTechnologyPackageCategoriesCollection;

  public readonly categories$: Observable<string[]>;
  public readonly technologyCategories$: Observable<TechnologyPackages[]>;

  private readonly reloadState = inject(ReloadState);
  protected readonly base = this.environmentService.getValue('base');

  constructor(
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService,
    @Inject('baseurl') protected readonly baseUrl: string,
    @Inject('baseUrlCategory') protected readonly baseUrlCategory: string,
    protected readonly db: DB_TYPES | 'ewz',
  ) {
    this.technologyPackageCollection = new AbstractTechnologyPackageCollection(
      http,
      environmentService,
      this.baseUrl,
    );
    this.technologyPackageCategoriesCollection =
      new AbstractTechnologyPackageCategoriesCollection(
        http,
        environmentService,
        this.baseUrl,
        db,
      );

    this.categories$ = this.technologyPackageCategoriesCollection.data$;
    this.technologyCategories$ =
      this.technologyPackageCategoriesCollection.data$;
  }
  public update(gui: string, data: Partial<TechnologyPackages>): Promise<any> {
    throw new Error('Method not implemented.');
  }
  public load() {
    if (!this.reloadState.shouldLoad(this.db)) return;

    this.technologyPackageCategoriesCollection.load();
    this.loadedCategoryDetails$.next([]);
  }
  public reload() {
    if (!this.reloadState.shouldLoad(this.db)) return;

    this.technologyPackageCategoriesCollection.load();
    this.loadedCategoryDetails$.next([]);
  }

  /**
   * Get details about user conversion technology
   */
  public _getTechnologyDetails(guid: string) {
    return this.http
      .get<
        ResponseModel<{
          conversionTechnologies: any[];
          storageTechnologies: any[];
        }>
      >(`${this.base}technology-packages/${guid}`)
      .pipe(mapData());
  }

  public getTechnologyCategoryDetails(guid: string[]) {
    return this.http
      .get<
        ResponseModel<{
          conversionTechnologies: any[];
          storageTechnologies: any[];
        }>
      >(`${this.base}technology-packages/${guid[0]}`)
      .pipe(
        mapData(),
        map(({ conversionTechnologies, storageTechnologies }) => {
          return [
            conversionTechnologies?.map((tech) => ({
              ...tech,
              guid: tech.conversionTechnologyGuid,
              name: tech.processName,
            })),
            storageTechnologies?.map((tech) => ({
              ...tech,
              guid: tech.storageTechnologyGuid,
              name: tech.storageName,
            })),
          ].flat() as any[];
        }),
        tap((techs) => this.loadedCategoryDetails$.next(techs)),
      );
  }

  public getDetails(
    categories: string,
    guid: string,
    exchangeRate: number,
  ): Observable<any> {
    return this.loadedCategoryDetails$.pipe(
      map((techs) => techs.filter((t) => t.guid === guid)),
    );
  }

  public delete(guid: string): Promise<string> {
    return firstValueFrom(
      this.http
        .delete(`${this.base}technology-packages-no-scenario/${guid}`)
        .pipe(map(() => guid)),
    );
  }

  public async deleteCategory(category: string) {
    await this.delete(category);

    return category;
  }

  public create(data: Partial<TechnologyPackages>) {
    return this.technologyPackageCollection.create(data);
  }

  public deleteType(category: string, type: string): Promise<string> {
    throw new Error('Method not implemented.');
  }
}
