import { Injectable } from '@angular/core';
import {
  GisChangeEvent,
  gisStyles,
  MapFeatureService,
} from '@sympheny/gis/utils';
import { never as NeverCondition } from 'ol/events/condition';
import Polygon from 'ol/geom/Polygon';
import { Draw, Modify, Snap } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';

import { MapHubLayer } from './map-hub-layer';

export interface DrawHubChangeEvent extends GisChangeEvent {
  mapChanged: boolean;
}

@Injectable()
export class DrawHubService extends MapFeatureService<
  DrawHubChangeEvent,
  Draw
> {
  protected override tempLayerOptions = {
    style: [gisStyles.editFeatures, gisStyles.editVertices],
    minZoom: 12,
    zIndex: 2,
  };

  protected addInteraction() {
    if (!this.map || !this.tempSource) {
      return null;
    }

    const source = this.tempSource!;
    const layer = this.tempLayer!;

    this.map.addLayer(layer);

    // Create Draw, Snap and Modify interactions
    const draw = new Draw({
      source,
      type: 'Polygon',
      minPoints: 3,
    });
    const snap = new Snap({ source });
    const modify = new Modify({
      source,
      insertVertexCondition: NeverCondition,
    });

    // Add interactions to map
    this.map.addInteraction(draw);
    this.map.addInteraction(snap);
    this.map.addInteraction(modify);

    // Add event listeners
    draw.on('drawstart', () => {
      source.clear();

      //this.hubUpdated.emit(true);
    });

    modify.on('modifyend', (event: ModifyEvent) => {
      const feature = event.features.item(0) as any;
      this.updateEvent({
        geoJson: this.getGeoJson([feature]),
        mapChanged: true,
      });
    });

    draw.on('drawend', (event) => {
      this.updateEvent({
        geoJson: this.getGeoJson([event.feature]),
        mapChanged: true,
      });
    });

    return draw;
  }

  protected defaultValue(): DrawHubChangeEvent {
    return { geoJson: null, mapChanged: false };
  }

  public setHubLayer(mapHubLayer: MapHubLayer | null) {
    if (!mapHubLayer || !this.tempSource) {
      return;
    }
    const layer =
      mapHubLayer.baseLayer.layer ?? mapHubLayer.baseLayer.createLayer();
    const source = this.tempSource;
    layer.getSource().forEachFeature((f: any) => {
      // Filter only base polygon (Building point features are added)
      if (f.getGeometry() instanceof Polygon) {
        source.addFeature(f.clone());
      }
    });

    this.updateEvent({
      geoJson: mapHubLayer.hubGis.base_layer,
    });
  }
}
