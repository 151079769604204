import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

export type TYPE_OF_CHARGING = 'Smart' | 'Dump' | 'V2G' | 'None';

import { TechnologyAdvancedPriceComponentSchemaV1 } from './price-component.model';
import {
  HubEnergyCarrierSchema,
  TechnologyModeEnergyCarrierSchema,
} from './technology.model';

export const StorageCarrierSchema = z.object({
  energyCarrierGuid: z.string().nullish(),
  typeKey: z.string().nullish(),
  typeDisplayName: z.string().nullish(),
  subtypeKey: z.string().nullish(),
  subtypeDisplayName: z.string().nullish(),
  energyCarrierName: z.string().nullish(),
  colorHexCode: z.string().nullish(),
  fixedInputShare: z.string().nullish(),
  outputEfficiency: z.string().nullish(),
  customOutputEfficiencyActivated: z.boolean().nullish(),
  customInputEfficiencyActivated: z.boolean().nullish(),
  customSeasonalityValues: SymphenyNumber().nullish(),
  outputEfficiencyProfileId: SymphenyNumber().nullish(),
  created: SymphenyDate().nullish(),
  primary: z.boolean().nullish(),
});

export const StorageTechnologySchema = z.object({
  storageTechnologyGuid: z.string().nullish(),
  storageName: z.string().nullish(),
  capacity: SymphenyNumber().nullish(),
  fixedInvestmentCost: SymphenyNumber().nullish(),
  variableInvestmentCost: SymphenyNumber().nullish(),
  variableOmCost: SymphenyNumber().nullish(),
  fixedOmCostChf: SymphenyNumber().nullish(),
  fixedOmCostPercent: SymphenyNumber().nullish(),
  lifetime: SymphenyNumber().nullish(),
  maximumChargingRate: SymphenyNumber().nullish(),
  maximumDischargingRate: SymphenyNumber().nullish(),
  standbyLoss: SymphenyNumber().nullish(),
  minimumSoc: SymphenyNumber().nullish(),
  maximumCapacity: SymphenyNumber().nullish(),
  minimumCapacity: SymphenyNumber().nullish(),
  variableEmbodiedCo2: SymphenyNumber().nullish(),
  fixedEmbodiedCo2: SymphenyNumber().nullish(),
  storageChargingEfficiency: SymphenyNumber().nullish(),
  storageDischargingEfficiency: SymphenyNumber().nullish(),
  technologyCapacity: z.string().nullish(),
  mustBeInstalled: z.string().nullish(),
  created: z.string().nullish(),
  updated: z.string().nullish(),
  storageCarrier: TechnologyModeEnergyCarrierSchema.nullish(),
  energyCarrierGuid: z.string().nullish(),
  hubs: z.array(HubEnergyCarrierSchema).nullish(),
  category: z.string().nullish(),
  technologyCategory: z.string().nullish(),
  notes: z.string().nullish(),
  source: z.string().nullish(),
  technologyOptional: z.boolean().nullish(),
  costComponents: z.array(TechnologyAdvancedPriceComponentSchemaV1).nullish(),
  comesFromDb: z.string().nullish(),
  evPlugInTime: z.string().nullish(),
  evPlugOutTime: z.string().nullish(),
  evPlugInDurationHours: SymphenyNumber().nullish(),
  drivingDistanceKms: SymphenyNumber().nullish(),
  evSocStartPercent: SymphenyNumber().nullish(),
  evBatterySizeKWh: SymphenyNumber().nullish(),
  maximumSocPercent: SymphenyNumber().nullish(),
  evAverageKWhPerKm: SymphenyNumber().nullish(),
  evPlugInPowerKW: SymphenyNumber().nullish(),
  isEvBattery: z.boolean().nullable().default(false),
  typeOfCharging: z.string().nullish(),
  stages: z.array(z.string()).nullish(),
  hubGuids: z.array(z.string()).nullish(),

  // v2 fields

  fixedSalvageValue: SymphenyNumber().nullish(),
  variableSalvageValueCHF: SymphenyNumber().nullish(),
  variableSalvageValuePercent: SymphenyNumber().nullish(),
  fixedReplacementCost: SymphenyNumber().nullish(),
  variableReplacementCostCHF: SymphenyNumber().nullish(),
  variableReplacementCostPercent: SymphenyNumber().nullish(),
  standByLossProfileId: z.number().nullish(),
  variableOmCostPercent: SymphenyNumber().nullish(),
  variableOmEnergyFlowCost: SymphenyNumber().nullish(),
});

// TODO do the right mapping
export const StorageTechnologySchemaV1 = StorageTechnologySchema;
// TODO do the right mapping
export const StorageTechnologySchemaV2 = StorageTechnologySchema;

export const StorageTechnologyWithProfileFilesSchemaV2 =
  StorageTechnologySchemaV2.extend({
    standByLossProfileFile: z
      .object({
        encodedFile: z.string(),
        fileName: z.string(),
      })
      .nullish(),

    standByLossDynamic: z.boolean(),
  });

export type StorageTechnologyWithProfileFiles = z.infer<
  typeof StorageTechnologyWithProfileFilesSchemaV2
>;

// TODO do the right mapping
export const StorageTechnologyDtoV1 = StorageTechnologySchema;
// TODO do the right mapping
export const StorageTechnologyDtoV2 = StorageTechnologySchema;

export type StorageTechnology = z.infer<typeof StorageTechnologySchema>;
export const StorageTechnologyListSchemaV1 = StorageTechnologySchema.pick({
  storageTechnologyGuid: true,
  storageName: true,
  lifetime: true,
  created: true,
  updated: true,
  hubs: true,
}).extend({
  energyCarrier: TechnologyModeEnergyCarrierSchema,
});

export const StorageTechnologyListSchemaV2 = StorageTechnologySchema.pick({
  storageTechnologyGuid: true,
  storageName: true,
  lifetime: true,
  created: true,
  updated: true,
  hubs: true,
  stages: true,
}).extend({
  energyCarrier: TechnologyModeEnergyCarrierSchema,
});

export type StorageTechnologyList = z.infer<
  typeof StorageTechnologyListSchemaV1 & typeof StorageTechnologyListSchemaV2
>;

export const StorageTechnologyDatabaseSchema = StorageTechnologySchema.pick({
  storageTechnologyGuid: true,
  category: true,
  technologyCategory: true,
  notes: true,
  source: true,
  capacity: true,
  variableInvestmentCost: true,
  variableOmCost: true,
  fixedOmCostChf: true,
  fixedOmCostPercent: true,
  fixedInvestmentCost: true,
  lifetime: true,
  maximumChargingRate: true,
  maximumDischargingRate: true,
  standbyLoss: true,
  minimumSoc: true,
  maximumCapacity: true,
  minimumCapacity: true,
  variableEmbodiedCo2: true,
  fixedEmbodiedCo2: true,
  storageChargingEfficiency: true,
  storageDischargingEfficiency: true,
  storageCarrier: true,
  energyCarrierGuid: true,
  created: true,
  updated: true,
  costComponents: true,
  mustBeInstalled: true,
  technologyCapacity: true,
  evPlugInTime: true,
  evPlugOutTime: true,
  evPlugInDurationHours: true,
  drivingDistanceKms: true,
  evSocStartPercent: true,
  evBatterySizeKWh: true,
  maximumSocPercent: true,
  evAverageKWhPerKm: true,
  evPlugInPowerKW: true,
  isEvBattery: true,
  typeOfCharging: true,
  fixedSalvageValue: true,
  variableSalvageValueCHF: true,
  variableSalvageValuePercent: true,
  fixedReplacementCost: true,
  variableReplacementCostCHF: true,
  variableReplacementCostPercent: true,
}).extend({ storageTechnologyName: z.string().nullish() });

export type StorageTechnologyDatabase = z.infer<
  typeof StorageTechnologyDatabaseSchema
>;

export const StorageTechnologyDatabaseSchemaToTechnology = (
  tech: StorageTechnologyDatabase,
  ...omitFields: Array<keyof StorageTechnologyDatabase>
): StorageTechnology => {
  const conversionTechnology = StorageTechnologySchema.partial()
    .passthrough()
    .parse(tech);

  if (!omitFields.includes('storageTechnologyName'))
    conversionTechnology.storageName = tech.storageTechnologyName;

  return conversionTechnology;
};
