import memoizee from 'memoizee';

export const lightness = memoizee(
  (Rint: number, Gint: number, Bint: number) => {
    // takes sRGB channels as 8 bit integers

    const Rlin = (Rint / 255.0) ** 2.218; // Convert int to decimal 0-1 and linearize
    const Glin = (Gint / 255.0) ** 2.218; // ** is the exponentiation operator, older JS needs Math.pow() instead
    const Blin = (Bint / 255.0) ** 2.218; // 2.218 Gamma for sRGB linearization. 2.218 sets unity with the piecewise sRGB at #777 .... 2.2 or 2.223 could be used instead

    const Ylum = Rlin * 0.2126 + Glin * 0.7156 + Blin * 0.0722; // convert to Luminance Y

    const value = Math.pow(Ylum, 0.43) * 100; // Convert to lightness (0 to 100)

    return value;
  },
);

export const contrastingTextColor = memoizee((color: string) => {
  if (color.startsWith('#')) {
    const code = color.substring(1);
    const [r, g, b] = code.match(/.{1,2}/g);

    const l = lightness(parseInt(r, 16), parseInt(g, 16), parseInt(b, 16));

    return l < 75 ? '#ffffff' : '#000000';
  }

  return '#000000';
});
