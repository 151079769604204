import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DatabaseSelectSignalService } from '@sympheny/database/select';
import { of, combineLatest, map, catchError, firstValueFrom } from 'rxjs';

import { AbstractTechnologyPackagesCollection } from './abstract-technology-packages.collection';
import { TechnologyPackagesDetailDatabase } from './technology-package.model';

@Injectable()
export class TechnologyPackageSelectService extends DatabaseSelectSignalService<any> {
  public async getItemsWithDetails() {
    const datasource =
      this.getDataSource() as AbstractTechnologyPackagesCollection;
    if (!datasource) return null;

    const items = (await firstValueFrom(this.selectedItems())) as any[];

    if (!items) return null;

    return firstValueFrom(
      combineLatest(
        items.map((item) =>
          datasource
            .getTechnologyDetails(item, this.scenarioStore?.exchangeRate)
            .pipe(
              map((value) => ({ selectedValue: item, ...value })),
              catchError((errror) => {
                console.error(errror);
                return of(null);
              }),
            ),
        ),
      ),
    );
  }
  public async getSelectedPackage() {
    const datasource =
      this.getDataSource() as AbstractTechnologyPackagesCollection;
    const category = this.state.get('category');
    if (!datasource || !category) return null;

    const categories = (await firstValueFrom(this.getCategories)) as any[];

    if (!categories) return null;

    return categories.find(
      (c) => c.guid === category,
    ) as TechnologyPackagesDetailDatabase;
  }
}
