import { groupBy, maxBy, minBy } from 'lodash-es';

import { CircularLinkType, GraphData, GraphExtend, Link, Node } from '../model';
import { getLink, getLinkInternalId, getNodeLinks } from '../utils/links';
import { getNode, sortNodeBy } from '../utils/node';
export class GraphDataImpl implements GraphData {
  private readonly sourceLinkMap = new Map<string, string[]>();
  private readonly targetLinkMap = new Map<string, string[]>();
  private nodeIds: string[] = [];
  private readonly nodeMap: Map<string, Node> = new Map();
  private linkIds: string[] = [];
  private readonly linkMap: Map<string, Link> = new Map();
  public readonly extend: GraphExtend;
  constructor(extend: Pick<GraphExtend, 'x0' | 'x1' | 'y1' | 'y0'>) {
    this.extend = { ...extend, ky: 0, py: extend.y0 };
  }

  public setExtendValue(key: keyof GraphExtend, value: number) {
    this.extend[key] = value;
  }

  public addNode(_id: string, node: Node) {
    node.setValue('_id', _id);

    this.nodeMap.set(node._id, node);
    this.sourceLinkMap.set(node._id, []);
    this.targetLinkMap.set(node._id, []);
    this.nodeIds.push(_id);
    this.computeColumns();

    return this;
  }

  public addLink(link: Link) {
    const _id = getLinkInternalId(link);
    this.sourceLinkMap.get(link.source).push(_id);
    this.targetLinkMap.get(link.target).push(_id);
    const targetNode = this.getNode(link.target);
    const sourceNode = this.getNode(link.source);

    link.setValue('_id', _id);
    link.setValue('_index', {
      source: sourceNode.index,
      target: targetNode.index,
    });

    this.linkMap.set(_id, link);
    this.linkIds.push(_id);

    targetNode.setValue('hasSource', true);
    sourceNode.setValue('hasTarget', true);

    // Identify if the link is self linking
    try {
      const findSelfLinking = this.getLink(
        getLinkInternalId({ target: link.source, source: link.target }),
      );
      if (findSelfLinking) {
        findSelfLinking.setValue('isSelfLinking', true);
        link.setValue('isSelfLinking', true);
      }
    } catch {
      /* empty */
    }
    this.computeNodes(sourceNode, targetNode);
    return this;
  }

  public computeNodes(...nodes: Node[]) {
    nodes.forEach((node) => {
      const source = this.getSourceLinks(node);
      const target = this.getTargetLinks(node);
      const s = source.length === 1 ? source[0] : null;
      const t = target.length === 1 ? target[0] : null;
      node.setValue(
        'sameSourceTarget',
        !!s && !!t && s.source === t.target && s.target === t.source,
      );
    });
    this.calculateLinksInColumns();
  }

  public getNodeSource(link: Link) {
    return this.getNodeLinks(link).source;
  }

  public getNodeTarget(link: Link) {
    return this.getNodeLinks(link).target;
  }

  public getNodeLinks(link: Link) {
    return getNodeLinks(link, this.nodeMap);
  }

  // #region Link functions
  public getTargetLinks(node: Node) {
    return this.getLinksForNodeWithLinkData('targetLinkMap', node, 'target');
  }

  private getLinksForNode(key: 'sourceLinkMap' | 'targetLinkMap', node: Node) {
    return this[key].get(node._id) ?? [];
  }

  private getLinksForNodeWithLinkData(
    key: 'sourceLinkMap' | 'targetLinkMap',
    node: Node,
    sortBy: 'source' | 'target',
  ) {
    return this.getLinksForNode(key, node)
      .map((l) => getLink(l, this.linkMap))
      .sort((l1, l2) => (l1._index[sortBy] < l2._index[sortBy] ? -1 : 1));
    // .filter((l) => l.type !== 'replaced');
  }

  public totalLinks(key: 'sourceLinkMap' | 'targetLinkMap', node: Node) {
    return this.getLinksForNode(key, node).length;
  }

  public getSourceLinks(node: Node) {
    return this.getLinksForNodeWithLinkData('sourceLinkMap', node, 'source');
  }

  public forEachLink(fnc: (node: Link) => void) {
    this.linkIds.forEach((linkId) => {
      const link = this.linkMap.get(linkId)!;

      fnc(link);
    });
  }

  public getLink(linkId: string) {
    return getLink(linkId, this.linkMap);
  }

  public getNode(nodeId: string): Node {
    return getNode(nodeId, this.nodeMap);
  }

  public getLinks() {
    return this.linkIds.map((l) => this.getLink(l));
  }

  public removeLinksFromIndex(type: string): void {
    this.linkIds = this.linkIds.filter((id) => {
      return this.getLink(id).type !== type;
    });

    for (const nodeId of this.targetLinkMap.keys()) {
      const links = this.targetLinkMap
        .get(nodeId)
        .filter((id) => this.getLink(id).type !== type);
      this.targetLinkMap.set(nodeId, links);
    }
    for (const nodeId of this.sourceLinkMap.keys()) {
      const links = this.sourceLinkMap
        .get(nodeId)
        .filter((id) => this.getLink(id).type !== type);
      this.sourceLinkMap.set(nodeId, links);
    }
  }
  private _maxLinksInColumns = 0;
  private _linksInColumn: Map<number, Link[]> = new Map();
  private calculateLinksInColumns() {
    const totalLinksColumn = new Map();
    const linksInColumn = new Map();
    totalLinksColumn.set(0, 0);

    this.forEachLink((link) => {
      const { source, target } = this.getNodeLinks(link);

      Array.from({ length: target.column - source.column }).forEach(
        (_, index) => {
          const col = index + source.column;
          totalLinksColumn.set(col, (totalLinksColumn.get(col) ?? 0) + 1);
          const linkInColumn = linksInColumn.get(col) ?? [];
          linkInColumn.push(link);
          linksInColumn.set(col, linkInColumn);
        },
      );
    });

    this._linksInColumn = linksInColumn;
    this._maxLinksInColumns = Math.max(...totalLinksColumn.values()) ?? 100;
  }

  public maxLinksInColumns() {
    return this._maxLinksInColumns;
  }

  public linksInColumn() {
    return this._linksInColumn;
  }

  // #endregion
  // #region Node functions
  public forEachNode(fnc: (node: Node) => void) {
    this.nodeIds.forEach((nodeId) => {
      const node = this.nodeMap.get(nodeId)!;

      fnc(node);
    });
  }
  public getNodes() {
    return this.getNodesById(this.nodeIds);
  }

  public maxNodeWidth(): number {
    let maxNodeWidth = 0;
    this.forEachNode((node) => {
      if (maxNodeWidth < node.nodeWidth) maxNodeWidth = node.nodeWidth;
    });
    return maxNodeWidth;
  }
  public maxNodeHeight(): number {
    let maxNodeHeight = 0;
    this.forEachNode((node) => {
      if (maxNodeHeight < node.nodeHeight) maxNodeHeight = node.nodeHeight;
    });
    return maxNodeHeight;
  }
  private getNodesById(nodeIds: string[], sortOn?: keyof Node) {
    const nodes = (nodeIds ?? [])
      .map((l) => this.getNode(l))
      .filter((n) => !!n);

    if (!sortOn) return nodes;

    return nodes.sort(sortNodeBy(sortOn));
  }
  //#endregion

  // #region Column functions
  private columnIds: number[];
  private columnIdsReverse: number[];
  private columnMap = new Map<number, string[]>();
  private _maxColumns: number;

  private _columnDistance: number;
  private _maxNodesInColumns: number;

  public computeColumns(): void {
    const grouped = groupBy(this.getNodes(), (n) => n.column);
    const keys = Object.keys(grouped).filter((i) => i !== undefined);
    this.columnIds = keys
      .map((i) => +i)
      .sort((n1, n2) => (n1 < n2 ? -1 : 1)) as any;
    this.columnIdsReverse = keys
      .map((i) => +i)
      .sort((n1, n2) => (n1 < n2 ? 1 : -1)) as any;

    this.columnMap.clear();
    this._maxNodesInColumns =
      (maxBy(this.getNodes(), (n) => n.row)?.row ?? 0) + 1;

    this.columnIds.forEach((key) => {
      const nodeIds = (grouped[key] ?? [])
        .filter((n) => this.nodeIds.indexOf(n._id) > -1)
        .map((n) => n._id);

      this.columnMap.set(key, nodeIds);

      if (this._maxNodesInColumns < nodeIds.length) {
        this._maxNodesInColumns = nodeIds.length;
      }
    });
    this._maxColumns = maxBy(this.getNodes(), (n) => n.column)?.column ?? 0;
    const totalColumns = this.maxColumns();
    this._columnDistance = (this.extend.x1 - this.extend.x0) / totalColumns;
    this.calculateLinksInColumns();
  }

  public forEachNodeAtColumn(
    columnIndex: number,
    fnc: (nodes: Node[]) => void,
    sortOn?: keyof Node,
  ) {
    return fnc(this.getNodesById(this.columnMap.get(columnIndex), sortOn));
  }
  public forEachColumn(
    fnc: (nodes: Node[], columnIndex: number) => void,
    sortOn: keyof Node = 'row',
  ): void {
    this.columnIds.forEach((key) => {
      fnc(this.getNodesById(this.columnMap.get(key), sortOn), +key);
    });
  }
  public forEachColumnReverse(
    fnc: (nodes: Node[], columnIndex: number) => void,
    sortOn: keyof Node = 'row',
  ): void {
    this.columnIdsReverse.forEach((key) => {
      fnc(this.getNodesById(this.columnMap.get(key), sortOn), +key);
    });
  }

  public maxColumns(): number {
    return this._maxColumns;
  }

  public columnDistance(): number {
    return this._columnDistance;
  }

  public maxNodesInColumns(): number {
    return this._maxNodesInColumns;
  }
  // #endregion

  public getMinY(): number {
    return (
      minBy(this.getLinks(), (link: Link) => {
        const source = this.getNodeSource(link);
        return source.y0;
      })?.y0 ?? 0
    );
  }

  public filterLinks(predicate: (link: Link) => boolean): Link[] {
    return this.getLinks().filter(predicate);
  }

  public get replacedLinks(): Link[] {
    const replaced: Link[] = [];
    for (const link of this.linkMap.values()) {
      if (link.type === 'replaced') replaced.push(link);
    }

    return replaced;
  }

  public sameColumnLinks(
    fnc: (l: Link) => Node,
    column: number,
    circularLinkType: CircularLinkType | undefined,
  ) {
    return this.filterLinks((l) => {
      const t = fnc(l);
      return t.column === column && l.circularLinkType === circularLinkType;
    });
  }

  public removeVirtualNodesFromIndex(): void {
    this.nodeIds = this.nodeIds.filter((nodeId) => {
      return !this.getNode(nodeId).virtual;
    });
    this.computeColumns();
  }

  public setCenterNodeY(n: Node, center: number, row?: number) {
    this.getNode(n._id).setCenterNodeY(center, row);
    return this;
  }

  public getSourceLinksWithNodes(node: Node) {
    const sourceNodes = this.getTargetLinks(node).map((l) =>
      this.getNode(l.source),
    );

    return {
      sourceLinks: sourceNodes.map((n) => this.getTargetLinks(n)).flat(),
      sourceNodes,
    };
  }
  public getTargetLinksWithNodes(node: Node) {
    const targetNodes = this.getSourceLinks(node).map((l) =>
      this.getNode(l.target),
    );

    return {
      targetLinks: targetNodes.map((n) => this.getSourceLinks(n)).flat(),
      targetNodes,
    };
  }
}
