import { Injectable } from '@angular/core';

import { AbstractTechnologyPackagesCollection } from './abstract-technology-packages.collection';
import { OrgDatabaseConversionTechnologyCollectionV2 } from '../../../conversion-technology';
import { OrgDatabaseStorageTechnologyCollectionServiceV2 } from '../../../storage-technology';

const url = 'database-technology-packages';
const saveUrl = `database-technology-packages/saved-profiles`;

@Injectable()
export class OrgDatabaseTechnologyPackageCollectionServiceV2 extends AbstractTechnologyPackagesCollection {
  constructor(
    conversionService: OrgDatabaseConversionTechnologyCollectionV2,
    storageService: OrgDatabaseStorageTechnologyCollectionServiceV2,
  ) {
    super('database-org', url, saveUrl, conversionService, storageService);
  }
}
