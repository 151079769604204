import { Injectable, Provider } from '@angular/core';

export interface LoadParameters {
  ewz: boolean;
  organization: string;
  organizationId: string;
}

@Injectable()
export abstract class LoadDataService {
  public abstract load(): void;
}

export const createLoadProviders = (providers: Provider[]): Provider[] => {
  return providers
    .map((provider) => [
      provider,
      {
        multi: true,
        provide: LoadDataService,
        useExisting: provider,
      },
    ])
    .flat();
};
