<sympheny-crud-table
  *ngIf="columnConfig"
  [actions]="actions"
  [items]="hubs$ | async"
  [columnConfig]="columnConfig"
  [definedActions]="definedActions"
  scenarioStoreField="hubs"
  tooltip="SCENARIO.tooltip.hubs"
>
  <span title>
    {{ 'SCENARIO.hubs' | transloco }}
  </span>
</sympheny-crud-table>
