<sympheny-dialog-form
  [form]="uploadForm"
  [formGroup]="uploadForm"
  [hideLog]="true"
  submitLabel="map.layer.preview"
  dialogTitle="map.layer.add"
  (submitForm)="onSubmit()"
>
  <ngx-file-drag-drop
    formControlName="file"
    activeBorderColor="#3F51B5"
    [multiple]="false"
    accept="application/vnd.geo+json"
    (valueChanged)="updateForm()"
  >
  </ngx-file-drag-drop>
</sympheny-dialog-form>
