<ng-container *transloco="let t">
  <h3 class="text-lg !font-bold pt-2" *ngIf="sectionTitle">
    {{ t(sectionTitle, sectionTitleParams) }}:
  </h3>
  <div
    class="grid grid-cols-3 pb-2 gap-2"
    [class.grid-cols-1]="elementsRow === 1"
    [class.grid-cols-4]="elementsRow === 4"
  >
    <sympheny-label-value
      *ngFor="let format of fields"
      [format]="format"
      [element]="element"
      [currency]="exchangeCurrency"
    ></sympheny-label-value>
  </div>
</ng-container>
