<div class="flex gap-4">
  <ng-container *ngIf="isNotGlobalDb$ | async">
    <ng-container *ngIf="superuser">
      <sympheny-button type="button" (click)="onEdit()" [disabled]="invalid">
        {{ 'DATABASE.edit.in' | transloco }}
      </sympheny-button>
      <sympheny-button color="warn" (click)="onDelete()" [disabled]="invalid">
        {{ 'DATABASE.delete.in' | transloco }}
      </sympheny-button>
    </ng-container>
  </ng-container>
</div>
