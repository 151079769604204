import { FormatValueConfig } from '@sympheny/format-value';
import { Hub } from '@sympheny/project/data-access';
type KEY = keyof Hub;

export const HubFieldConfig: Record<KEY, FormatValueConfig<Hub>> = {
  hubGuid: undefined,
  hubName: { label: 'HUBS.name', key: 'hubName' },
  updated: undefined,
  created: undefined,
};

export const HubSummaryFields: KEY[] = [];

export const HubListFields: KEY[] = ['hubName'];
