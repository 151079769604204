import { DatabaseDetailsService } from '@sympheny/database/model';

import { DatabaseSelectService } from './service/database-select.service';
import { DatabaseSelectSignalService } from './service/database-select_signel.service';
import {
  DB_SELECT_TOKEN_DB,
  DB_SELECT_TOKEN_EWZ,
  DB_SELECT_TOKEN_ORG,
  DB_SELECT_TOKEN_USER,
} from './tokens';

interface DatabaseSelectConfig<
  TECH,
  S extends DatabaseDetailsService<TECH>,
  SELECT extends DatabaseSelectSignalService<TECH>,
> {
  dbDetailsService?: S;
  ewzDetailsService?: S;
  userDetailsService?: S;
  orgDetailsService?: S;
  databaseSelectSignalService?: any;
}

export function createProviders<TECH, S = any>({
  dbDetailsService,
  ewzDetailsService,
  orgDetailsService,
  userDetailsService,
  databaseSelectSignalService,
}: DatabaseSelectConfig<TECH, any, DatabaseSelectSignalService<TECH>>) {
  return [
    DatabaseSelectService,
    {
      provide: DatabaseSelectSignalService,
      useClass: databaseSelectSignalService ?? DatabaseSelectSignalService,
    },
    dbDetailsService && {
      provide: DB_SELECT_TOKEN_DB,
      useExisting: dbDetailsService,
    },
    ewzDetailsService && {
      provide: DB_SELECT_TOKEN_EWZ,
      useExisting: ewzDetailsService,
    },
    userDetailsService && {
      provide: DB_SELECT_TOKEN_USER,
      useExisting: userDetailsService,
    },
    orgDetailsService && {
      provide: DB_SELECT_TOKEN_ORG,
      useExisting: orgDetailsService,
    },
  ].filter((provider) => !!provider);
}
