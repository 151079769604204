import * as d3 from 'd3';

import { TYPE, SVG } from './types';
import { contrastingTextColor } from '../utils/brightness';

type Props<T extends TYPE> = {
  y?: (d: T) => number;
  x?: (d: T) => number;
  anchor?: 'left' | 'right' | 'middle';
  wordBreak?: (d: T) => boolean;
};
export const breakWords = (text, textEl, width, height) => {
  let currentText = '';
  const x = textEl.node().getAttribute('x');
  textEl.text(null);

  const words = text?.split(' ') ?? [];

  const textMeasure = document.createElement('span');
  textMeasure.style['font-size'] = '12px';

  textMeasure.textContent = text;
  document.body.append(textMeasure);
  const textHeight = textMeasure.offsetHeight;
  let toHigh = false;

  const fitInTextBox = (testText: string) => {
    textMeasure.textContent = testText;
    return textMeasure.offsetWidth < width;
  };

  const splitTextToFit = (testText: string) => {
    textMeasure.textContent = testText;
    // Define a first index to have the value, depending on the current width and the text
    let splitAt = Math.ceil(
      (width / textMeasure.offsetWidth) * testText.length,
    );
    let splittedText = '';
    let rest = '';

    textMeasure.textContent = splittedText;
    let fit = false;

    // Go until it fits, each time one character less
    while (!fit) {
      splitAt = splitAt - 1;
      splittedText = testText.substring(0, splitAt);
      rest = testText.substring(splitAt);
      textMeasure.textContent = splittedText;
      fit = textMeasure.offsetWidth < width;
    }

    // let firstChunk =
    return [splittedText, rest];
  };

  const getCurrentHeight = () => {
    return splitted.length * textHeight;
  };

  const testNextChunck = (chunck: string) => {
    if (toHigh) return null;
    const nextText = currentText + (currentText === '' ? '' : ' ') + chunck;
    if (fitInTextBox(nextText)) {
      currentText = nextText;
      return null;
    } else {
      let newText = currentText;
      let newChunk = chunck;
      let restText = null;

      // If the chunck does not fit in the box, start splitting it already
      if (!fitInTextBox(chunck)) {
        const [firstChunk, rest] = splitTextToFit(chunck);
        newChunk = firstChunk;
        restText = rest;
      }

      if (getCurrentHeight() + textHeight > height) {
        currentText = '';

        toHigh = true;
        if (!fitInTextBox(`${newText} ...`)) {
          newText = newText.substring(0, newText.length - 3);
        }

        splitted.push(`${newText} ...`);

        return `${newText} ...`;
      }

      currentText = newChunk;
      splitted.push(newText);

      if (restText) {
        testNextChunck(restText);
      }
      return newText;
    }
  };

  const splitted: string[] = [];
  words.forEach((word) => {
    if (toHigh) return null;

    testNextChunck(word);
  });

  if (currentText) {
    splitted.push(currentText);
  }

  textEl
    .selectAll('tspan')
    .data(splitted)
    .enter()
    .append('tspan')
    .attr('x', x)
    .attr('dy', '1.2em')
    .style('text-anchor', 'middle')
    .text((d) => d);
  // .text(currentText);

  const y = textEl.node().getAttribute('y');

  textEl.node().setAttribute('y', y - getCurrentHeight() / 2 - textHeight / 3);
  document.body.removeChild(textMeasure);
  return;
};

export type TextProps<T extends TYPE> = Props<T>;

export const createTextElement = <T extends TYPE>(
  node: SVG<any>,
  getText: (d: T) => string,
  nodeColor: (d: T) => string | null,
  props: Props<T>,
) => {
  const { anchor } = props;

  const text = node
    .append('text')
    // .attr('dy', '0.35em')
    .attr('text-anchor', anchor ?? 'middle')
    .attr('opacity', 1)
    .attr('fill', (d) => contrastingTextColor(nodeColor?.(d) ?? 'black'))
    .style('paint-order', 'stroke')
    .style('font-size', '12px')
    .text(getText);

  updateText(text, getText, props);

  return { text };
};

export const updateText = <T extends TYPE>(
  text,
  getText: (d: T) => string,
  { x, y, wordBreak }: Props<T>,
) => {
  const dy = y ?? ((d) => d.y0);
  const dx = x ?? ((d) => d.x0);
  text.attr('x', dx).attr('y', dy);

  if (wordBreak) {
    text
      .attr('x', (d) => d.nodePositionCenterX)
      .attr('y', (d) => d.nodePositionCenterY)
      // .attr('y', (d) =>d.nodePositionCenterY)
      .attr('text-anchor', 'middle')
      .each(function (this: any, t: any) {
        if (wordBreak(t)) {
          const thisText = this as any;
          breakWords(
            getText(t),
            d3.select(thisText),
            t.nodeWidth - 10,
            t.nodeHeight - 10,
          );
        }
      });

    // TODO put it in the middle
    // text.attr('y', (d) => d.y0);
  }
};
