// eslint-disable-next-line @nx/enforce-module-boundaries
import { createProviders } from '@sympheny/database/select';

export * from './collections/database-conversion-technology-collection.service';
export * from './collections/ewz-conversion-technology-collection.service';
export * from './collections/org-database-conversion-technology-collection.service';
export * from './collections/user-conversion-technology-collection.service';
export * from './collections.v2/database-conversion-technology-collection.service';
export * from './collections.v2/ewz-conversion-technology-collection.service';
export * from './collections.v2/org-database-conversion-technology-collection.service';
export * from './collections.v2/user-conversion-technology-collection.service';
import { createLoadProviders } from '@sympheny/utils/data-access';

import { DatabaseConversionTechnologyCollection } from './collections/database-conversion-technology-collection.service';
import { EwzConversionTechnologyCollection } from './collections/ewz-conversion-technology-collection.service';
import { OrgDatabaseConversionTechnologyCollection } from './collections/org-database-conversion-technology-collection.service';
import { UserConversionTechnologyCollection } from './collections/user-conversion-technology-collection.service';
import { DatabaseConversionTechnologyCollectionV2 } from './collections.v2/database-conversion-technology-collection.service';
import { EwzConversionTechnologyCollectionV2 } from './collections.v2/ewz-conversion-technology-collection.service';
import { OrgDatabaseConversionTechnologyCollectionV2 } from './collections.v2/org-database-conversion-technology-collection.service';
import { UserConversionTechnologyCollectionV2 } from './collections.v2/user-conversion-technology-collection.service';

export const createConversionProvidersV2 = () =>
  createProviders({
    dbDetailsService: DatabaseConversionTechnologyCollectionV2,
    ewzDetailsService: EwzConversionTechnologyCollectionV2,
    userDetailsService: UserConversionTechnologyCollectionV2,
    orgDetailsService: OrgDatabaseConversionTechnologyCollectionV2,
  });

export const createConversionProviders = () =>
  createProviders({
    dbDetailsService: DatabaseConversionTechnologyCollection,
    ewzDetailsService: EwzConversionTechnologyCollection,
    userDetailsService: UserConversionTechnologyCollection,
    orgDetailsService: OrgDatabaseConversionTechnologyCollection,
  });

export const ProvideConversionTechnology = () => {
  return createLoadProviders([
    OrgDatabaseConversionTechnologyCollection,
    DatabaseConversionTechnologyCollection,
    EwzConversionTechnologyCollection,
    UserConversionTechnologyCollection,

    OrgDatabaseConversionTechnologyCollectionV2,
    DatabaseConversionTechnologyCollectionV2,
    EwzConversionTechnologyCollectionV2,
    UserConversionTechnologyCollectionV2,
  ]);
};
