import { z } from 'zod';

export const UploadFileSchema = () =>
  z.object({
    fileName: z.string().nullish(),
    encodedFile: z.any().nullish(),
    file: z.any().nullish(),
  });

const UploadFileSchema_ = UploadFileSchema();

export type UploadFile = z.infer<typeof UploadFileSchema_>;
