import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';

import { AbstractEnergyDemandDatabaseCollection } from './abstract-energy-demand-database.collection';

@Injectable()
export class OrgEnergyDemandDatabaseCollection extends AbstractEnergyDemandDatabaseCollection {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(true, { db: 'database', guid: 'energyDemandGuid' });
  }
}
