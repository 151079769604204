import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DIALOG_WIDTH, DialogService } from '@sympheny/ui/dialog';
import { SnackbarService } from '@sympheny/ui/snackbar';
import { UserState } from '@sympheny/user/data-access';
import { firstValueFrom } from 'rxjs';

import {
  AbstractSaveFromScenario,
  SaveToDb,
} from './abstract-save-to-db.component';

@Injectable()
export abstract class SaveToDbService<T> {
  protected readonly dialogService = inject(DialogService);
  protected readonly snackbarService = inject(SnackbarService);
  protected readonly translateService = inject(TranslocoService);
  protected readonly userState = inject(UserState);

  public saveToDb<DATA extends SaveToDb<T>>(
    data: DATA,
    viewContainerRef: ViewContainerRef,
  ) {
    throw new Error('NOT IMPLEMENTED');
  }

  protected onSaveToDb<DATA extends SaveToDb<T>>({
    target,
    data,
    width,
    injector,
  }: {
    target: ComponentType<any>;
    data: DATA;
    width?: string;
    injector?: Injector;
  }) {
    this.dialogService
      .openDialog(target, data, width ?? DIALOG_WIDTH.medium, undefined, {
        injector,
      })
      .afterClosed()
      .subscribe((result) => this.onCloseDialog(result));
  }

  public edit?<DATA extends AbstractSaveFromScenario<T>>(
    data: any, //TODO DATA,
    viewContainerRef: ViewContainerRef,
  ) {
    throw new Error('NOT IMPLEMENTED');
  }

  protected onEdit<DATA extends AbstractSaveFromScenario<T>>({
    target,
    data,
    viewContainerRef,
    width,
  }: {
    target: ComponentType<any>;
    data: DATA;
    viewContainerRef: ViewContainerRef;
    width?: string;
  }) {
    this.dialogService
      .openDialog(target, data, width ?? DIALOG_WIDTH.xlarge, viewContainerRef)
      .afterClosed()
      .subscribe((result) => this.onCloseDialog(result));
  }

  protected async onFromDb<
    DATA extends Pick<
      AbstractSaveFromScenario<T>,
      'scenarioId' | 'projectVersion'
    >,
  >({
    target,
    viewContainerRef,
    width,
    data,
    injector,
  }: {
    target: ComponentType<any>;
    viewContainerRef?: ViewContainerRef;
    width?: string;
    data: Partial<DATA>;
    injector?: Injector;
  }) {
    const dialogRef = this.dialogService.openDialog<any, any>(
      target,
      data,
      width ?? DIALOG_WIDTH.xlarge,
      viewContainerRef,
      { injector },
    );

    let result = await firstValueFrom(dialogRef.afterClosed());

    result = this.onCloseDialog(result);

    if (result) {
      this.edit(
        {
          ...data,
          isEditing: result.isEditing,
          target: result.target,
          fromDb: true,
          data: result.selectedTechnology,
        },
        viewContainerRef,
      );
    }
  }

  protected onCloseDialog(result: { message?: string }) {
    if (result?.message) {
      this.snackbarService.success(
        this.translateService.translate(result.message),
      );
      return null;
    } else return result;
  }
}
