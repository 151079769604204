export class Zoom {
  public scale = 1.0;

  private pointer = { x: 0, y: 0, oldX: 0, oldY: 0 };
  private pos = { x: 0, y: 0 };
  private dragging = false;

  constructor(private readonly image: SVGElement) {}

  public reset() {
    this.dragging = false;
    this.pointer = { x: 0, y: 0, oldX: 0, oldY: 0 };
    this.pos = { x: 0, y: 0 };
    this.scale = 1;
    this.apply();
  }

  public startPan() {
    this.dragging = true;
  }

  public endPan() {
    this.dragging = false;
  }

  public panMove(event: MouseEvent) {
    this.pointer.oldX = this.pointer.x;
    this.pointer.oldY = this.pointer.y;
    this.pointer.x = event.pageX;
    this.pointer.y = event.pageY;
    if (!this.dragging) return;

    event.preventDefault();

    this.pan({
      x: this.pointer.x - this.pointer.oldX,
      y: this.pointer.y - this.pointer.oldY,
    });
    this.apply();
  }

  public zoom(event: WheelEvent) {
    event.preventDefault();
    const x = event.layerX - this.width / 2;
    const y = event.layerY - this.height / 2;
    if (event.deltaY < 0) {
      this.scaleAt({ x, y }, 1.1);
    } else {
      this.scaleAt({ x, y }, 1 / 1.1);
    }
    this.apply();
  }

  private get width() {
    return +this.image.getAttribute('width');
  }
  private get height() {
    return +this.image.getAttribute('height');
  }
  private pan(amount) {
    this.pos.x += amount.x;
    this.pos.y += amount.y;
  }
  private scaleAt(at, amount) {
    this.scale *= amount;
    this.pos.x = at.x - (at.x - this.pos.x) * amount;
    this.pos.y = at.y - (at.y - this.pos.y) * amount;
  }

  private apply() {
    const transform = `matrix(${this.scale},0,0, ${this.scale}, ${this.pos.x}, ${this.pos.y})`;
    this.image.setAttribute('transform', transform);
  }
}
