import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
import { SelectComponent } from '../select/select.component';

export const DAYS = {
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
  0: 'SUNDAY',
} as const;

export interface Day {
  label: string;
  value: string;
  dateString: string;
  weekDay: 1 | 2 | 3 | 4 | 5 | 6 | 0;
}

export const days: Readonly<Day[]> = [
  { label: 'date.day.monday', value: 'MONDAY', dateString: 'Mon', weekDay: 1 },
  {
    label: 'date.day.tuesday',
    value: 'TUESDAY',
    dateString: 'Tue',
    weekDay: 2,
  },
  {
    label: 'date.day.wednesday',
    value: 'WEDNESDAY',
    dateString: 'Wed',
    weekDay: 3,
  },
  {
    label: 'date.day.thursday',
    value: 'THURSDAY',
    dateString: 'Thu',
    weekDay: 4,
  },
  { label: 'date.day.friday', value: 'FRIDAY', dateString: 'Fri', weekDay: 5 },
  {
    label: 'date.day.saturday',
    value: 'SATURDAY',
    dateString: 'Sat',
    weekDay: 6,
  },
  { label: 'date.day.sunday', value: 'SUNDAY', dateString: 'Sun', weekDay: 0 },
] as const;

@Component({
  selector: 'sympheny-select-day',
  templateUrl: './select-day.component.html',
  styleUrls: ['./select-day.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, SelectComponent],
})
export class SelectDayComponent extends BaseFormComponent<
  Day,
  FormFieldConfig
> {
  public readonly days = days;
  @Input() public multiple = false;
}
