import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatabaseDetailsNoCategoriesService } from '@sympheny/database/model';
import {
  mapDataZ,
  ResponseModel,
  SymphenyNumber,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, map } from 'rxjs';
import { z } from 'zod';

export const EnergyDemandSavedUserProfileSchema = z.object({
  guid: z.string(),
  name: z.string(),
  notes: z.string().nullish(),
  energyReferencedArea: SymphenyNumber().nullish(),
  minimumProfileValue: SymphenyNumber().nullish(),
  maximumProfileValue: SymphenyNumber().nullish(),
  averageProfileValue: SymphenyNumber().nullish(),
  totalProfileValue: SymphenyNumber().nullish(),
  fullLoadHours: SymphenyNumber().nullish(),
});

export type EnergyDemandSavedUserProfile = z.infer<
  typeof EnergyDemandSavedUserProfileSchema
>;
@Injectable()
export class EnergyDemandSavedUserProfileCollection extends DatabaseDetailsNoCategoriesService<
  EnergyDemandSavedUserProfile,
  'guid'
> {
  private readonly base = this.environmentService.getValue('base');
  private readonly url = `${this.base}energy-demands/saved-profiles`;
  protected readonly idKey = 'guid';

  constructor(
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService,
  ) {
    super();
  }

  public override fetchData(): Promise<EnergyDemandSavedUserProfile[]> {
    return firstValueFrom(
      this.http
        .get<
          ResponseModel<{ userEnergyDemands: EnergyDemandSavedUserProfile }>
        >(this.url)
        .pipe(
          mapDataZ(EnergyDemandSavedUserProfileSchema, 'userEnergyDemands'),
        ),
    );
  }

  public override create(
    partialData: Partial<EnergyDemandSavedUserProfile>,
    ...extraParams: any
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public override update(
    gui: string,
    data: Partial<EnergyDemandSavedUserProfile>,
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public override delete(guid: string): Promise<string> {
    return firstValueFrom(
      this.http
        .delete(`${this.base}energy-demands/profile-types/user/${guid}`)
        .pipe(map(() => guid)),
    );
  }
}
