import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

interface Options {
  translateParams?: Record<string, string>;
  dismiss?: boolean;
  duration?: number;
  action?: {
    text: string;
    action: () => void;
  };
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private activeSnackbar?: MatSnackBarRef<SimpleSnackBar>;

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translocoService: TranslocoService,
  ) {}

  public success(message: string, options?: Options) {
    return this.open(message, 'success', options);
  }

  public error(message: string, options?: Options) {
    this.open(message, 'error', options);
  }

  public warn(message: string, options?: Options) {
    this.open(message, 'warn', options);
  }

  public info(message: string, options?: Options) {
    this.open(message, 'info', options);
  }

  public open(
    message: string | string[],
    level: 'warn' | 'info' | 'success' | 'error',
    options?: Options,
  ) {
    const dismiss = options?.dismiss ?? true;
    const duration = options?.duration ?? 5000;

    const action = options?.action;
    const actionText = action?.text
      ? action.text
      : dismiss
        ? 'Dismiss'
        : undefined;
    this.activeSnackbar?.dismiss();

    const messsages = typeof message === 'string' ? [message] : message;

    const snackbar = this.snackBar.open(
      messsages
        .map((m) =>
          this.translocoService.translate(m, options?.translateParams ?? {}),
        )
        .join('\n'),
      actionText,
      {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 2000,
        panelClass: [
          'sympheny-snackbar',
          `sympheny-snackbar--${level}`,
          'whitespace-pre-wrap',
        ],
      },
    );

    this.activeSnackbar = snackbar;

    if (action) {
      snackbar.onAction().subscribe(() => {
        action.action();
        snackbar.dismiss();
      });
    }

    if (dismiss) {
      snackbar.onAction().subscribe(() => snackbar.dismiss());
    }
  }
}
