import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TechnologyPackages } from '@sympheny/project/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom } from 'rxjs';

import { AbstractTechnologyPackagesCollection } from './abstract-technology-packages.collection';

const url = 'user-technology-packages';
const urlCategory = `${url}/technology-categories`;
const saveUrl = `${url}/saved-profiles`;

@Injectable()
export class UserTechnologyPackageCollectionService extends AbstractTechnologyPackagesCollection {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService, url, urlCategory, 'customer');
  }

  public override async create(data: Partial<TechnologyPackages>) {
    const createCall = this.http.post(
      `${this.base}${saveUrl}/${data.guid}`,
      {},
    );
    await firstValueFrom(createCall);
    this.reload();
  }
}
