<div
  [class.full-width]="config?.fullWidth"
  class="sympheny--select-list"
  *ngIf="options?.length > 0"
  [attr.data-cy]="dataCy"
>
  <h3 *ngIf="label">
    {{ label | transloco }}
    <sympheny-info-button *ngIf="hint" [tooltip]="hint"></sympheny-info-button>
  </h3>
  <div class="max-h-96">
    <button
      *ngFor="let option of sortedOptions"
      type="button"
      [class.active]="isSelected(option)"
      (click)="selectValue(option)"
      [innerHTML]="
        translate ? (getLabel(option) | transloco) : getLabel(option)
      "
    ></button>
  </div>
</div>
<mat-error *ngIf="invalid">
  {{ getErrorMessage() | transloco }}
</mat-error>
<div class="info" *ngIf="!formControl.value && options?.length">
  <ng-content select="[info]"></ng-content>
</div>

<div class="info" *ngIf="options?.length === 0">
  <ng-content select="[no-values]"></ng-content>
</div>
