import { Graph, Link, Node } from '../model';
type G_EL = d3.Selection<any, undefined, null, undefined>;

export const drawInfo = <NODE_TYPE extends Node, LINK_TYPE extends Link>(
  { graph: data, settings }: Graph<NODE_TYPE, LINK_TYPE>,
  g: G_EL,
  graphWidth: number,
  graphHeight: number,
) => {
  const start = data.maxNodeWidth() + settings.minNodePadding.x;
  const startCenter = data.maxNodeWidth() / 2;
  const endCenter = graphWidth - startCenter;
  const y = 20;

  const end = graphWidth - start;

  g.append('rect')
    .attr('x', start)
    .attr('y', 0)
    .attr('height', graphHeight)
    .attr('width', end - start)
    .style('text-anchor', 'middle')
    .style('fill', '#f4f4f4');

  g.append('text').attr('x', startCenter).attr('y', y).text('Inputs');
  g.append('text')
    .attr('x', 0)
    .attr('x', graphWidth / 2)
    .style('text-anchor', 'middle')
    .attr('y', y)
    .text('Technologies');
  g.append('text')
    .attr('x', endCenter)
    .style('text-anchor', 'middle')
    .attr('y', y)
    .text('Outputs');
};
