import { Graph, Node } from '../../model';

export const EhubComputeNodePosition = (graph: Graph<any, any>) => {
  const { graph: data, settings } = graph;

  const { extend } = data;
  const contentHeight = extend.y1 - extend.y0;

  const maxNodeWidth = data.maxNodeWidth();
  const maxNodeHeight = data.maxNodeHeight();
  const nodePadding = settings.minNodePadding.x;
  const nodeDistanceX = maxNodeWidth + nodePadding;
  const nodeDistanceY = contentHeight / data.maxNodesInColumns();

  // let nextX =  firstPOS;
  let nextX = maxNodeWidth / 2;

  const aggregateNodes: Node[] = [];

  data.forEachColumn((nodes: Node[]) => {
    nodes.forEach((node) => {
      const nextY = maxNodeHeight + nodeDistanceY * node.row;

      node.setCenterNodeY(nextY);

      if (node.sameSourceTarget) {
        node.setCenterNodeX(nextX - node.nodeWidth / 2);
      } else {
        node.setCenterNodeX(nextX);
      }

      if (node.aggregate) aggregateNodes.push(node);
    });
    nextX += nodeDistanceX;
  });

  return;
};
