import { sortBy } from 'lodash-es';

export type SortDirection = 'asc' | 'desc' | '';

export const sortByFn = <T>(
  values: T[],
  valueMap: (v: T) => any,
  direction: SortDirection = 'asc',
) => {
  if (!values?.length) {
    return values;
  }

  const sorted = sortBy(values, (value: T) => valueMap(value));

  if (direction === 'desc') return sorted.reverse();

  return sorted;
};
