import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { LabelValueComponent, FormatValueConfig } from '@sympheny/format-value';
import { ScenarioStore } from '@sympheny/project/scenario/data-access';

@Component({
  selector: 'sympheny-summary-section',
  templateUrl: './summary-section.component.html',
  styleUrls: ['./summary-section.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, LabelValueComponent, NgFor, NgIf],
})
export class SummarySectionComponent<T> {
  @Input() public fields: FormatValueConfig<T>[];
  @Input() public element!: T;

  @Input() public sectionTitle: string;
  @Input() public elementsRow: 1 | 3 | 4 = 3;
  @Input() public sectionTitleParams: Record<string, string>;

  public readonly exchangeCurrency = this.scenarioStore.exchangeCurrency;

  constructor(private readonly scenarioStore: ScenarioStore) {}
}
