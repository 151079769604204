import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { Component, Optional } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { IconButtonComponent } from '@sympheny/ui/button';
import { tap, Observable } from 'rxjs';

import { ProgressState, Progress } from '../progress.state';

@Component({
  selector: 'sympheny-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  standalone: true,
  imports: [NgIf, IconButtonComponent, NgFor, MatIconModule, AsyncPipe],
})
export class ProgressComponent {
  public readonly progress$: Observable<Progress[]>;

  public closed = true;
  public collapsed = false;

  constructor(@Optional() private readonly progressService: ProgressState) {
    this.progress$ = this.progressService.progress$.pipe(
      tap((progress) => {
        if (this.closed) {
          this.closed = progress.length === 0;
        }
      }),
    );
  }

  public closeProgress() {
    this.closed = true;
    this.progressService?.removeAllProgress();
  }

  public trackByProgress(index: number, progress: Progress) {
    return progress.id;
  }
}
