import { Provider } from '@angular/core';

import { ENVIRONMENT_CONFIGURATION } from './environment.config';
import { EnvironmentService } from './environment.service';

export function provideEnvironment<T>(configuration: T): Provider[] {
  return [
    { provide: ENVIRONMENT_CONFIGURATION, useValue: configuration },
    EnvironmentService,
  ].flat();
}
