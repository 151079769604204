import { Injectable } from '@angular/core';

import { AbstractTechnologyPackagesCollection } from './abstract-technology-packages.collection';
import { DatabaseConversionTechnologyCollectionV2 } from '../../../conversion-technology';
import { DatabaseStorageTechnologyCollectionServiceV2 } from '../../../storage-technology';

const url = 'database-technology-packages';
const saveUrl = `user-technology-packages/saved-profiles`;

@Injectable()
export class DatabaseTechnologyPackageCollectionServiceV2 extends AbstractTechnologyPackagesCollection {
  constructor(
    conversionService: DatabaseConversionTechnologyCollectionV2,
    storageService: DatabaseStorageTechnologyCollectionServiceV2,
  ) {
    super('database', url, saveUrl, conversionService, storageService);
  }
}
