import { FormatValueConfig } from '@sympheny/format-value';

import {
  SelectValueFromStoreComponent,
  SELECT_VALUE_FROM_STORE_TOKEN,
  SelectValueFromStoreData,
} from './select-value-store.component';

export const StagesFieldConfig = <T>(): FormatValueConfig<T> => ({
  label: 'STAGES.stages',
  key: 'stages',
  type: 'component',
  sort: { disabled: true },
  component: SelectValueFromStoreComponent,
  token: SELECT_VALUE_FROM_STORE_TOKEN,
  data: {
    field: 'stages',
    storeField: 'stages',
    guid: 'guid',
    displayField: 'name',
  } as SelectValueFromStoreData<T, 'stages'>,
});
