import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import z from 'zod';

import { EnergyCarrierSchema } from './energy-carrier.model';
import { TechnologyAdvancedPriceComponentSchemaV1 } from './price-component.model';

export const NetworkTechnologyV1Schema = z.object({
  networkTechnologyGuid: z.string().nullish(),
  networkTechnologyName: z.string().nullish(),
  fixedInvestmentCost: SymphenyNumber().nullish(),
  variableInvestmentCost: SymphenyNumber().nullish(),
  variableOmCostYear: SymphenyNumber().nullish(),
  variableOmCostCHFkWh: SymphenyNumber().nullish(),
  fixedOmCostChf: SymphenyNumber().nullish(),
  fixedOmCostPercent: SymphenyNumber().nullish(),
  variableOmCostPercent: SymphenyNumber().nullish(),

  fixedReplacementCost: SymphenyNumber().nullish(),
  variableReplacementCostPercent: SymphenyNumber().nullish(),
  variableReplacementCostCHF: SymphenyNumber().nullish(),
  fixedSalvageValue: SymphenyNumber().nullish(),
  variableSalvageValuePercent: SymphenyNumber().nullish(),
  variableSalvageValueCHF: SymphenyNumber().nullish(),
  minimumCapacity: SymphenyNumber().nullish(),
  maximumCapacity: SymphenyNumber().nullish(),
  networkLoss: SymphenyNumber().nullish(),
  lifetime: SymphenyNumber().nullish(),
  variableEmbodiedCo2: SymphenyNumber().nullish(),
  fixedEmbodiedCo2: SymphenyNumber().nullish(),
  created: SymphenyDate().nullish(),
  updated: SymphenyDate().nullish(),
  energyCarrier: EnergyCarrierSchema.nullish(),
  category: z.string().nullish(),
  technologyCategory: z.string().nullish(),
  notes: z.string().nullish(),
  source: z.string().nullish(),
  networkSize: SymphenyNumber().nullish(),
  comesFromDb: z.string().nullish(),
  costComponents: z.array(TechnologyAdvancedPriceComponentSchemaV1).nullish(),
});

export const NetworkTechnologyV1DtoSchema = NetworkTechnologyV1Schema.pick({
  comesFromDb: true,
  fixedEmbodiedCo2: true,
  fixedInvestmentCost: true,
  fixedOmCostChf: true,
  fixedOmCostPercent: true,
  lifetime: true,
  maximumCapacity: true,
  minimumCapacity: true,
  networkLoss: true,
  networkSize: true,
  networkTechnologyName: true,
  notes: true,
  source: true,
  technologyCategory: true,
  variableEmbodiedCo2: true,
  variableInvestmentCost: true,
  variableOmCostYear: true,
  variableOmCostCHFkWh: true,
  variableOmCostPercent: true,
  variableReplacementCostPercent: true,
}).extend({
  energyCarrierGuid: z.string(),
  costComponents: z
    .array(
      z.object({
        category: z.string(),
        complexityFactor: SymphenyNumber().nullish(),
        dataPoints: SymphenyNumber().nullish(),
        interestRate: SymphenyNumber().nullish(),
        length: SymphenyNumber().nullish(),
        lifetime: SymphenyNumber().nullish(),
        name: z.string().nullish(),
        numberOfPumps: SymphenyNumber().nullish(),
        value: SymphenyNumber().nullish(),
      }),
    )
    .nullish(),
});

export const NetworkTechnologyV2Schema = NetworkTechnologyV1Schema.omit({
  networkLoss: true,
  maximumCapacity: true,
  minimumCapacity: true,
}).extend({
  stages: z.array(z.string()),
  fixedSalvageValue: SymphenyNumber().nullish(),
  variableSalvageValueCHF: SymphenyNumber().nullish(),
  variableSalvageValuePercent: SymphenyNumber().nullish(),
  fixedReplacementCost: SymphenyNumber().nullish(),
  variableReplacementCostCHF: SymphenyNumber().nullish(),
  variableReplacementCostPercent: SymphenyNumber().nullish(),
});

export const NetworkTechnologyV2SDtochema = NetworkTechnologyV1DtoSchema.omit({
  networkLoss: true,
  maximumCapacity: true,
  minimumCapacity: true,
}).extend({
  stages: z.array(z.string()),
  fixedSalvageValue: SymphenyNumber().nullish(),
  variableSalvageValueCHF: SymphenyNumber().nullish(),
  variableSalvageValuePercent: SymphenyNumber().nullish(),
  fixedReplacementCost: SymphenyNumber().nullish(),
  variableReplacementCostCHF: SymphenyNumber().nullish(),
  variableReplacementCostPercent: SymphenyNumber().nullish(),
});

export type NetworkTechnologyV1Dto = z.infer<
  typeof NetworkTechnologyV1DtoSchema
>;
export type NetworkTechnologyV1 = z.infer<typeof NetworkTechnologyV1Schema>;
export type NetworkTechnologyV2Dto = z.infer<
  typeof NetworkTechnologyV2SDtochema
>;
export type NetworkTechnologyV2 = z.infer<typeof NetworkTechnologyV2Schema>;
export type NetworkTechnology = NetworkTechnologyV2 & NetworkTechnologyV1;
