<form [formGroup]="newLayerForm" (ngSubmit)="onSave()" *transloco="let t">
  <sympheny-input label="map.layer.name" formControlName="name" />
  <sympheny-color-picker
    formControlName="color"
    (ngModelChange)="changeColor()"
  >
    {{ t('map.layer.color') }}
  </sympheny-color-picker>
  <sympheny-checkbox formControlName="share" label="map.layer.share" />

  <div class="flex gap-2 pt-2 border-t justify-end">
    <sympheny-button (click)="onCancel()" color="custom">
      {{ t('Cancel') }}
    </sympheny-button>
    <sympheny-button buttonType="submit" [disabled]="!newLayerForm.valid">
      {{ t('Save') }}
    </sympheny-button>
  </div>
</form>
