import { LoadDataService } from '@sympheny/utils/data-access';

import {
  DatabaseProfileExportCollection,
  EwzExportCollection,
  OrgDatabaseExportCollection,
} from './export';
import {
  DatabaseProfileImportCollection,
  EwzImportCollection,
  OrgDatabaseImportCollection,
} from './import';

const collections = [
  DatabaseProfileImportCollection,
  EwzImportCollection,
  OrgDatabaseImportCollection,
  // TODO NYI  UserImportCollection,
  DatabaseProfileExportCollection,
  EwzExportCollection,
  OrgDatabaseExportCollection,
  // TODO NYI UserExportCollection,
];

export const provideDatabaseDataAccessImpex = () => {
  return collections
    .map((collection) => [
      collection,
      {
        multi: true,
        provide: LoadDataService,
        useExisting: collection,
      },
    ])
    .flat();
};
