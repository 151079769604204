import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { DB_TYPES } from '@sympheny/database/model';
import {
  Collection,
  mapData,
  ReloadState,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Observable, of } from 'rxjs';

export class AbstractTechnologyPackageCategoriesCollection extends Collection<any> {
  public readonly guidKey = null;
  private readonly reloadState = inject(ReloadState);

  constructor(
    http: HttpClient,
    environmentService: EnvironmentService,
    protected readonly baseUrl: string,
    protected readonly db: DB_TYPES | 'ewz',
  ) {
    super(http, environmentService);
  }

  protected override listDataApi(): Observable<string[]> {
    if (!this.reloadState.shouldLoad(this.db)) return of([]);
    return this.http
      .get<
        ResponseModel<{ technologyPackages: string[] }>
      >(`${this.base}${this.baseUrl}`)
      .pipe(mapData('technologyPackages'));
  }
}
