import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { SnackbarService } from '@sympheny/ui/snackbar';
import { EnvironmentService } from '@sympheny/utils/environment';

import {
  ResponseStatusCode,
  ResponseStatusMessages,
} from './model/response.model';

const defaultMessage =
  'Something went wrong. Please try again. If the issue persists, contact support.';

@Injectable()
export class SymphenyErrorHandler implements ErrorHandler {
  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly environmentService: EnvironmentService,
  ) {}

  /**
   * Hook for uncaught error responses
   *
   * @param error
   */
  public handleError(error: HttpErrorResponse) {
    if (this.environmentService.isSymphenyUrl(error.url)) {
      this.handleEspError(error);
      return;
    }

    // Don't remove this, or it will eat all other errors!
    console.error(error);
  }

  /**
   * Handle custom errors from the backend, show a snackbar when it happens
   *
   * @param error HttpErrorResponse
   * @param customMessage if a custom message should be shown instead of the description in the response
   */
  public handleEspError(
    error: HttpErrorResponse,
    customMessage?: {
      code: ResponseStatusCode;
      message: ResponseStatusMessages;
    },
  ) {
    console.error(error);
    const res: any = error.error;

    let message =
      (typeof res.detail === 'string' ? res.detail : res.detail?.message) ??
      res.status?.message ??
      res.status?.desc ??
      defaultMessage;

    if (
      customMessage &&
      'code' in customMessage &&
      customMessage.code === res.status.code
    ) {
      message = customMessage.message ?? message;
    }

    if (error.status === 403) {
      this.snackbarService.warn(message);
    } else this.snackbarService.error(message);
  }
}
