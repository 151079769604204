<div class="sympheny-banner" [ngClass]="bannerColor">
  <div class="flex-grow flex items-center justify-center">
    <div>
      <ng-content></ng-content>
      <ng-container *ngIf="text">{{ text }}</ng-container>
      <ng-template [cdkPortalOutlet]></ng-template>
    </div>
  </div>

  <div class="mr-4" *ngIf="!disableDismiss">
    <sympheny-button-icon
      cssClass="text-white"
      (action)="onDismiss()"
      icon="close"
    ></sympheny-button-icon>
  </div>
</div>
