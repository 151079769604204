import {
  Address,
  HubGis,
  LayerType,
} from '@sympheny/project/scenario/data-access';
import { plainToClass } from 'class-transformer';
import { getCenter } from 'ol/extent';
import { getArea } from 'ol/sphere';
import { Style } from 'ol/style';

import { MapLayer } from './map-layer';

export interface HubProcessed {
  geoadmin: boolean;
  sep: boolean;
}

export class MapHubLayer extends MapLayer {
  public readonly hubGis: HubGis;
  public readonly baseLayer: MapLayer;
  public readonly buildingLayer: MapLayer;
  public readonly facadeLayer: MapLayer;
  public readonly roofLayer: MapLayer;
  public readonly parcelLayer: MapLayer;
  public readonly area: number | null;

  constructor(hubGis: HubGis) {
    super(hubGis.hub_id, LayerType.hubs, '', false);
    this.hubGis = hubGis;

    this.baseLayer = new MapLayer(
      `baseLayer${hubGis.hub_id}`,
      LayerType.hubs,
      '',
      false,
    );

    this.baseLayer.addFeatures(hubGis.base_layer);
    this.area = this.calculatePolygonArea();
    this.baseLayer.features[0].set('area', this.area);

    this.facadeLayer = new MapLayer(
      `facadeLayer${hubGis.hub_id}`,
      LayerType.hubs,
      '',
      false,
    );
    this.facadeLayer.addFeatures(hubGis.facade_layer);

    this.buildingLayer = new MapLayer(
      `buildingLayer${hubGis.hub_id}`,
      LayerType.hubs,
      '',
      false,
    );

    this.buildingLayer.addFeatures(hubGis.building_layer);

    this.roofLayer = new MapLayer(
      `roofLayer${hubGis.hub_id}`,
      LayerType.hubs,
      '',
      false,
    );
    this.roofLayer.addFeatures(hubGis.roof_layer);

    this.parcelLayer = new MapLayer(
      `parcelLayer${hubGis.hub_id}`,
      LayerType.hubs,
      '',
      false,
    );
    this.parcelLayer.addFeatures(hubGis.parcel_layer);
  }

  public getCenter(): [number, number] {
    return getCenter(this.baseLayer.features[0].getGeometry().extent_) as any;
  }
  public getExtend() {
    return this.baseLayer.features[0].getGeometry().extent_;
  }

  private calculatePolygonArea(): number | null {
    const feature0 = this.baseLayer.features[0];
    const geometry = feature0.getGeometry();
    return geometry ? getArea(feature0.getGeometry()) : null;
  }

  public get processed(): HubProcessed {
    return {
      geoadmin: this.hubGis.geoadmin,
      sep: this.hubGis.sep,
    };
  }

  public getAddressDetails(addressId: any) {
    if (addressId === null) return this.hubGis.addresses;

    const details = this.hubGis.addresses.find(
      (a) => a.address_id === addressId,
    );

    return details ? [plainToClass(Address, details)] : [];
  }

  public override setColorRange(
    key: any,
    rangeStyle: Record<string, Style>,
  ): void {
    this.baseLayer.setColorRange(key, rangeStyle);
    this.buildingLayer.setColorRange(key, rangeStyle);
    this.facadeLayer.setColorRange(key, rangeStyle);
    this.roofLayer.setColorRange(key, rangeStyle);
    this.parcelLayer.setColorRange(key, rangeStyle);
  }
  public override setStyle(style: Style | null | undefined): void {
    this.baseLayer.setStyle(style);
    this.buildingLayer.setStyle(style);
    this.facadeLayer.setStyle(style);
    this.roofLayer.setStyle(style);
    this.parcelLayer.setStyle(style);
  }
}
