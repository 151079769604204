import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const AvailableResourceTypeV2Enum = z.enum([
  'Area',
  'Power',
  'Power output', // Only V1
  'Generic',
]);
export type AvailableResourceTypeV2 = z.infer<
  typeof AvailableResourceTypeV2Enum
>;

export const IrradianceProfileTypeV2Enum = z.enum([
  'GENERATED',
  'UPLOADED',
  'SAVED',
]);
export type IrradianceProfileTypeV2 = z.infer<
  typeof IrradianceProfileTypeV2Enum
>;

export const SolarHubV2Schema = z.object({
  hubGuid: z.string().nullish(),
  hubName: z.string().nullish(),

  availableResourceType: AvailableResourceTypeV2Enum,
  availableSolarCollectorArea: SymphenyNumber().nullish(),
  technologyDimensioningStdValue: SymphenyNumber().nullish(),
});

export const OnSiteSolarResourceV2Schema = z.object({
  created: SymphenyDate().nullish(),
  energyCarrierGuid: z.string().nullish(),
  energyCarrierName: z.string().nullish(),
  hubs: z.array(SolarHubV2Schema).nullish(),
  irradianceProfileType: z
    .string()
    .transform((v) => v.toUpperCase() as IrradianceProfileTypeV2)
    .nullish(),
  name: z.string().nullish(),
  profileId: SymphenyNumber().nullish(),
  solarResourceGuid: z.string().nullish(),
  solarResourceMetadataDbOrganization: z.string().nullish(),
  solarResourceMetadataGuid: z.string().nullish(),
  solarResourceMetadataLocation: z.string().nullish(),
  solarResourceMetadataName: z.string().nullish(),
  solarResourceMetadataOrientation: z.string().nullish(),
  solarResourceMetadataSlope: SymphenyNumber().nullish(),
  solarResourceMetadataType: z.string().nullish(),
  stages: z.array(z.string()).nullish(),
  updated: SymphenyDate().nullish(),
});

export const OnSiteSolarResourceV2ListSchema = OnSiteSolarResourceV2Schema;

export const OnSiteSolarResourceV2DtoSchema = OnSiteSolarResourceV2Schema.pick({
  energyCarrierGuid: true,
  hubs: true,
  name: true,
  profileId: true,
  stages: true,
  irradianceProfileType: true,
}).extend({
  databaseSolarResourceMetadataName: z.string().nullish(),
  userSolarResourceMetadataGuid: z.string().nullish(),
  solarResourceName: z.string().nullish(),
  profile: IrradianceProfileTypeV2Enum.nullish(),
});

export type SolarHubV2 = z.infer<typeof SolarHubV2Schema>;
export type OnSiteSolarResourceV2 = z.infer<typeof OnSiteSolarResourceV2Schema>;
export type OnSiteSolarResourceV2List = z.infer<
  typeof OnSiteSolarResourceV2ListSchema
>;

export type OnSiteSolarResourceV2Dto = z.infer<
  typeof OnSiteSolarResourceV2DtoSchema
>;
