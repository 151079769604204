import { Graph } from '../../model';

export const calculateGraphDimsions = (
  { graph }: Readonly<Graph>,
  minWidth: number,
  minHeight: number,
) => {
  const padding = 0;
  const width = minWidth;
  const height = minHeight; // < settings.height ? settings.height : minHeight;

  const x0 = padding;
  const x1 = width - padding;
  const y0 = padding;
  const y1 = height - padding;

  graph.setExtendValue('x0', x0);
  graph.setExtendValue('x1', x1);
  graph.setExtendValue('y0', y0);
  graph.setExtendValue('y1', y1);

  return { maxWidth: x1 - x0, maxHeight: y1 - y0 };
};

export const EhubComputeGraphSize = (graph: Graph<any, any>) => {
  const { settings, graph: data } = graph;
  const { nodeDimensions, minNodePadding } = settings;

  const maxNodeWidth = data.maxNodeWidth();
  const maxNodeHeight = data.maxNodeHeight();
  const minGraphHeight =
    (maxNodeHeight + minNodePadding.y) * data.maxLinksInColumns();

  const minGraphWidth = (maxNodeWidth + minNodePadding.y) * data.maxColumns();
  data.forEachNode((node) => node.setNodeDimensions(nodeDimensions(node)));

  calculateGraphDimsions(graph, minGraphWidth, minGraphHeight);
  // Resize the elements to a lower resolution
};
