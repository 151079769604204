import { NgIf } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { DB_TYPES, ITEM_CONFIG } from '@sympheny/database/model';
import { SelectListComponent } from '@sympheny/ui/form';
import { map } from 'rxjs';

import { SelectDbInitalValue } from './model';
import { DatabaseSelectSignalService } from '../service/database-select_signel.service';

@Component({
  selector: 'sympheny-db-select-item-type',
  templateUrl: './select-item-type.component.html',
  styleUrls: ['./select-item-type.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, SelectListComponent, NgIf, TranslocoPipe],
})
export class DbSelectItemTypeComponent {
  @Input() public itemConfig: ITEM_CONFIG;
  @Input() public multi: boolean;
  @Input() public selectAllTechs: boolean;
  @Input() public hideTechnology: boolean;

  @Input() public categoryHeaderLabel = 'DATABASE.technologyCategory';
  @Input() public categoryInfoLabel = 'DATABASE.category.info';
  @Input() public technologyHeaderLabel = 'DATABASE.technology';
  @Input() public technologyInfoLabel = 'DATABASE.technology.info';
  @Input() public typeHeaderLabel = 'DATABASE.type';
  @Input() public typeInfoLabel = 'DATABASE.type.info';

  @Output() public readonly selectDb = new EventEmitter();
  @Output() public readonly selectType = new EventEmitter();
  @Output() public readonly selectCategory = new EventEmitter();
  @Output() public readonly selectValue = this.databasSelectService
    .selectedItems()
    .pipe(map((items) => (this.multi ? items : items?.[0])));

  public readonly form = new FormGroup({
    database: new FormControl<DB_TYPES | 'ewz'>(null, Validators.required),
    category: new FormControl<string>(null, Validators.required),
    type: new FormControl<string>(null),
    items: new FormControl<string | string[]>(null, Validators.required),
  });
  public readonly databases = toSignal(
    this.databasSelectService.databases$,
  ) as any;
  public readonly categories = toSignal(
    this.databasSelectService.getCategories,
  ) as any;
  public readonly types = toSignal(this.databasSelectService.getTypes) as any;
  public readonly items = toSignal(this.databasSelectService.getItems) as any;

  private readonly selectAll = signal(false);

  @Input()
  public set showEwz(showEwz: boolean) {
    this.databasSelectService.setEwz(showEwz);
  }

  @Input()
  public set showUser(showUser: boolean) {
    this.databasSelectService.showUser(showUser);
  }

  @Input()
  public set showSymphenyDb(showSymphenyDb: boolean) {
    this.databasSelectService.showSymphenyDb(showSymphenyDb);
  }

  @Input()
  public set showOrganization(showOrganization: boolean) {
    this.databasSelectService.showOrganization(showOrganization);
  }

  @Input()
  public set initialValue(initialValue: SelectDbInitalValue) {
    if (!initialValue) {
      return;
    }
    const items = initialValue.items;
    const value = {
      database: initialValue.database,
      category: initialValue.category,
      type: initialValue.type,
      items: items ?? null,
    } as SelectDbInitalValue;

    this.form.patchValue(value);
    this.databasSelectService.initalValue(value);
  }

  constructor(
    private readonly databasSelectService: DatabaseSelectSignalService<any>,
  ) {
    effect(() => {
      const selectAll = this.selectAll();
      if (!selectAll) return;

      const itemsChanged = this.items();

      if (!itemsChanged?.length) return;

      const key = this.getKey('item');
      this.form.patchValue({ items: itemsChanged?.map((i) => i[key]) ?? [] });

      return;
    });
  }

  public changeDB(database: DB_TYPES) {
    this.form.patchValue({ category: null, type: null, items: null });
    this.databasSelectService.setDatabase(database);
    this.selectDb.emit(database);

    this.selectCategory.emit(null);
    this.selectType.emit(null);
  }

  public changeCategory(category: string) {
    this.form.patchValue({ type: null, items: null });
    this.databasSelectService.setCategory(this.form.value.category);
    this.selectCategory.emit(category);
    this.selectType.emit(null);
    this.selectAll.set(this.selectAllTechs && !this.showType);
  }

  public changeType(type: string) {
    this.form.patchValue({ items: null });
    this.databasSelectService.setType(this.form.value.type);
    this.selectType.emit(type);

    this.selectAll.set(this.selectAllTechs);
  }

  public changeItems(items: string[] | string) {
    this.databasSelectService.setItems(
      typeof items === 'string' ? [items] : items,
    );
  }

  public get showType() {
    return this.itemConfig?.type === 'PROFILE';
  }

  public getLabel(config: 'type' | 'category' | 'item') {
    let label = 'label';
    switch (config) {
      case 'type':
        label = (this.itemConfig as any).typeLabel;
        break;
      case 'category':
        label = (this.itemConfig as any).categoryLabel;
        break;
      case 'item':
        label = (this.itemConfig as any).valueLabel;
        break;
    }

    return label ?? 'label';
  }

  public getKey(config: 'type' | 'category' | 'item') {
    let key = 'label';
    switch (config) {
      case 'type':
        key = (this.itemConfig as any).typeLabel;
        break;
      case 'category':
        key = (this.itemConfig as any).categoryKey;
        break;
      case 'item':
        key = (this.itemConfig as any).valueKey;
        break;
    }

    return key ?? 'key';
  }
}
