<sympheny-dialog-form
  [formGroup]="form"
  [form]="form"
  (submitForm)="saveToDb()"
  [editMode]="editMode"
  *transloco="let t"
>
  <ng-container title>{{t(titleLabel,{db})}}</ng-container>

  <div class="grid grid-cols-2 gap-4">
    <ng-content> </ng-content>
    <sympheny-input
      *ngIf="form.get('source')"
      class="col-span-2"
      label="Source"
      formControlName="source"
      placeholder="https://..."
    ></sympheny-input>
    <sympheny-textarea
      *ngIf="form.get('notes')"
      class="col-span-2"
      formControlName="notes"
      label="Notes"
    ></sympheny-textarea>
  </div>
</sympheny-dialog-form>
