import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlag } from './feature-flag';
import { FeatureFlagService } from './feature-flag.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[featureFlag]',
  standalone: true,
})
export class FeatureFlagDirective {
  private isHidden = true;

  @Input() public set featureFlag(featureFlag: FeatureFlag) {
    if (featureFlag) {
      this.updateView(featureFlag);
    }
  }

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly featureFlagService: FeatureFlagService,
  ) {}

  private updateView(featureFlag: FeatureFlag) {
    if (this.featureFlagService.isEnabled(featureFlag)) {
      if (this.isHidden) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.viewContainerRef.clear();
      this.isHidden = true;
    }
  }
}
