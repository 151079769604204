<div class="absolute text-right right-2 items-center z-10">
  <button (click)="restore()">
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M-6.9994 0.9083M4.5416 -2.9654L7.1597 -2.9654L7.1597 -5.6904M7.0261 -2.6448C5.9041 -5.6102 2.8051 -7.8543 -0.8549 -7.4536C-4.6485 -7.0261 -7.5872 -3.6867 -7.4536 0.1603M-4.5416 2.9654L-7.1864 2.9654L-7.1864 5.6904M-7.0261 2.6448C-5.9041 5.6102 -2.8051 7.8543 0.8549 7.4536C4.6485 7.0261 7.5872 3.6867 7.4536 -0.1603"
        fill="transparent"
        stroke="rgb(29,150,130)"
        style="transform: translate(10px, 10px)"
      ></path>
    </svg>
  </button>
  <button (click)="toggleFullscreen()" *ngIf="type === 'ehub'">
    <mat-icon color="primary">
      {{ fullScreen ? 'fullscreen_exit' : 'fullscreen' }}
    </mat-icon>
  </button>
  <button (click)="download()">
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M-6.4526 -1.5776L-0.0905 4.2672L6.4784 -1.4483M-6.4784 3.7759L-6.4784 7.5L6.2457 7.5L6.2457 3.7759M-0.1164 4.1638L-0.1164 -7.5"
        fill="transparent"
        stroke="rgb(29,150,130)"
        style="transform: translate(10px, 10px)"
      ></path>
    </svg>
  </button>
</div>
<div
  *ngIf="showWarning"
  class="absolute top-1/2 w-full -mt-2 items-center text-center"
  [class.hidden]="!showDiagram"
>
  {{ 'HUBS.system.diagram.warning' | transloco }}
</div>

<div
  class="w-full h-full flex justify-center items-center overflow-hidden z-0"
  #chart
  [class.opacity]="showDiagram ? 1 : 0"
></div>
<div
  #tooltip
  class="fixed block border nowrap bg-white rounded p-2 top-0 left-0 pointer-events-none"
  style="
    z-index: 9999999;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 10px;
    transition:
      opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
      visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
      transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    color: rgb(102, 102, 102);
    opacity: 0;
    max-width: 400px;
    word-break: break-all;
  "
  [style.transform]="mousePosition$ | async"
>
  Tooltip
</div>
